/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./additional-details-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./additional-details-modal.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/material/dialog";
var styles_AdditionalDetailsModalComponent = [i0.styles];
var RenderType_AdditionalDetailsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdditionalDetailsModalComponent, data: {} });
export { RenderType_AdditionalDetailsModalComponent as RenderType_AdditionalDetailsModalComponent };
export function View_AdditionalDetailsModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h5", [["class", "modal-title pull-left"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "modal-body"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.modalBody; _ck(_v, 5, 0, currVal_1); }); }
export function View_AdditionalDetailsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-additional-details-modal", [], null, null, null, View_AdditionalDetailsModalComponent_0, RenderType_AdditionalDetailsModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdditionalDetailsModalComponent, [i3.DomSanitizer, i4.MatDialogRef, [2, i4.MAT_DIALOG_DATA]], null, null)], null, null); }
var AdditionalDetailsModalComponentNgFactory = i1.ɵccf("app-additional-details-modal", i2.AdditionalDetailsModalComponent, View_AdditionalDetailsModalComponent_Host_0, { modalTitle: "modalTitle", modalBody: "modalBody" }, {}, []);
export { AdditionalDetailsModalComponentNgFactory as AdditionalDetailsModalComponentNgFactory };
