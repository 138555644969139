import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EnvironmentData } from 'src/app/services/environments.service';
import { DataModel, EntitySearchCriteria } from '../models/data-model.model';
import { AuthService, SecuredStorageService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  private onSaveEntity: Subject<any> = new Subject<any>();

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private environmentData: EnvironmentData,
    private storageService: SecuredStorageService
  ) { }

  getParameterizedEntityList(entityName: string, pageNumber: any, fetchRecords: any, entitySearchCriteria: EntitySearchCriteria): Observable<any[]> {
    const subject = new Subject<any[]>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + entityName + "/find/" + pageNumber + "," + fetchRecords;

    this.httpClient.post<any[]>(
      url,
      entitySearchCriteria,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );

    return subject.asObservable();
  }

  getEntityList(entityName: string, pageNumber: any, fetchRecords: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + entityName + '/findAll/' + pageNumber + "," + fetchRecords;

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.get<any[]>(
          url,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any[]>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error

              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }

  getSaveEntityObservable() {
    return this.onSaveEntity;
  }

  /**
   * This is a very special condition where existing linkage (_linkageId) is broken when
   * parent id (_parentId) is not present while making the save/submit entity call.
   * 
   * The API actually supports entity without parentId, with just linkageId to retain the
   * existing linkage, but then it leave us no way to clear a linkage.
   * 
   * Since field is only present for parentId and mechanically, parentId will never be
   * absent unless cleared manually, we're going to treat this action as removal of
   * existing linkage.
   * 
   * The API is designed in such fashion to support all the save/submit call that are not
   * happening from UI (backend/internal calls), where the response of save/submit never
   * contains the parentId (it gets removed the moment linkage is created and linkageId
   * is persisted instead), thus retains the existing linkage as long as the linkageId is
   * present in the request payload.
   */
  private manageLinkageFields(entityMap: any) {
    if (entityMap && (!entityMap['_parentId'] || entityMap['entityMap'].trim().length == 0)) {
      delete entityMap['_linkageId'];
      delete entityMap['_parentId'];
      delete entityMap['_parentDataModelName'];
    }
  }

  saveEntity(entity?: DataModel, map?: any, isSavingWizardProgress?: boolean): Observable<any> {
    const subject = new Subject<any>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + entity.name;

    this.manageLinkageFields(map);

    if (this.storageService.getItem(SecuredStorageService._USER_INFO)) {
      map["_userInfo"] = this.storageService.getItem(SecuredStorageService._USER_INFO);
    }

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.post<any>(
          url,
          map,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
                this.onSaveEntity.next();
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error

              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }

  submitEntity(entity?: DataModel, map?: any): Observable<any> {
    const subject = new Subject<any>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + entity.name + '/submit';

    this.manageLinkageFields(map);

    if (this.storageService.getItem(SecuredStorageService._USER_INFO)) {
      map["_userInfo"] = this.storageService.getItem(SecuredStorageService._USER_INFO);
    }

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.post<any>(
          url,
          map,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error

              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }


  updateEntity(entity?: DataModel, map?: any): Observable<any> {
    const subject = new Subject<any>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + entity.name + '/update';

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.post<any>(
          url,
          map,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error

              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }


  getEntity(entityName: string, id?: string): Observable<any> {
    const subject = new Subject<any>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + entityName + "/" + id;

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.get<any>(
          url,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error

              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }

  getDuplicateEntity(entityName: string, id?: string): Observable<any> {
    const subject = new Subject<any>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + "duplicateEntity/" + entityName + "/" + id;

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.get<any>(
          url,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error

              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }

  getEntityLookupRef(referenceModel: string, id: any) {
    const subject = new Subject<any>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.entitrefurl + referenceModel + "/" + id;

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.get<any>(
          url,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error

              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }

  getLookupEntities(referenceModel: string, map?: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.lookupurl + referenceModel;

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.post<any>(
          url,
          map,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }

  getRefLookupEntities(referenceModel: string, map?: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.reflookupurl + referenceModel;

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.post<any>(
          url,
          map,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }

  getReferenceEntity(referenceModel: string, map?: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.getrefentity + referenceModel;

    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.post<any>(
          url,
          map,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }



  // entityUspSearch(scrollId?: string, searchText?: string): Observable<any> {
  //   const subject = new Subject<any>();
  // 
  //   const url = `${environment.interfaceService + environment.uspsearch}`;
  //   const body = {
  //     "searchText":searchText,
  //     "entityType":"entity"
  //   }
  //   this.authService.validateAuthToken().subscribe(
  //     (isValidToken: boolean) => {
  //       this.httpClient.post<any>(
  //         url,
  //         body,
  //         {
  //           observe: 'response',
  //           reportProgress: true,
  //           withCredentials: true
  //         }
  //       )
  //         .subscribe(
  //         (response: HttpResponse<any>) => {
  //           if (response.body) {
  //             subject.next(response.body);
  //           }
  //         },
  //         (err: HttpErrorResponse) => {
  //           // All errors are handled in ErrorInterceptor, no further handling required
  //           // Unless any specific action is to be taken on some error
  // 
  //           subject.error(err);
  //         }
  //         );
  //     }
  //   );
  // 
  //   return subject.asObservable();
  // }

  evaluateMvel(entityName: string, payload: any): Observable<any> {
    const subject = new Subject<any>();
    const envData = this.environmentData.getEnvData();
    const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.evaluateMvel + entityName;
    this.authService.validateAuthToken().subscribe(
      (isValidToken: boolean) => {
        this.httpClient.post<any>(
          url,
          payload,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.body) {
                subject.next(response.body);
              }
            },
            (err: HttpErrorResponse) => {
              // All errors are handled in ErrorInterceptor, no further handling required
              // Unless any specific action is to be taken on some error

              subject.error(err);
            }
          );
      }
    );

    return subject.asObservable();
  }
  getDataModelLookupByType(datamodelName: string, type: string, attributeName?: string, parentGroupName?: string): Observable<any> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any>();
    let url = "";
    if (parentGroupName) {
      url = `${envData.baseURL + '/flow-api/' + envData.datamodelurl + 'lookup/' + datamodelName + '/' + attributeName + '/USER/' + parentGroupName}`;
    } else {
      let suffix = ""
      switch (type) {
        case 'GROUP':
          suffix = '/'+attributeName+'/GROUP';
          break;
        case 'USER':
          suffix = '/' + attributeName + '/USER';
          break;
        case 'PRIORITY':
          suffix = '/priority/PRIORITY';
          break;
        case 'STATUS':
          suffix = '/status/STATUS';
          break;

        default:
          break;
      }
      url = `${envData.baseURL + '/flow-api/' + envData.datamodelurl + 'lookup/' + datamodelName + suffix}`;
    }
    this.httpClient.get<DataModel>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<DataModel>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error

          subject.error(err);
        }
      );


    return subject.asObservable();
  }

   /** 
  *  This section checks Validation for entire Entity
  */
   getValidationForEntityId(entityId:string): Observable<any[]> {
    const subject = new Subject<any[]>();
    if(entityId){
      const envData = this.environmentData.getEnvData();
      const url = envData.baseURL + envData.flowAPI + "entity/validation/" +entityId + "/0/50";

      this.httpClient.get<any[]>(
        url,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<any[]>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error

            subject.error(err);
          }
        );
    }else{
      subject.error(false);
    }
    return subject.asObservable();
  }
  getValidationCountForEntityId(entityId:string): Observable<any[]> {
    const subject = new Subject<any[]>();
    if(entityId){
      const envData = this.environmentData.getEnvData();
      const url = envData.baseURL + envData.flowAPI + "entity/validation/count/" +entityId;

      this.httpClient.get<any[]>(
        url,
        {
          observe: 'response',
          reportProgress: true,
          withCredentials: true
        }
      )
        .subscribe(
          (response: HttpResponse<any[]>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error

            subject.error(err);
          }
        );
    }else{
      subject.error(false);
    }
    return subject.asObservable();
  }
  /**
   * This section is for Getting not required field that are shown for a Datamodel
   */
  getEntityCommonFields(): Observable<any[]> {
    const envData = this.environmentData.getEnvData();
    const subject = new Subject<any[]>();

    let url = envData.baseURL + envData.flowAPI;
    if (!url.includes("/flow-api/") && localStorage.getItem(SecuredStorageService._X_AUTH_TOKEN)) {
      url += "entity/getEntityCommonFields";
    } else {
      url += "dataModel/getEntityCommonFields";
    }
    

    this.httpClient.get<string[]>(
      url,
      {
        observe: 'response',
        reportProgress: true,
        withCredentials: true
      }
    )
      .subscribe(
        (response: HttpResponse<any[]>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          subject.error(err);
        }
      );

    return subject.asObservable();
  }
}