<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding mt-2 embed-responsive embed-responsive-16by9">
    <iframe
        [id]="videoPlayerId"
        class="embed-responsive-item"
        [src]="videoPlayerUrl"
        width="1024"
        height="576"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true">
    </iframe>
</div>

<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
    </label>

    <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
        <img src="assets/faIcons/fa-info-circle.svg" height="12" />
    </span>

    <label *ngIf="field.value" [class]="getFieldClass(field, '-value', 'ml-2')">
        {{field.value.fileName}}
    </label>

    <input
        [id]="'videoUpload' + field.name + getUniqueIndex(field)"
        type="file"
        (change)="onDocumentUpload($event,field)"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null" />
        
    <input
        type="hidden"
        name="fileHidden"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null" />
    
    <br>

    <label
        *ngIf="!checkForRefrenceField(field)"
        [attr.for]="'videoUpload' + field.name + getUniqueIndex(field)"
        [class]="getFieldClass(field, '-button', 'btn btn-outline-info')">
        <img src="assets/faIcons/fa-upload.svg" height="12" /> Upload {{field.label}}
    </label>

    <ng-container *ngIf="field.value">
        <label
            [class]="getFieldClass(field, '-button', 'btn btn-outline-info ml-2')"
            (click)="onVideoPreview(videoPlayer, field)">
            <img src="assets/faIcons/fa-eye.svg" height="12" />
        </label>
        
        <label
            [class]="getFieldClass(field, '-button', 'btn btn-secondary ml-2')"
            (click)="onRemoveDocument(deleteBox, field)">
            <img src="assets/faIcons/fa-trash.svg" height="12" />
        </label>

        <label
            [class]="getFieldClass(field, '-button', 'btn btn-info ml-2')"
            (click)="downloadFile(field)">
            <img src="assets/faIcons/fa-download.svg" height="12" />
        </label>
    </ng-container>
    
    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>
    
    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>

    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>

<!-- Delete file Modal -->
<ng-template #deleteBox>
    <div class="alertbox">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" (click)="onDocumentDeleteCancel()">&times;</button>
        </div>
        <div class="modal-body">
            <p>Are you sure you want to remove the video?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="onDocumentDeleteConfirmation()">Yes</button>
            <button type="button" class="btn btn-success" data-dismiss="modal" (click)="onDocumentDeleteCancel()">No</button>
        </div>
    </div>
</ng-template>

<ng-template #videoPlayer style="background-color: #0000">
    <div>
        <div class="modal-body embed-responsive embed-responsive-16by9">
            <iframe
              id="videoPreviewPlayer"
              class="embed-responsive-item"
              [src]="videoPlayerUrl"
              [width]="videoWidth"
              height="auto"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen>
            </iframe>
        </div>
    </div>
</ng-template>