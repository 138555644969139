import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent extends BaseFieldDirective {

  showPassword: boolean = false;

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  getViewModeValue() {
    if (this.showPassword) {
      return this.field.value;
    } else {
      return this.field.value.replace(/./g, '*');
    }
  }

  toggleVisibility() {
    this.showPassword = !this.showPassword;
  }
}
