import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuid } from 'uuid';
import { DataModel } from './models/data-model.model';
import { APIService } from './services/api.service';
import { DataModelService } from './services/data-model.service';
import { EntityService } from './services/entity.service';
import { EnvironmentData } from './services/environments.service';
import { ApiResponseQueue, AuthService, DataSharingService, EntitySharingService, LoaderService, SecuredStorageService, Status, UserAccessControlStorageService } from './services/shared.service';
import { AccessControlResponse } from './models/accessControleResponse.model';
import { AccessControlService } from './services/accesscontrol.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnChanges, OnDestroy {
  title = 'wizard-ui';
  envLoaded: boolean = false;
  showThankYouPage: boolean = false;
  thankYouPageHtml: string = '';
  manualStateEntityAction: boolean = false;

  dataModel: DataModel;
  entity: any;
  errorMsg: string;

  viewRefreshed: boolean = true;

  _apikey: string;

  get apikey(): string {
    return this._apikey;
  }

  @Input() set apikey(apikey: string) {
    if (this.apikey && this.apikey.trim().length > 0) {
      this._apikey = apikey;
    }
  }

  _bearertoken: string;

  get bearertoken(): string {
    return this._bearertoken;
  }

  @Input() set bearertoken(bearertoken: string) {
    if (bearertoken && bearertoken.trim().length > 0) {
      this._bearertoken = bearertoken;
      this.storageService.setItem(SecuredStorageService._BEARER_TOKEN, bearertoken);
      this.storageService.removeItem(SecuredStorageService._GENERATE_TOKEN);
    } else {
      if (!this._bearertoken) {
        this.storageService.setItem(SecuredStorageService._GENERATE_TOKEN, true);
      }
    }
  }

  _entityid: string;

  get entityid(): string {
    return this._entityid;
  }

  @Input() set entityid(entityid: string) {
    if (entityid) {
      this._entityid = entityid;
    }
  }

  _datamodelname: string;

  get datamodelname(): string {
    return this._datamodelname;
  }

  @Input() set datamodelname(datamodelname: string) {
    if (datamodelname) {
      this._datamodelname = datamodelname;
      this.storageService.setItem(SecuredStorageService._DATA_MODEL_NAME, datamodelname);
    }
  }

  _companyid: string;

  get companyid(): string {
    return this._companyid;
  }

  @Input() set companyid(companyid: string) {
    if (companyid) {
      this._companyid = companyid;
      this.storageService.setItem(SecuredStorageService._COMPANY_ID, companyid);
    }
  }

  _baseurl: string;

  get baseurl(): string {
    return this._baseurl;
  }

  @Input() set baseurl(baseurl: string) {
    if (baseurl) {
      this._baseurl = baseurl;
      this.environmentData.setRootUrl(baseurl);
    }

  }

  _uimode: 'Default' | 'Wizard Mode' | 'View Mode';

  get uimode(): 'Default' | 'Wizard Mode' | 'View Mode' {
    return this._uimode;
  }

  @Input() set uimode(uimode: 'Default' | 'Wizard Mode' | 'View Mode') {
    if (!uimode || uimode.trim().length == 0) {
      this._uimode = 'Wizard Mode';
    } else if (!['Default', 'Wizard Mode', 'View Mode'].includes(uimode)) {
      this._uimode = 'Wizard Mode';
    } else {
      this._uimode = uimode;
    }
  }

  _userinfo: 'any';

  get userinfo(): 'any' {
    return this._userinfo;
  }

  @Input() set userinfo(userinfo: 'any') {
    if (userinfo) {
      this._userinfo = userinfo;
      this.storageService.setItem(SecuredStorageService._USER_INFO, userinfo);
    }
  }

  _duplicateentity: boolean;

  @Input() set duplicateentity(duplicateentity: boolean) {
    this._duplicateentity = duplicateentity;
  }

  _useraccesscontrol: AccessControlResponse;

  get useraccesscontrol(): AccessControlResponse {
    return this._useraccesscontrol;
  }

  @Input() set useraccesscontrol(accessControleResponse: AccessControlResponse) {
    if (accessControleResponse) {
      this._useraccesscontrol = accessControleResponse;
      this.userAccessControlStorageService.setAccessControl(this._useraccesscontrol);
    }
  }


  _renderui: 'true' | 'false' = 'true';

  get renderui(): 'true' | 'false' {
    return this._renderui;
  }

  @Input() set renderui(renderui: 'true' | 'false') {
    if (!renderui) {
      this._renderui = 'true';
    } else {
      this._renderui = renderui;
    }
    this.storageService.setItem(SecuredStorageService._RENDER_UI, renderui);
  }

  @Output() onsave: EventEmitter<any> = new EventEmitter();
  @Output() onsubmit: EventEmitter<any> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private dataModelService: DataModelService,
    private entityService: EntityService,
    private apiService: APIService,
    private changeDetector: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private storageService: SecuredStorageService,
    private entitySharingService: EntitySharingService,
    private apiResponseQueue: ApiResponseQueue,
    private toastrService: ToastrService,
    public loaderService: LoaderService,
    private environmentData: EnvironmentData,
    private userAccessControlStorageService: UserAccessControlStorageService,
    private accessControlService: AccessControlService,
    private authService: AuthService,
    private dataSharingService: DataSharingService
  ) {

    this.environmentData.fetchEnvData().subscribe(
      envData => {
        if (envData) {
          this.envLoaded = true;
          AppComponent.loadGoogleAnalytics(envData.GATag); //TODO : shift this to get data from Company ID
        }
      });

    this.route.queryParams.subscribe(params => {
      if (params['entityId']) {
        this.entityid = params['entityId'];
      }

      if (params['dataModelName']) {
        this.datamodelname = params['dataModelName'];
      }

      if (params['companyId']) {
        this.companyid = params['companyId'];
      }

      if (params['bearerToken'] && params['bearerToken'].trim().length > 0) {
        this.bearertoken = params['bearerToken'];
      } else {
        this.storageService.setItem(SecuredStorageService._GENERATE_TOKEN, true);
      }

      if (!params['uiMode'] || params['uiMode'].trim().length == 0) {
        this.uimode = 'Wizard Mode';
      } else if (!['Default', 'Wizard Mode', 'View Mode'].includes(params['uiMode'])) {
        this.uimode = 'Wizard Mode';
      } else {
        this.uimode = params['uiMode'];
      }
      if (params['apiKey'] && params['apiKey'].trim().length > 0) {
        this._apikey = params['apiKey'].trim();
      }
      if (params['dataModelName'] && params['companyId'] && !params["waitForToken"]) {
        this.environmentData.fetchEnvData().subscribe(
          envData => {
            if (envData) {
              this.envLoaded = true;
              if(this.companyid)
                this.getCompanyPref(this.companyid);
                this.fetchLatestActiveDataModel(this.datamodelname, this.entityid);
              }
          });
      }
      // if (!this.envLoaded) {
      //   this.envLoaded = true;
      //   this.fetchLatestActiveDataModel(this.datamodelname, this.entityid);
      // }
      if (params['renderui']) {
        this.renderui = params['renderui'];
        this.storageService.setItem(SecuredStorageService._RENDER_UI, this.renderui);
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    this.apiService.clearAll();
    //this.storageService.clearStorage();
    this.entitySharingService.clearAll();

    this.datamodelname = null;
    this.entityid = null;
    this.companyid = null;
    this.bearertoken = null;
    this.uimode = null;
    this.renderui = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datamodelname && changes.datamodelname.currentValue) {
      if (!this.envLoaded) {
        this.environmentData.fetchEnvData().subscribe(
          envData => {
            if (envData) {
              this.envLoaded = true;
              this.fetchLatestActiveDataModel(this.datamodelname, this.entityid);
            }
          });
      }
      else {
        // this.fetchLatestActiveDataModel(this.datamodelname, this.entityid);
      }
    }
  }

  ngAfterViewInit() {
    window.addEventListener('message', (event) => {
      this.performEventAction(event);
    });
    this.route.queryParams.subscribe(params => {
      if (params["eventOnLoad"] == "true") {
        this.raiseParentEvent("WIZARD_UI", {code: "LOADED"});          
      }
    });
    
  }

  performEventAction(event) {
    if (event.data && event.data.data && event.data.eventId) {
      if (event.data.eventId = "AUTH_TOKEN" && event.data.data.token && event.data.data.user) {
        this.storageService.setXAuthToken(event.data.data.token);
        this.storageService.setUniversalUser(event.data.data.user);
        this.environmentData.fetchEnvData().subscribe(
          envData => {
            if (envData) {
              this.envLoaded = true;
              this.environmentData.setFlowApiByUser(event.data.data.user);
              this.fetchLatestActiveDataModel(this.datamodelname, this.entityid);
            }
          });
        
        
      }
    }
  }

  
  fetchLatestActiveDataModel(dataModelName: string, entityId: string, withoutAccessControl?: boolean) {
    if (dataModelName) {
      if (!this.loginRequired()) {
        if (withoutAccessControl) {
          this.setDataModel(dataModelName, entityId);
          return;
        }
        if (!this.useraccesscontrol) {
          this.accessControlService.getAccessControlForEntity(dataModelName)
            .subscribe(
              response => {
                if (!response || !response.operationAccessList || response.operationAccessList.length == 0) {
                  if (this.storageService.getUniversalUser().authorities.includes("ROLE_EXTERNAL_USER")) {
                    this.errorMsg = "You don't have access to perform any operation on this form";
                    return;
                  }
                }
                this.useraccesscontrol = response;
                this.setDataModel(dataModelName, entityId);
              },
              error => {

              }
            )
        }
        else {
          this.setDataModel(dataModelName, entityId);
        }

      }

    }
  }

  setDataModel(dataModelName: string, entityId: string) {
    this.dataModelService.getDataModelByName(dataModelName).subscribe(
      dataModel => {
        if (dataModel) {
          this.dataModel = dataModel;
          this.apiService.setDataModelId(this.dataModel._id);
          this.apiService.setDataModel(dataModel);
          this.fetchEntity(dataModel.name, entityId);
        }
      },
      error => {

      }
    );
  }

  fetchEntity(dataModelName, entityId) {
    if (dataModelName && entityId) {
      if (this._duplicateentity) {
        this.entityService.getDuplicateEntity(dataModelName, entityId)
          .subscribe(
            entity => {
              entity["_documentId"] = uuid();
              this.onFetchEntity(entity);
            }
          )
      }
      else {
        this.entityService.getEntity(dataModelName, entityId).subscribe(
          entity => {


            if (!entity["_documentId"]) {
              entity["_documentId"] = uuid();
            }
            if(!this.userinfo && entity.statusCd){
              if (this.dataModel.uiProperties && this.dataModel.uiProperties.disableUserFlowOnComplete && (entity.statusCd == 'INPROGRESS' || entity.statusCd == "ACTIVE")) {
                this.uimode = 'View Mode';
              }
            }
            this.onFetchEntity(entity);
          },
          error => {
            this.entity = {};
            this.apiService.setEntityId(null);
          }
        );
      }
    } else {
      this.entity = {};
      this.entity["_documentId"] = uuid();
      this.apiService.setEntityId(null);
      this.onFetchEntity(this.entity);
    }
  }

  onFetchEntity(entity: any) {
    if (entity) {
      this.entity = entity;
      this.apiService.setEntityId(this.entity._id);
      this.apiService.setEntity(JSON.parse(JSON.stringify(entity)))
    } else {
      this.entity = {};
      this.apiService.setEntityId(null);
    }

    this.changeDetector.detectChanges();

    if (this.dataModel.onLoadApi) {
      const payload = {
        'event': 'ON_LOAD'
      };

      this.apiService.triggerApiOrRouteOrMvel(this.dataModel.onLoadApi, payload, false).subscribe(
        response => {
          this.apiResponseQueue.push(response);
          if(response && response.payload && response.payload.transientContext){
            this.dataSharingService.transientContext = response.payload.transientContext;
          }
        }
      );
    }
  }

  onSaveOrSubmit(emittedMap?: any) {
    if (emittedMap && emittedMap['type']) {
      if (emittedMap['type'] == 'save') {
        this.saveEntity(emittedMap['model'], emittedMap['entity']);
      } else if (emittedMap['type'] == 'submit') {
        this.submitEntity(emittedMap['model'], emittedMap['entity']);
      }
    }
  }


  saveEntity(dataModel: DataModel, entity: any) {
    this.loaderService.show();

    const entityMap = this.entitySharingService.getEntityMap(dataModel.fields, entity);
    // entityMap['entityName'] = dataModel.name;
    if(dataModel && dataModel.uiProperties.disableUserFlowOnComplete == true){
      entityMap["statusCd"] = Status.INPROGRESS;
    }else{
      entityMap["statusCd"] = Status.DRAFT;
    }
    this.entityService.saveEntity(dataModel, entityMap).subscribe(
      savedEntity => {
        this.toastrService.success(this.dataModel.label + ' saved successfully!', 'Saved');

        this.viewRefreshed = false;
        this.changeDetector.detectChanges();

        if (savedEntity && savedEntity._id) {
          this.entity = savedEntity;
          this.removeParentIdField(dataModel);

          // After entity save
          if (this.entity && this.entity['_id']) {
            this.apiService.setEntityId(this.entity['_id']);
          }

          let queryParamMap = this.queryParamToMap(window.location.search.replace("?",""));

          if (this.dataModel.onSaveApi) {
            const payload = {
              'event': 'ON_SAVE',
              '_queryParam': queryParamMap
            };

            this.apiService.triggerApiOrRouteOrMvel(this.dataModel.onSaveApi, payload, false).subscribe(onSaveApiResponse => {
              this.onsave.emit(savedEntity);
              this.raiseParentEvent('onsave', savedEntity);
              this.pageRefresh();
            });

          } else {
            this.onsave.emit(savedEntity);
            this.raiseParentEvent('onsave', savedEntity);
            this.pageRefresh();
          }


          this.loaderService.hide();
          if (dataModel.uiProperties.showThankYouPage && dataModel.uiProperties.showThankYouPage == true) {
            this.showThankYouPage = true;
            if (dataModel.uiProperties.thankYouHtml && dataModel.uiProperties.thankYouHtml == '') {
              this.thankYouPageHtml = "<div class='col-md-12 text-center'><h2 class='thankYouHead'>Thank You</h2><p class='thankYouText'>Your submission has been noted.</p></div>";
            } else {
              this.thankYouPageHtml = dataModel.uiProperties.thankYouHtml;
            }
          } else {
            this.showThankYouPage = false;
          }
        }
        else {

          this.pageRefresh();

        }

      }, error => {
        this.loaderService.hide();

        if (error.error.message) {
          this.toastrService.error(error.error.message, 'Oops');
        } else {
          this.toastrService.error('Failed to save ' + this.dataModel.label + '. Please try again.', 'Oops');
        }
      }
    );
  }

  pageRefresh() {

    setTimeout(() => {
      this.viewRefreshed = true;
      this.changeDetector.detectChanges();
      this.loaderService.hide();
    }, 10);

  }

  submitEntity(dataModel: DataModel, entity: any) {
    this.loaderService.show();

    const entityMap = this.entitySharingService.getEntityMap(dataModel.fields, entity);

    this.entityService.submitEntity(dataModel, entityMap).subscribe(
      submittedEntity => {
        this.toastrService.success(this.dataModel.label + ' submitted successfully!', 'Submitted');

        this.viewRefreshed = false;
        this.changeDetector.detectChanges();

        if (submittedEntity && submittedEntity._id) {
          this.entity = submittedEntity;
          this.removeParentIdField(dataModel);

          // After entity save
          if (this.entity && this.entity['_id']) {
            this.apiService.setEntityId(this.entity['_id']);
          }

          let queryParamMap = this.queryParamToMap(window.location.search.replace("?",""));

          if (this.dataModel.onSaveApi) {
            const payload = {
              'event': 'ON_SUBMIT',
              '_queryParam': queryParamMap
            };

            this.apiService.triggerApiOrRouteOrMvel(this.dataModel.onSaveApi, payload, false).subscribe(onSaveApiResponse => {
              this.onsubmit.emit(submittedEntity);
              this.raiseParentEvent('onsubmit', submittedEntity);
              this.pageRefresh();
            });

          } else {
            this.onsubmit.emit(submittedEntity);
            this.raiseParentEvent('onsubmit', submittedEntity);
            this.pageRefresh();
          }


          this.loaderService.hide();
          if (dataModel.uiProperties.showThankYouPage && dataModel.uiProperties.showThankYouPage == true) {
            this.showThankYouPage = true;
            if (dataModel.uiProperties.thankYouHtml && dataModel.uiProperties.thankYouHtml == '') {
              this.thankYouPageHtml = "<div class='col-md-12 text-center'><h2 class='thankYouHead'>Thank You</h2><p class='thankYouText'>Your submission has been noted.</p></div>";
            } else {
              this.thankYouPageHtml = dataModel.uiProperties.thankYouHtml;
            }
          } else {
            this.showThankYouPage = false;
          }
        } else {
          this.pageRefresh();
        }


      }, error => {
        this.loaderService.hide();

        if (error.error.message) {
          this.toastrService.error(error.error.message, 'Oops');
        } else {
          this.toastrService.error('Failed to submit ' + this.dataModel.label + '. Please try again.', 'Oops');
        }
      }
    );
  }

  removeParentIdField(dataModel: DataModel) {
    if (dataModel && dataModel.fields && dataModel.fields.length > 0) {
      const indexToRemove: number[] = [];

      for (let index = 0; index < dataModel.fields.length; index++) {
        const field = dataModel.fields[index];

        if (field && field.type && field.type == 'PARENT_ID_SELECTOR') {
          indexToRemove.push(index);
        }
      }

      if (indexToRemove.length > 0) {
        for (const index of indexToRemove) {
          dataModel.fields.splice(index, 1);
        }
      }
    }
  }

  getSanitisedHtml(html: string) {
    if(html){
      var re = /{{{(.*?)}}}/;
      let splitString = html.split(re);
      if (splitString.length == 1 ) {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
      }else {
        let outputHtml ="";
        for (let index = 0; index < splitString.length; index++) {
          const element = splitString[index];
          
          if (element.indexOf('routeVar::') > -1) {
            let varPath = element.replace("routeVar::","");
            if(this.dataSharingService.transientContext){
            outputHtml += this.dataSharingService.transientContext[varPath];
          }
          }else{
            outputHtml += element;
          }
        }
        return this.domSanitizer.bypassSecurityTrustHtml(outputHtml);
      }
    }
  }

  onAuthSuccess(event) {
    this.fetchLatestActiveDataModel(this.datamodelname, this.entityid, event.jwt != null);
  }

  loginRequired() {
    return this.authService.loginRequired();
  }
  raiseParentEvent(type, eventData) {
    window.parent.postMessage(
      {
        event_id: type,
        data: eventData
      },
      "*" //or "www.parentpage.com"
    );
  }
  queryParamToMap(queryParamString:string){
    let qPArray = queryParamString.split("&");
    let allowedQueryParams = ['utm_source','utm_medium','utm_campaign','utm_id','utm_term','utm_content','context1','context2'];
    let qPMap = {}
    for (let index = 0; index < qPArray.length; index++) {
      const element = qPArray[index].split("=");
      if(element && element.length>0 && ![null,undefined].includes(element[0]) && ![null,undefined].includes(element[1])){
        if(allowedQueryParams.includes(element[0])){
          qPMap[element[0]] = element[1];
        }
      }
    }
    return qPMap;
  }
  getCompanyPref(companyId) {
      this.authService.getCompanyDetails(companyId).subscribe(responseString => {
        if (responseString) {
          this.dataSharingService.clientLogo = ((responseString)); // r   
        }
      })
    
  }
  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  static loadGoogleAnalytics(trackingID: string): void {

    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  /** ToDo List for View Mode
   * Provision to fire the configured api/routes - Done except for field value change, currently only sending entity id in request
   *   - Trigger
   *     - Data model on load and on complete (before save/submit call) - Done
   *     - Page on load and on complete (on page exit) - Done
   *     - On field value change
   *     - On carousel change - only when view as carousel is true - Done
   *   - Action on Response
   *     - Populate fields' values from response
   *     - Update source values for fields (dropdown, lookup, etc.)
   *     - Show/Hide fields, groups, pages
   *     - Make fields mandatory/non-mandatory
   *     - Show/Hide fields in view mode
   * Entity Internal Save - On the go save on various events
   *   - Page last known position :: [on page transition] - Done
   *   - Page max reached position (Page Progress) :: [on page transition] - Done
   *   - Carousel last know position :: [on carousel transition] - Done
   *     - For nested model list being shown as carousel
   *     - Store position in parent field where nested model list is defined
   *   - Carousel max reached position (Carousel Progress) :: [on carousel transition] - Done
   *     - For nested model list being shown as carousel
   *     - Store position in parent field where nested model list is defined
   *   - View Mode Properties
   *     - Video last known duration :: [after every 5 sec when video is being played] - Done
   *     - Video watched till end (boolean) :: [on video finished] - Done
  */
}