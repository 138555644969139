<div class="modal-header">
  <h4 class="modal-title pull-left">{{entityRefField.label}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ul  class="list-group">
      <li class="list-group-item"  *ngFor="let item of entityRefField.listvalue | paginate: { itemsPerPage: 10, currentPage: p }; let i=index">
          <mat-accordion (click)="openEntityUpdateModal(entityRefField,(p - 1) * 10 + i)">
              <mat-panel-title>
                  <strong style="line-height: 40px;">{{item._lookup_reference_label}} <img src="assets/faIcons/fa-chevron-right.svg" height="12" /></strong>
              </mat-panel-title>
          </mat-accordion>
      </li>
  </ul>
  <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div>