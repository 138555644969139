import { AuthConfigurationModel } from "./authConfiguration.model";

export class ValidateApiKeyRequest {
    apiKey: string;
	username: string;
	password: string;
	otp: string;
	sendOtp: boolean;
	authConfiguration: AuthConfigurationModel;
	functionInstanceNameForOTP : string;

    constructor() {
        
    }

}