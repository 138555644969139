import { BaseModel } from "./base.model";

export class User extends BaseModel {
    username: string;
    password: string;
    email: string;
    mobile: string;
    name: string;
    designation: string;
    enabled: boolean;
    authorities: string[];
    groups: UserGroup[];
    groupAsStringList: string[];
    credentialsNonExpired: boolean;
    accountNonLocked: boolean;
    accountNonExpired: boolean;
    autoAssignFlag: boolean;
    sla: SLA;
    alertPermission: {}
    isWhiteLabel: boolean;
    logoUrl: string;
    imageUrl: string;
    restrictPeersAllocation: boolean;
    uspEnable: boolean;
    currentCompanyId: string;
    selectedApplicationId: string;
    companyAccountStage: string;
    changePwdOnLogin: boolean;
    jwt: string;
    _basePath: string;
}

export class UserGroup {
    userGroupCd: string;
    expression: string;
    parentUserGroupCd: string;
    escalationUserGroupCd: string;

    constructor() {
        this.userGroupCd = '';
        this.expression = '';
        this.parentUserGroupCd = '';
        this.escalationUserGroupCd = '';
    }
}

export class SLA extends BaseModel {

    slaUnit: string;
    level: number;
    unit: number;
    escalationLevel: number;


    constructor() {
        super();
        this.slaUnit = '';
        this.level = 0;
        this.unit = 0;
        this.escalationLevel = 0;

    }
}