import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends BaseFieldDirective {

  defaultClass: string = 'btn btn-outline-info';

  @Output()
  onClick: EventEmitter<any> = new EventEmitter();

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  updateFieldValue(changedField: { fieldName: string, inputSource: any, value: any, formGroup: FormGroup }): void {
    // Setting _status to true, if not present
    if (changedField.value && Object.keys(changedField.value).length > 0) {
      if (changedField.value['_status'] == null || changedField.value['_status'] == undefined) {
        changedField.value['_status'] = true;
      }

      if (!changedField.value['_status'] && changedField.value['_statusMessage']) {
        this.errorMap[this.field.name] = changedField.value['_statusMessage'];
      } else {
        delete this.errorMap[this.field.name];
      }
    } else {
      delete this.errorMap[this.field.name];
    }

    this.field.value = changedField.value;
    this.parentFormGroup.controls[this.field.name].patchValue(changedField.value);
  }

  onButtonClick() {
    if (this.field.onClickApi) {
      this.onClick.emit(this.field.onClickApi);
    }
  }

  getStatusMessage() {
    if (this.field && this.field.value && this.field.value['_statusMessage']) {
      return this.field.value['_statusMessage'];
    }
  }
}
