import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { DataSharingService, FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';
import { APIService } from 'src/app/services/api.service';

@Component({
  selector: 'app-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrls: ['./html-viewer.component.scss']
})
export class HtmlViewerComponent extends BaseFieldDirective {

  constructor(
    private domSanitizer: DomSanitizer,
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog,
    private apiService: APIService,
    private dataSharingService: DataSharingService
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  getViewModeValue() {
    let value = super.getViewModeValue();

    return value.split(/\n/);
  }
  getHtmlContent(htmlDoc:any){
    if(htmlDoc){
      var re = /{{{(.*?)}}}/;
      let splitString = htmlDoc.split(re);
      if (splitString.length == 1 ) {
        return this.domSanitizer.bypassSecurityTrustHtml(htmlDoc);
      }else {
        let outputHtml ="";
        for (let index = 0; index < splitString.length; index++) {
          const element = splitString[index];
          
          if (element.indexOf('routeVar::') > -1) {
            let varPath = element.replace("routeVar::","");
            if(this.dataSharingService.transientContext){
            outputHtml += this.dataSharingService.transientContext[varPath];
          }
          }else{
            outputHtml += element;
          }
        }
        return this.domSanitizer.bypassSecurityTrustHtml(outputHtml);
      }
    }
  }

}