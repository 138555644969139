<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle view-label')">
        {{field.label}}:
    </label>

    <div *ngIf="field.value && !field.list" class="form-control disabled view-value">{{field.value}}</div>

    <ng-container *ngIf="field.value && field.list">
        <span *ngFor="let item of field.value; let lastIndex = last" [class]="getFieldClass(field, '-view-value')">{{ item + (lastIndex ? '' : ',&nbsp;') }}</span>
    </ng-container>
    <div *ngIf="field.value == undefined || field.value == null" class="form-control disabled view-value">&nbsp;</div>
</div>

<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    

    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
        <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
            <img src="assets/faIcons/fa-info-circle.svg" height="12" />
        </span>
    </label>

    

    <input *ngIf="!field.list && !isMaskPresent()"
        [class]="getFieldClass(field, '-value', defaultClass)"
        type="text"
        [id]="field.name"
        [(ngModel)]="field.value"
        [placeholder]="field.placeholder"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name" />

    <input *ngIf="!field.list && isMaskPresent()"
        [class]="getFieldClass(field, '-value', defaultClass)"
        type="text"
        [id]="field.name"
        [(ngModel)]="field.value"
        [placeholder]="field.placeholder"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [mask]="field.mask"
        [formControlName]="field.name" />

    <!-- Didn't add form-control to class deliberately -->
    <tag-input *ngIf="field.list"
        [class]="getFieldClass(field, '-value', tagInputClass)"
        style="border: 1px solid #A0A0A0;"
        [formControlName]="field.name"
        [id]="field.name"
        [(ngModel)]="field.value"
        [modelAsStrings]="true"
        [editable]="true"
        [separatorKeys]="[32]"
        placeholder="+Input (Enter separated)"
        secondaryPlaceholder="Insert values (Enter separated)"
        theme="minimal"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null">
    </tag-input>
    
    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>
    
    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>