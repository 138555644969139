// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL : "https://api-dev.automatapi.com",
  basePath : "/flow-api",
  vimeoVideoUrl: "https://player.vimeo.com/video/",
  flowAPI: "/flow-api/",
  tokenAuthorizer: "/api/authorizerv2/gettoken",
  routeBaseUrl: "route/",
  apiConfigBaseUrl: "apiConfig/",
  datamodelurl: "dataModel/",
  datamodelsaveurl: "dataModel/savedatamodel",
  entityurl: "dataModel/",
  entitrefurl: "lookuprefid/",
  entitysaveurl: "dataModel/save",
  entitysubmiturl: "dataModel/submit",
  lookupurl: "lookup/",
  reflookupurl: "referencelookup/",
  getrefentity: "searchcriteria/",
  evaluateMvel: "evaluateMvel/",
  fileUploadUrl: "api/uploadData",
  deleteDataFile: "api/deleteDataFile",
  pdfToImage: "/flow/interfaces/api/pdftoimage",
  routeUrl: "route/executeWithStatus/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
