import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { EnvironmentData } from 'src/app/services/environments.service';
import { AuthService } from './shared.service';
import * as i0 from "@angular/core";
import * as i1 from "./shared.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./environments.service";
export class DataModelService {
    constructor(authService, httpClient, environmentData) {
        this.authService = authService;
        this.httpClient = httpClient;
        this.environmentData = environmentData;
        this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    saveDataModel(datamodel) {
        const subject = new Subject();
        const environmentData = this.environmentData.getEnvData();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelsaveurl;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, datamodel, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    activate(_id) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + _id + "/activate";
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }).subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    updateDataModel(datamodel) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelsaveurl;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.put(url, datamodel, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getDataModel(id) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + id;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getDataModelByName(name) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + "name/" + name;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getLinkageModelById(linkageId) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + "linkage/" + linkageId;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getLinkageModelByParentId(parentId) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + 'linkage/parentid/' + parentId;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getDataModelLookupByType(datamodelName, type, attributeName, parentGroupName) {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject();
        let url = "";
        if (parentGroupName) {
            url = `${envData.baseURL + envData.flowAPI + envData.entityurl + 'lookup/' + datamodelName + '/' + attributeName + '/USER/' + parentGroupName}`;
        }
        else {
            let suffix = "";
            switch (type) {
                case 'GROUP':
                    suffix = '/' + attributeName + '/GROUP';
                    break;
                case 'USER':
                    suffix = '/' + attributeName + '/USER';
                    break;
                case 'PRIORITY':
                    suffix = '/' + attributeName + '/PRIORITY';
                    break;
                case 'STATUS':
                    suffix = '/' + attributeName + '/STATUS';
                    break;
                default:
                    break;
            }
            url = `${envData.baseURL + envData.flowAPI + envData.entityurl + 'lookup/' + datamodelName + suffix}`;
        }
        this.httpClient.get(url, {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
        })
            .subscribe((response) => {
            if (response.body) {
                subject.next(response.body);
            }
        }, (err) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
            subject.error(err);
        });
        return subject.asObservable();
    }
}
DataModelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataModelService_Factory() { return new DataModelService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.EnvironmentData)); }, token: DataModelService, providedIn: "root" });
