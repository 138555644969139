<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle view-label')">
        {{field.label}}:
    </label>

    <div *ngIf="field.value != null && field.value != undefined" class="form-control disabled view-value">
        <span
            *ngIf="field.value && field.type == 'DATE' && !(field.uiProperties && field.uiProperties.enableTimePicker)">
            {{ field.value | date: 'dd-MM-yyyy' }}
        </span>

        <span *ngIf="field.value && field.type == 'DATE' && field.uiProperties && field.uiProperties.enableTimePicker">
            {{ field.value | date: 'dd-MM-yyyy hh:mm A' }}
        </span>

        <span *ngIf="field.value && field.type == 'DATETIME'">
            {{ field.value | date: 'dd-MM-yyyy hh:mm A' }}
        </span>
    </div>
    <div *ngIf="field.value == undefined || field.value == null" class="form-control disabled view-value">&nbsp;</div>
</div>

<div *ngIf="!viewMode" class="col-md-12 no-horizontal-padding">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
    </label>

    <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
        <img src="assets/faIcons/fa-info-circle.svg" height="12" />
    </span>

    <div class="row no-gutters" *ngIf="field.type == 'DATE'">
        <div [class]="getFieldClass(field, '-value', getDatePickerClass())">
            <my-date-picker [placeholder]="field.placeholder ? field.placeholder : 'dd-mm-yyyy'"
                [options]="getDatePickerOptions()" [disabled]="checkForRefrenceField(field)" [(ngModel)]="dateFieldMap"
                (dateChanged)="onDateChanged($event)">
            </my-date-picker>
        </div>
        <div [class]="getFieldClass(field, '-value', 'col-5')"
            *ngIf="field.uiProperties && field.uiProperties.enableTimePicker">
            <timepicker class="mt-minus25" [disabled]="checkForRefrenceField(field)" [(ngModel)]="field.value"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="onTimeChanged($event)">
            </timepicker>
        </div>
    </div>

    <div class="row no-gutters" *ngIf="field.type == 'DATETIME'">
        <div [class]="getFieldClass(field, '-value', 'col-7 pr-2')">
            <my-date-picker [placeholder]="field.placeholder ? field.placeholder : 'dd-mm-yyyy'"
                [options]="getDatePickerOptions()" [disabled]="checkForRefrenceField(field)" [(ngModel)]="dateFieldMap"
                (dateChanged)="onDateChanged($event)">
            </my-date-picker>
        </div>
        <div [class]="getFieldClass(field, '-value', 'col-5')">
            <timepicker class="mt-minus25" [disabled]="checkForRefrenceField(field)" [(ngModel)]="field.value"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="onTimeChanged($event)">
            </timepicker>
        </div>
    </div>

    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>

    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>