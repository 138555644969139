import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dynamic-form-modal',
  templateUrl: './dynamic-form-modal.component.html',
  styleUrls: ['./dynamic-form-modal.component.scss']
})
export class DynamicFormModalComponent implements OnInit {

  entityItem: any;
  map = {};
  index: any;
  field: any;
  initialField: any;
  initialEvent: any;
  form: FormGroup;
  addNew:boolean = false;
  DataModelfields: any = {};
  showDoneButton: boolean = true;
  viewingNestedEntity: boolean = false;
  formMode: 'Default' | 'Wizard Mode' | 'View Mode';
  viewEntity: any;
  onSaveNestedEntity: Subject<any>;
  @Input() formData;
  @Output() emitted: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DynamicFormModalComponent>,

    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    dialogRef.disableClose = true;
    this.entityItem = data.entityItem;
    this.index = data.index;
    this.field = data.field;
    this.initialField = data.entityItem;
    this.initialEvent = data.event;
    this.viewingNestedEntity = data.viewingNestedEntity;
    this.formMode = data.formMode;
    this.viewEntity = data.viewEntity;
    this.onSaveNestedEntity = data.onSaveNestedEntity;
    this.form = new FormGroup({});
    this.addNew = data.addNew;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.previousValue != undefined) {

    }
  }

  ngOnInit() {
    this.updateInnerModel()
  }

  updateInnerModel(field?, event?) {
    if (event && event._id) {
      event["dialogRef"] = this.dialogRef;
      this.map["field"] = field;
      this.map["event"] = event;
      this.closeDialog(event)
    }
  }

  updateInnerModelList(field, event, index) {
    if (event && event._id) {
      event["dialogRef"] = this.dialogRef;
      this.map["field"] = field
      this.map["event"] = event;
    }
    this.map["index"] = index;
    this.closeDialog(event);
  }

  closeDialog(field?: any) {
    let event = this.map["event"];
    if (event) {
      event.viewEntity = true;
      this.map["event"] = event;
      if (this.index != undefined) {
        this.map["list"] = true
        this.map["index"] = this.index;
      }
      else {
        this.map["list"] = false
      }
      event["map"] = this.map;
    }
  }

  closeModal() {
    this.dialogRef.close({ event: 'closeFromTop', data: false });
  }

  saveNestedEntity(data) {
    if (this.onSaveNestedEntity) {
      const payload = {
        'viewEntity': data['viewEntity'],
        'field': this.entityItem
      }
      this.onSaveNestedEntity.next(payload);
    }
  }
}
