import { BaseModel } from './base.model';

export class DataModel extends BaseModel {
  label: string;
  name: string;
  fields: Field[];
  version: number;
  process: string;
  locked: boolean;
  type: string;
  subEntity: boolean;
  validators: ValidatorInstance[];
  onLoadApi: string;
  onSaveApi: string;
  pages: Page[];
  groups: Group[];
  retainPosition: boolean;
  trackProgress: boolean;
  showNavigationPanel: boolean;
  parentDataModelName: string;
	parentModelLocked: boolean;
  uiProperties: any;
    /* {
      modelStartHtml: string
      modelEndHtml: string
      modelCss: string
      theme: [Normal, View Mode (All fields will be represented as read-only labels and not disabled input boxes]
    } */

  constructor() {
    super();

    this.label = '';
    this.name = '';
    this.fields = [];
    this.version = 0;
    this.process = '';
    this.validators = [];
    this.type = '';
    this.locked = false;
    this.subEntity = false;
    this.uiProperties = {};
    this.onLoadApi = '';
    this.onSaveApi = '';
    this.pages = [];
    this.groups = [];
    this.retainPosition = false;
    this.trackProgress = false;
    this.showNavigationPanel = false;
    this.parentDataModelName = null;
    this.parentModelLocked = false;
    this.uiProperties.navPosition = "top";
    this.uiProperties.showNavigationPanel = true;
  }
}

export class Field {
  label: string;
  name: string;
  validators: ValidatorInstance[];
  extractors: ExtractorInstance[];
  modelName: string;
  locked: boolean;
  type: string;
  list: boolean;
  expression: string;
  sortOrder: number;
  dataPointName: string;
  value: any;
  inputSource: string[];
  mask: string;
  validationExpression: string;
  placeholder: string;
  errorMessage: string;
  hide: boolean;
  mandatory: boolean;
  secured: boolean;
  referenceModel: string;
  lookupEntityList: any[];
  dependencyExpression: string;
  dependencyExpressionList: DependencyExpression[];
  dateTypeName: string;
  viewEntity:boolean;
  disable: boolean;
  lookupType: string;
  parentAttributeCode: string;
  businessKey:boolean;
  uiProperties: any;
  /* {
    enableTimePicker: boolean
    fieldCss: string
    className: string
    fieldStartHtml: string
    fieldEndHtml: string
    hideInViewMode: boolean
  } */
  pageName: string;
  groupName: string;
  onChangeApi: string;
  onClickApi: string;
  displayAsCarousel: boolean;
  showNavigationPanel: boolean;
  retainPosition: boolean;
  trackProgress: boolean;
  onCarouselChangeApi: string;
  trackVideoProgress: boolean;
  onVideoCompleteApi: string;
  functionInstanceNameForFile: string;
  publicFile: boolean;

  constructor() {
    this.label = '';
    this.name = '';
    this.validators = [];
    this.extractors = [];
    this.modelName = '';
    this.list = false;
    this.sortOrder = 0;
    this.locked = false;
    this.expression = '';
    this.type = '';
    this.dataPointName = '';
    this.inputSource = [];
    this.mask = '';
    this.validationExpression = '';
    this.placeholder = '';
    this.errorMessage = '';
    this.mandatory = false;
    this.secured = false;
    this.referenceModel = '';
    this.lookupEntityList = [];
    this.dependencyExpression = null;
    this.dependencyExpressionList = [];
    this.hide = false;
    this.dateTypeName = '';
    this.disable = false;
    this.businessKey = false;
    this.uiProperties = {};
    this.pageName = '';
    this.groupName = '';
    this.onChangeApi = '';
    this.onClickApi = '';
    this.displayAsCarousel = false;
    this.showNavigationPanel = false;
    this.retainPosition = false;
    this.trackProgress = false;
    this.onCarouselChangeApi = '';
    this.trackVideoProgress = false;
    this.onVideoCompleteApi = '';
    this.functionInstanceNameForFile = null;
    this.publicFile = false;
  }
}

export class DependencyExpression {
  sequence: number;
  triggerEvent: string;
  expression: string;
  action: string;
  targetField: string;
  message: string;

  constructor() {
    this.sequence = 0;
    this.triggerEvent = '';
    this.expression = '';
    this.action = '';
    this.targetField = '';
    this.message = '';
  }
}

export class ValidatorInstance {
  name: string;
  params: any;
  constructor() {
    this.name = "";
    this.params = {};
  }
}

export class ExtractorInstance {
  name: string;
  params: any;
  constructor() {
    this.name = "";
    this.params = {};
  }
}

export class Page {
  name: string;
  label: string;
  sortOrder: number;
  wizardStepName: string; // Leave blank or null for default behaviour
  onLoadApi: string;
  onCompleteApi: string;
  onCompleteApiSync : boolean;
  uiProperties: any;
  /* {
      className: string,
      pageCss: string,
      pageStartHtml: string,
      pageEndHtml: string
    } */

  constructor() {
    this.name = '';
    this.label = '';
    this.sortOrder = 0;
    this.wizardStepName = '';
    this.onLoadApi = '';
    this.onCompleteApi = '';
    this.onCompleteApiSync = false;
    this.uiProperties = {};
  }
}

export class Group {
  name: string;
  label: string;
  sortOrder: number;
  uiProperties: any;
  /* {
      className: string,
      groupCss: string,
      groupStartHtml: string,
      groupEndHtml: string
    } */

  constructor() {
    this.name = '';
    this.label = '';
    this.sortOrder = 0;
    this.uiProperties = {};
  }
}

export class Entity extends BaseModel {
  label: string;
  name: string;
  fields: Field[];
  version: number;
  process: string;
  locked: boolean;

  constructor() {
    super();

    this.label = '';
    this.name = '';
    this.fields = [];
    this.version = 0;
    this.process = '';
    this.locked = false;
  }
}

export class LinkageModel extends BaseModel {
  parentDataModelName: string;
  parentId: string;
  childDataModelName: string;
  parentMatadata: LinkageMetadata;
  childMetadataList: LinkageMetadata[];

  constructor() {
    super();

    this.parentDataModelName = '';
    this.parentId = '';
    this.childDataModelName = '';
    this.parentMatadata = new LinkageMetadata();
    this.childMetadataList = [];
  }
}

export class LinkageMetadata {
  entityId: string;
  modelName: string;
  statusCd: string;
  createdBy: string;
  lastUpdatedBy: string;
  createdUserId: string;
  lastUpdatedUserId: string;
  createdAt: Date;
  lastUpdatedAt: Date;

  constructor() {
    this.entityId = '';
    this.modelName = '';
    this.statusCd = '';
    this.createdBy = '';
    this.lastUpdatedBy = '';
    this.createdUserId = '';
    this.lastUpdatedUserId = '';
    this.createdAt = new Date();
    this.lastUpdatedAt = new Date();
  }
}

export class EntitySearchCriteria {
  searchText: string;
	criteria: any;
	sort: any;

  constructor() {
    this.searchText = '';
    this.criteria = {};
    this.sort = {};
  }
}