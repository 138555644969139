import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiResponseQueue, DataSharingService, LoaderService, SecuredStorageService, UserAccessControlStorageService } from '../../../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { DataModel, Field, Group, Page } from 'src/app/models/data-model.model';
import { APIMetadata, APIService } from 'src/app/services/api.service';
import { DataModelService } from 'src/app/services/data-model.service';
import { EntityService } from 'src/app/services/entity.service';
import { CommunicationService, EntitySharingService, FieldOnChangeService, FileService } from 'src/app/services/shared.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-mode',
  templateUrl: './view-mode.component.html',
  styleUrls: ['./view-mode.component.scss']
})
export class ViewModeComponent extends DynamicFormComponent implements AfterViewChecked {
  reInitializeWizard = false;
  protected readonly formMode: string = 'View Mode';

  @ViewChild('wizard', { static: false })
  public wizardInstance: WizardComponent;

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    protected cd: ChangeDetectorRef,
    protected modalService: BsModalService,
    protected communicationService: CommunicationService,
    protected dataModelService: DataModelService,
    protected entityService: EntityService,
    protected fileService: FileService,
    protected entitySharingService: EntitySharingService,
    protected apiResponseQueue: ApiResponseQueue,
    protected toastrService: ToastrService,
    protected apiService: APIService,
    protected fieldOnChangeService: FieldOnChangeService,
    protected loaderService: LoaderService,
    protected domSanitizer: DomSanitizer,
    public dialog: MatDialog,
    protected userAccessControlStorageService: UserAccessControlStorageService,
    protected storageService: SecuredStorageService,
    protected dataSharingService: DataSharingService
  ) {
    super(
      router, snackBar, cd, modalService, communicationService,
      dataModelService, entityService, fileService,
      entitySharingService, apiResponseQueue, toastrService, apiService,
      fieldOnChangeService, loaderService, domSanitizer, dialog, userAccessControlStorageService,storageService,dataSharingService
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.createPageMap(this.model);
  }
  ngAfterViewChecked(): void {
    if (!this.reInitializeWizard && this.wizardInstance && !this.wizardInstance.currentStep) {
      this.reInitializeWizard = true;

      setTimeout(() => {
        this.reInitializeWizard = false;
      }, 500);
    }
  }
  createPageMap(model: DataModel) {
    model.pages.sort((a: Page, b: Page) => {
      return a.sortOrder > b.sortOrder ? 1 : a.sortOrder ? -1 : 0
    });

    if (model && model.pages) {
      for (const page of model.pages) {
        if (page && page.name != null && page.name != undefined) {
          this.pageMap.set(page.name, page);
        }
      }
    }
  }

  conditionForField(field: Field) {
    if (field && field.hide) {
      return false;
    }
    return field && !(field.uiProperties && field.uiProperties['hideInViewMode']);
  }

  arePagesPresent() {
    if (this.reInitializeWizard) {
      return false;
    }
    return this.selectedDataModel && this.selectedDataModel.pages && this.selectedDataModel.pages.length > 0;
  }

  isFieldInPage(page: Page, field: Field): boolean {
    if (page && field) {
      return field.pageName && page.name && field.pageName == page.name;
    }

    return false;
  }

  isFieldInGroup(group: Group, field: Field): boolean {
    if (group && field) {
      return field.groupName && group.name && field.groupName == group.name;
    }

    return false;
  }

  getDefaultStepIndex() {
    let currEntity = this.apiService.getEntity();
    if (currEntity && this.selectedDataModel.retainPosition) {
      if (currEntity['lastKnownPage'] != null && currEntity['lastKnownPage'] != undefined) {
        const lastKnownPage = this.pageMap.get(currEntity['lastKnownPage']);
        let pos = this.selectedDataModel.pages.map(item=>item.name).indexOf(lastKnownPage.name);
        if(pos == -1){
          return 0;
        }else{
          return pos
        }
        // if (lastKnownPage.sortOrder == this.selectedDataModel.pages.length) {
        //   return lastKnownPage.sortOrder - 1;
        // } else {
        //   return lastKnownPage.sortOrder;
        // }

      }
    }

    return 0;
  }

  isCompleted(page: Page) {
    if (page && this.viewEntity && this.selectedDataModel.retainPosition) {
      if (this.viewEntity['lastKnownPage'] != null && this.viewEntity['lastKnownPage'] != undefined) {
        const lastKnownPage = this.pageMap.get(this.viewEntity['lastKnownPage']);
        return lastKnownPage.sortOrder > page.sortOrder;
      }
    }

    return false;
  }

  getCurrentWizardStep() {
    return this.wizardInstance && this.wizardInstance.currentStep ? this.wizardInstance.currentStep : '';
  }

  onEnterStep(page:Page, movingDirection: any) {
    if (!page && this.wizardInstance && this.wizardInstance.currentStep) {
      const pageName = this.wizardInstance.currentStep.stepId;
      let page = this.pageMap.get(pageName);
    }

    if (page) {
      if (this.selectedDataModel.retainPosition || this.selectedDataModel.trackProgress) {
        const data = {
          'currentPage': page
        };

        if (this.selectedDataModel && this.selectedDataModel.pages && this.selectedDataModel.pages.length > 0
          && this.selectedDataModel.pages[this.selectedDataModel.pages.length - 1] == page) {
          data['pageFinished'] = true;
        }

        this.saveViewModeProperties(data);
      }

      if (page.onLoadApi) {
        const payload = {
          'pageName': page.name,
          'event': 'ON_PAGE_LOAD'
        };

        const apiMetadata = new APIMetadata(this.form, this.selectedDataModelFieldMap, this.errorMap, this.warningMap,this.backendErrorMap);

        const subscription = this.apiService.triggerApiOrRouteOrMvel(page.onLoadApi, payload, false, apiMetadata);

        if (subscription) {
          // this.loaderService.show();
          subscription.subscribe(
            result => {
              // this.loaderService.hide();

              if (result != null && result != undefined && result[APIService._IS_FORM_VALIDATION_NEEDED]) {
                this.isEntityFormValid.emit(this.checkFormisValid());
              }
            }
          );
        }
      }
    }
  }

  onExitStep(page: Page, movingDirection: any) {
    if (!page && this.wizardInstance && this.wizardInstance.currentStep) {
      const pageName = this.wizardInstance.currentStep.stepId;
      let page = this.pageMap.get(pageName);
    }

    if (page && page.onCompleteApi) {
      const payload = {
        'pageName': page.name,
        'event': 'ON_PAGE_EXIT'
      };

      const apiMetadata = new APIMetadata(this.form, this.selectedDataModelFieldMap, this.errorMap, this.warningMap,this.backendErrorMap);

      const subscription = this.apiService.triggerApiOrRouteOrMvel(page.onCompleteApi, payload, false, apiMetadata);

      if (subscription) {
        // this.loaderService.show();
        subscription.subscribe(
          result => {
            // this.loaderService.hide();

            if (result != null && result != undefined && result[APIService._IS_FORM_VALIDATION_NEEDED]) {
              this.isEntityFormValid.emit(this.checkFormisValid());
            }
          }
        );
      }
    }
  }

  navigateWizard(navigateBy: number) {
    if (this.wizardInstance) {
      if (!navigateBy) {
        navigateBy = 0;
      }
      
      const newIndex = this.wizardInstance.currentStepIndex + navigateBy;

      if (newIndex != this.wizardInstance.currentStepIndex && newIndex >= 0 && newIndex < this.selectedDataModel.pages.length) {
        this.wizardInstance.goToStep(newIndex);
        window.scroll(0, 0);
      }
    }
  }
}