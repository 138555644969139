import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { AbstractControlOptions, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { parse, stringify } from 'flatted';
import { IMyDpOptions } from 'mydatepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { CommonSearchModel } from 'src/app/models/shared.model';
import { APIMetadata, APIService } from 'src/app/services/api.service';
import { v4 as uuid } from 'uuid';
import { commonKeys } from '../../../models/constants';
import { DataModel, Entity, Field, LinkageModel, Page } from '../../../models/data-model.model';
import { State } from '../../../models/tasks.model';
import { DataModelService } from '../../../services/data-model.service';
import { EntityService } from '../../../services/entity.service';
import { ApiResponseQueue, CommunicationService, EntitySharingService, FieldOnChangeService, FileService, LoaderService,Status, SecuredStorageService, UserAccessControlStorageService, DataSharingService } from '../../../services/shared.service';
import { DynamicFormModalComponent } from '../../utility/dynamic-form-modal/dynamic-form-modal.component';
import { EntityRefModalComponent } from '../../utility/entity-ref-modal/entity-ref-modal.component';
import { ImagePdfViewerComponent } from '../../utility/image-pdf-viewer/image-pdf-viewer.component';
import { AccessControlResponse } from 'src/app/models/accessControleResponse.model';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {

  // constructor() { }

  // ngOnInit() {
  /**
   * field.mask -> check and assign default value ('') if null or undefined
   * field.placeholder -> check and assign default value (placeHolderText) if null or undefined
   * 
   * use formControl to enable/disable field instead of using HTML attribute
   * [(ngModel)]="field.value" should be remove since we're using formControl
   * 
   * Entity Reference - Dropdown to select entity. On eye click (view selected entity) show details of the entity in view mode in a pop up
   * Nested Modal - Introduce a collapseable div containing entity form
   * Nested Modal List - Introduce a collapseable div containing entity form using a carousel or a tab view
   */
  // }

  @Input() model;
  @Input() viewEntity;
  @Input() form: FormGroup;
  @Input() manualStateEntityAction;
  @Input() state: State;
  @Input() parentClass;
  @Input() innerForm;
  @Input() addNew:boolean = false;
  @Input() taskSelectedDataModel: Subject<DataModel> = new Subject<DataModel>();
  @Output() emitted: EventEmitter<any> = new EventEmitter();
  @Output() finalEmit: EventEmitter<any> = new EventEmitter();
  @Output() emittedinner: EventEmitter<any> = new EventEmitter();
  @Output() isEntityFormValid: EventEmitter<boolean> = new EventEmitter();

  @Output()
  protected onNestedEntitySave: EventEmitter<any> = new EventEmitter();

  protected pageMap: Map<string, Page> = new Map<string, Page>();

  protected readonly formMode: string = 'Default';

  protected subscription: Subscription;
  renderui:string='true';
  // dataModelList: DataModel[];
  fieldDatamodel: DataModel;
  selectedDataModel: DataModel;
  selectedDataModelFieldMap: Map<string, Field> = new Map();
  // form: FormGroup;
  boolean: any[];
  visibleFieldsMap: any = {};
  renderedFieldsMap: any = {};
  checkFieldsAreRenderedAttempt: number = 0;
  showFieldsMap: any = {};
  placeHolderText: string;
  DataModelfields: any = {};
  showSave: boolean = true;
  showSubmit: boolean = true;
  lookupEntityList: any[];
  lookupRefEntityList: any[];
  entityModels: any[];
  bsModalRef: BsModalRef;
  modalOpen: boolean = false;
  entityRefField: Field = new Field();
  dialogRef: MatDialogRef<DynamicFormModalComponent>;
  entityRefs: any[];
  p: number = 1;

  listCallInProcess: boolean = false;
  mvelPayloadMap: any = {};
  backendErrorMap : any = {};
  errorMap: any = {};
  warningMap: any = {};
  highlightErrors: boolean = false;

  viewingNestedEntity: boolean = false;

  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentPrevDate: any = this.mytime.getUTCDate() + 1;
  currentFutDate: any = this.mytime.getUTCDate() - 1;
  currentMonth: any = this.mytime.getUTCMonth() + 1; //months from 1-12
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd-mm-yyyy'
  };
  public myPreviousDatePickerOptions: IMyDpOptions = {
    disableSince: { year: this.currentYear, month: this.currentMonth, day: this.currentPrevDate },
    dateFormat: 'dd-mm-yyyy'
  };
  public myFutureDatePickerOptions: IMyDpOptions = {
    disableUntil: { year: this.currentYear, month: this.currentMonth, day: this.currentFutDate },
    dateFormat: 'dd-mm-yyyy'
  };

  renderLookup: boolean = false;
  parentDataModelName: string = "";
  childDataModelName: string = "";
  stateInstanceId: string = null;

  selectedDocumentFieldToBeDelete: Field = null;
  selectedDocumentFieldToBeDeleteIndex: number;

  bsModelDelete: BsModalRef;
  uiRendered: boolean = false;
  validationErrorList:any[] = [];
  validationErrorTypes:any[] =[];
  errPanelName='ERROR';
  errorsByType:any[] =[];
  linkageModelList: LinkageModel[];

  // Deliberately written 'String' & not 'string' since field.type has datatype 'String'
  delayedOnChangeFieldTypes: String[] = [
    'TEXT',
    'TEXTAREA',
    'PASSWORD',
    'SECURED',
    'INT',
    'LONG',
    'FLOAT'
  ];

  delayedFieldsTimerMap: any = {};

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    protected cd: ChangeDetectorRef,
    protected modalService: BsModalService,
    protected communicationService: CommunicationService,
    protected dataModelService: DataModelService,
    protected entityService: EntityService,
    protected fileService: FileService,
    protected entitySharingService: EntitySharingService,
    protected apiResponseQueue: ApiResponseQueue,
    protected toastrService: ToastrService,
    protected apiService: APIService,
    protected fieldOnChangeService: FieldOnChangeService,
    protected loaderService: LoaderService,
    protected domSanitizer: DomSanitizer,
    public dialog: MatDialog,
    protected userAccessControlStorageService: UserAccessControlStorageService,
    protected storageService: SecuredStorageService,
    protected dataSharingService:DataSharingService
  ) {
    this.placeHolderText = commonKeys.entityDefaultPlaceHolder;
    this.lookupEntityList = [];
    this.lookupRefEntityList = [];
    this.linkageModelList = [];
    this.entityModels = [];
    this.boolean = [true, false];
    this.selectedDataModel = new DataModel();
    this.form = new FormGroup({});
    //this.dialogRef = new MatDialogRef(null,null);
  }

  ngOnInit() {

  }

  onFieldRendered(field: Field) {
    if (field) {
      this.renderedFieldsMap[field.name] = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.model) {
      if (this.model.viewEntity == undefined || !this.model.viewEntity) {
        if (this.model._id) {
          this.getDataModelById(this.model._id);
        } else if (this.model.modelName) {
          this.getDataModelByName(this.model.modelName);
        }
      } else {
        if (this.model.type == "MODEL" && !this.model.list) {
          if (this.model.value) {
            this.selectedDataModel = this.model.value;
          }
        }
        else {
          this.selectedDataModel = this.model;
        }
        this.createForm();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  createForm() {
    let _this = this;
    for (let field of this.selectedDataModel.fields) {
      this.selectedDataModelFieldMap.set(field.name, field);
    }
    for (let field of this.selectedDataModel.fields) {
      if (field.type == commonKeys.entityLookup) {
        this.getLookupEntitiesList(field);
      }
      else if (field.type == commonKeys.entityLookupReference) {
        this.getRefLookupEntitiesList(field);
      }
    }
    //this.selectedDataModel.fields = this.selectedDataModel.fields.filter(field => !field.hide);
    this.selectedDataModel.fields.forEach((field, index) => {
      if (field.dependencyExpression) {
        this.showFieldsMap[field.name + "*" + field.sortOrder] = false;
      }
      else {
        this.showFieldsMap[field.name] = true;
      }
    });
    this.selectedDataModel.groups.forEach((group,index)=>{
      group.uiProperties.iscollapsed = false;
    });
    this.selectedDataModel.fields.sort(function (a: Field, b: Field) {
      if (_this.selectedDataModel.groups && _this.selectedDataModel.groups.length>0) {
        if (a.groupName === b.groupName) {
          // Price is only important when cities are the same
          return a.sortOrder - b.sortOrder;
       }
       return a.groupName > b.groupName ? 1 : -1;
      }else{ 
        return a.sortOrder > b.sortOrder ? 1 : a.sortOrder ? -1 : 0
      }
    });
    this.emitted.emit(this.selectedDataModel);
    this.form = this.toFormGroup(this.selectedDataModel.fields);

    this.selectedDataModel.fields.forEach((field) => {
      if (this.conditionForField(field)) {
        this.visibleFieldsMap[field.name] = true;
      }
    });

    this.onChanges();

    setTimeout(() => {  // To avoid first on change when the form is created
      this.runAllDependencyExpressions();
      this.onFieldChange();
      this.checkIfAllFieldsAreRendered();
    }, 10);

    this.fetchLinkageModels();
  }

  checkIfAllFieldsAreRendered() {
    if (this.checkFieldsAreRenderedAttempt >= 5 || this.uiRendered) {
      return;
    }

    this.checkFieldsAreRenderedAttempt++;

    if (this.renderedFieldsMap && this.visibleFieldsMap && this.renderedFieldsMap.length == this.visibleFieldsMap.length) {
      this.uiRendered = true;

      while (this.apiResponseQueue.hasMore()) {
        const apiResponse = this.apiResponseQueue.pop();
        const apiMetadata = new APIMetadata(this.form, this.selectedDataModelFieldMap, this.errorMap, this.warningMap,this.backendErrorMap);

        this.apiService.processResponse(apiResponse, apiMetadata);
      }
    } else {

      setTimeout(() => {
        this.checkIfAllFieldsAreRendered();
      }, 500);
    }
  }

  getDatePickerOptions(field: Field) {
    if (field.dateTypeName == 'PastAndCurrentDate') {
      return this.myPreviousDatePickerOptions;
    }
    else if (field.dateTypeName == 'FutureAndCurrentDate') {
      return this.myFutureDatePickerOptions;
    }
    else {
      return this.myDatePickerOptions;
    }
  }

  addModel(field) {
    let index;
    if (field.value && field.value.length > 0) {
      index = field.value.length;
    }
    else {
      field.value = [];
      index = 0;
    }
    field["currentIndex"] = index;
    this.dataModelService.getDataModelByName(field.modelName).subscribe(model => {
      field.value[index] = model;
      this.openEntityUpdateModal(field, index);
    });

  }

  onAddModel(field: Field) {
    this.dataModelService.getDataModelByName(field.modelName).subscribe(model => {
      const index = field["currentIndex"];

      field.value[index] = model;
      this.openEntityUpdateModal(field, index, true,true);
    });
  }

  removeModel(field, i) {
    if (field.value.length > 0) {
      field.value.splice(i, 1);
    }
  }

  getUniqueIndex(field) {
    if (field.currentIndex) {
      return field.currentIndex;
    }
    else {
      return 0;
    }

  }

  updateInnerModelList(field, event, index) {
    field.value[index] = event;
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      this.emitted.emit(this.selectedDataModel);
      for (let key of Object.keys(val)) {
        this.DataModelfields[key] = val[key];
      }
      this.isEntityFormValid.emit(this.checkFormisValid());
    });
  }

  onFieldChange() {
    if (this.selectedDataModel && this.selectedDataModel.fields) {
      for (const field of this.selectedDataModel.fields) {
        if (field && this.form && this.form.controls && this.form.controls[field.name]) {
          this.form.controls[field.name].valueChanges.subscribe(value => {
            if (field.value != value || (value && (field.type == 'FILE' || field.type == 'FILE_SECURED' || field.type == 'DATE' || field.type == 'DATETIME' || field.type == 'BUTTON'))) {
              let shouldRunMVEL = false;

              if (field.dependencyExpressionList) {
                for (const dependencyExp of field.dependencyExpressionList) {
                  if (dependencyExp && dependencyExp.expression && dependencyExp.triggerEvent && dependencyExp.triggerEvent == 'ON_CHANGE') {
                    shouldRunMVEL = true;
                    break;
                  }
                }
              }

              if (this.delayedOnChangeFieldTypes.includes(field.type)) {
                // Adding a delay of 650 ms to perform actions on keystrokes
                // If a new keystroke is received before finishing 650 ms, timer is reset
                // This is to avoid multiple API calls on every keystroke, but also to
                // make sure API is fired once user is done typing
                let delayedTimer = this.delayedFieldsTimerMap[field.name];

                if (delayedTimer != null && delayedTimer != undefined) {
                  clearTimeout(delayedTimer);
                }

                delayedTimer = setTimeout(() => {
                  this.performOnFieldChangeAction(field, shouldRunMVEL);
                }, 650);

                this.delayedFieldsTimerMap[field.name] = delayedTimer;
              } else {
                setTimeout(() => {
                  this.performOnFieldChangeAction(field, shouldRunMVEL);
                }, 10);
              }
            }
          });
        }
      }
    }
  }

  performOnFieldChangeAction(field: Field, shouldRunMVEL: boolean) {
    let payloadToEvaluateMVEL = this.entitySharingService.getPayloadToEvaluateMVEL(this.selectedDataModel, this.viewEntity, this.state);

    if (shouldRunMVEL) {
      payloadToEvaluateMVEL['onChange'] = true;
      payloadToEvaluateMVEL['fields'] = [field.name];
      this.getMVELResult(payloadToEvaluateMVEL, field);
    }

    if (field.onChangeApi) {
      delete payloadToEvaluateMVEL['onChange'];
      delete payloadToEvaluateMVEL['fields'];
      payloadToEvaluateMVEL['changedField'] = field.name;
      this.getOnChangeAPIResult(field, payloadToEvaluateMVEL);
    }
  }

  isErrorMapEmpty() {
    return !this.errorMap || Object.keys(this.errorMap).length == 0;
  }

  checkFormisValid() {
    if (!this.isErrorMapEmpty()) {
      return false;
    }

    let valid = [];
    if (this.selectedDataModel.fields) {
      for (let field of this.selectedDataModel.fields) {
        if (field.mandatory) {

          if (field.value !== null && field.value !== "" && field.value !== undefined) {
            valid.push("true");
          }
          else {
            if (field.dependencyExpression) { 
              if (!this.showFieldsMap[field.name + "*" + field.sortOrder]) {
                valid.push("true");
              }
              else {
                valid.push("false");
              }
            }
            else {
              valid.push("false");
            }
          }
        }
        else {
          valid.push("true");
        }
      }
      if (valid.includes("false")) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }

  }


  saveEntity(type: string) {
    if (!this.validateCurrentPage()) {
      this.toastrService.error('Please address the highlighted errors to ' + type + ' the record.');
      return;
    }

    if (this.checkFormisValid()) {
      let map = {};
      let validationArr = this.entitySharingService.checkValidation(this.selectedDataModel);
      if (validationArr.length == 0 && !validationArr.includes(false)) {
        map["type"] = type;
        map["model"] = this.selectedDataModel;
        map["entity"] = this.viewEntity;
        this.finalEmit.emit(map);
      }
    }
  }


  conditionForField(field: Field) {
    if (!field.hide && field.name != commonKeys.entityLookUpRefId) {
      if (field.dependencyExpression) {

        const exp = field.dependencyExpression.replace(/datamodel/g, commonKeys.dependencyExpressionReplacer);
        const result = this.evaluateExpression(exp);

        if (result) {
          this.showFieldsMap[field.name + "*" + field.sortOrder] = true;
          if (field["previousValue"] != null && field["previousValue"] != undefined) {
            field.value = field["previousValue"];
            field["previousValue"] = null;
          }
        }
        else {
          this.showFieldsMap[field.name + "*" + field.sortOrder] = false;
          if (field.value != null && field.value != undefined) {
            field["previousValue"] = field.value;
            field.value = null;
          }
        }
        return result;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }
  }

  evaluateExpression(expression: string) {
    if (expression && expression.trim().length > 0) {
      const result = eval(expression);
      return result;
    }
  }

  checkForRefrenceField(field: Field) {
    return field.disable || field.name == commonKeys.entityLookUpRefId;
  }

  toFormGroup(fields: Field[]) {
    const group: any = {};

    fields.forEach(field => {
      if (field.name != 'text') {
        let required = false;
        required = field.mandatory;
        for (const validator of field.validators) {
          if (validator.name === 'Required') {
            required = true;
          }
        }

        let fieldValue = field.value != null && field.value != undefined ? field.value : '';

        let formControlOptions: AbstractControlOptions = {
          'updateOn': 'change'
        };

        if (required) {
          formControlOptions['validators'] = [Validators.required];
        }

        group[field.name] = new FormControl(fieldValue, formControlOptions);
      }
    });

    return new FormGroup(group);
  }

  compareKeys(a, b) {
    var aKeys = Object.keys(a).sort();
    var bKeys = Object.keys(b).sort();
    return JSON.stringify(aKeys) === JSON.stringify(bKeys);
  }

  getDataModelById(dataModelId: string) {
    // Am adding this to save the API call that gets the data model twice
    this.showFieldsMap = {};
    this.visibleFieldsMap = {};
    this.subscription = this.dataModelService.getDataModel(dataModelId)
    .subscribe(
      dataModel => {
        this.addParentIdFieldIfApplicable(dataModel);
        this.createEntityModal(dataModel);
      });
    
  }

  getDataModelByName(dataModelName: string) {
    this.showFieldsMap = {};
    this.visibleFieldsMap = {};
   
    this.subscription = this.dataModelService.getDataModelByName(dataModelName)
    .subscribe(
      dataModel => {
        this.addParentIdFieldIfApplicable(dataModel);
        this.createEntityModal(dataModel);
      });
    
  }

  addParentIdFieldIfApplicable(dataModel: DataModel) {
    if (dataModel && !this.innerForm && dataModel.parentDataModelName) {
      const parentIdField = new Field();
      parentIdField.name = '_parentId';
      parentIdField.label = 'Link Parent';
      parentIdField.type = 'PARENT_ID_SELECTOR';
      parentIdField.sortOrder = -1;
      parentIdField.placeholder = 'Choose parent to link';

      dataModel.fields.unshift(parentIdField);
    }
  }

  getLinkageId() {
    if (!this.innerForm && this.viewEntity && this.viewEntity['_linkageId']) {
      return this.viewEntity['_linkageId'];
    }

    return null;
  }

  fetchLinkageModels() {
    if (this.viewEntity && this.viewEntity['_id'] && this.viewEntity['_id'].trim().length > 0) {
      this.dataModelService.getLinkageModelByParentId(this.viewEntity['_id']).subscribe(
        (linkageModelList: LinkageModel[]) => {
          if (linkageModelList && linkageModelList.length > 0) {
            for (const linkageModel of linkageModelList) {
              if (linkageModel && linkageModel.childMetadataList) {
                const tempChildMetadataList = [];

                for (const childMetadata of linkageModel.childMetadataList) {
                  if (childMetadata && childMetadata.entityId && childMetadata.statusCd
                      && !['DELETED', 'DELINKED'].includes(childMetadata.statusCd)) {
                    tempChildMetadataList.push(childMetadata);
                  }
                }

                if (tempChildMetadataList.length > 0) {
                  linkageModel.childMetadataList = tempChildMetadataList;
                  this.linkageModelList.push(linkageModel);
                }
              }
            }
          }
        }
      );
    }
  }

  createEntityModal(datamodel: DataModel) {
    if (datamodel) {
      if (this.viewEntity != null) {
        datamodel["parent"] = true;
        this.insertDataForView(datamodel);
      }
      else {
        this.selectedDataModel = datamodel;
        this.createForm();
      }
      // Applying Global CSS (if present), only in case it's Web UI
      if(this.storageService.getItem(SecuredStorageService._RENDER_UI)) {
        this.renderui = this.storageService.getItem(SecuredStorageService._RENDER_UI);
      }

      if (this.renderui && this.renderui  == 'true') {
        if (this.selectedDataModel && this.selectedDataModel.uiProperties && this.selectedDataModel.uiProperties.modelCss
            && this.selectedDataModel.uiProperties.modelCss.trim().length > 0) {
          const css = this.selectedDataModel.uiProperties.modelCss;
          const head = document.getElementsByTagName('head')[0];
          const style = document.createElement('style');
          style.type = 'text/css';
          style.appendChild(document.createTextNode(css));
          head.appendChild(style);
        }
      }
    }
  }

  insertDataForView(selectedDatamodel: any, viewEntity: any = null, refrenceField: boolean = false) {
    if (viewEntity == null) {
      viewEntity = this.viewEntity;
    }
    
    if (viewEntity && viewEntity._id) {
      this.entityService.getValidationForEntityId(viewEntity._id).subscribe(
        response=>{
          if(selectedDatamodel["parent"]){
            this.validationErrorList = (response);
            this.validationErrorTypes = Array.from(new Set(response.map(item=>item.type)));
            this.errorsByType = this.validationErrorList.filter(item=>item.type == 'ERROR');
          }
        },
        error=>{
          console.log(error)
        }
      );
    }
    selectedDatamodel.viewEntity = true;
    selectedDatamodel.fields.forEach((field, index) => {
      for (let key of Object.keys(viewEntity)) {
        if (field.name == key) {
          if (field.type == "DATE" || field.type == "DATETIME") {
            if (viewEntity[key]) {
              field.value = new Date(viewEntity[key]);
            }
          }
          else if (field.type == "MODEL" && field.list) {
            viewEntity[field.name].forEach((val, index) => {
              this.dataModelService.getDataModelByName(field.modelName).subscribe(model => {
                field["viewEntity"] = true;
                {
                  this.insertDataForView(model, val);
                  if (field.value == undefined) {
                    field.value = [];
                  }
                  field.value[index] = model;
                }
              });
            });
          }
          else if (field.type == "MODEL" && !field.list) {
            field["viewEntity"] = true;
            this.dataModelService.getDataModelByName(field.modelName).subscribe(model => {
              this.insertDataForView(model, viewEntity[key]);
              field.value = model;
            });

          }
          else if (field.type == "LOOKUP_REFERENCE" && field.list) {
            if (viewEntity[field.name]) {
              this.dataModelService.getDataModelByName(field.referenceModel).subscribe(model => {
                field["viewEntity"] = true;
                if (Array.isArray(viewEntity[field.name])) {
                  viewEntity[field.name].forEach((val, index) => {
                    let modelCopy = parse(stringify(model));
                    this.entityService.getEntityLookupRef(field.referenceModel, val).subscribe(entity => {
                      this.insertDataForView(modelCopy, entity);
                      if (field.listvalue == undefined) {
                        field.listvalue = [];
                      }
                      if (field.value == undefined) {
                        field.value = [];
                      }
                      let map = {};
                      map["id"] = entity[commonKeys.entityLookUpRefId];
                      map["name"] = entity[commonKeys.entityLookUpRefLabel];
                      field.value.push(map);
                      modelCopy["lookupEntityRefMap"] = map;
                      modelCopy[commonKeys.entityLookUpRefLabel] = entity[commonKeys.entityLookUpRefLabel];
                      field.listvalue[index] = modelCopy;
                      this.createForm();
                    });
                  });
                }
              });

            }
          }
          else if (field.type == "LOOKUP_REFERENCE" && !field.list) {
            field["viewEntity"] = true;
            field.value = viewEntity[key];
            this.dataModelService.getDataModelByName(field.referenceModel).subscribe(model => {
              let _lookupEntityRefId = null;

              if (viewEntity != null && viewEntity[key] != null && typeof viewEntity[key] === 'string') {
                _lookupEntityRefId = viewEntity[key];
              } else if (viewEntity != null && viewEntity[key] != null && typeof viewEntity[key] === 'object' && viewEntity[key]["_lookup_reference_id"] != null) {
                _lookupEntityRefId = viewEntity[key]["_lookup_reference_id"];
              }

              this.entityService.getEntityLookupRef(field.referenceModel, _lookupEntityRefId).subscribe(entity => {
                this.insertDataForView(model, entity);
                model["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId];
                field["actualValue"] = entity[commonKeys.entityLookUpRefId];
                field.value = model;
              });
            });
          }
          else {
            field.value = viewEntity[key];
          }
          field.value = this.decodeEntities(field.value);
          selectedDatamodel.fields.splice(index, 1, field);
        }
      }
    });
    if (selectedDatamodel["parent"]) {
      this.selectedDataModel = selectedDatamodel;
      this.createForm();
    }
  }

  compareObjects(o1: any, o2: any) {
    if (o2 != undefined) {
      if (o1.id == o2.lookupEntityRefId || o1.id == o2.actualValue)
        return true;
      else return false
    }
  }

  compareObjectsList(o1: any, o2: any) {
    if (o2 != undefined) {
      return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }
  }

  checkForSubmit() {
    if (!this.checkForCreate() && !this.checkForUpdate()) {
      return false;
    }
    if (!this.manualStateEntityAction) {
      if (this.viewEntity && this.viewEntity._id) {
        if ([Status.DRAFT, Status.INPROGRESS].includes(this.viewEntity.statusCd) && this.selectedDataModel && this.selectedDataModel.process && this.selectedDataModel.process.toString().length > 0) {
          return true;
        }
        else {
          return this.uiRendered && false;
        }
      }
      else {
        if (this.selectedDataModel && this.selectedDataModel.process && this.selectedDataModel.process.toString().length > 0) {
          return this.uiRendered && true;
        }
        else {
          return false;
        }
      }
    }
    else {
      return false;
    }

  }

  checkForCreate() {
    if (this.viewEntity == null || !this.viewEntity._id) {
      if (this.selectedDataModel && this.selectedDataModel.subEntity) {
        return true;
      }
      if (!this.userAccessControlStorageService.getAccessControl()) {
        return true;
      }
      if (this.isAllowToCreate(this.userAccessControlStorageService.getAccessControl()) && this.checkForSave()) {
        return true;          
      }          
    }
    return false;
  }

  checkForUpdate() {
    if (this.selectedDataModel && this.selectedDataModel.subEntity) {
      return true;
    }
    if (this.viewEntity && this.viewEntity._id) {
      if (!this.userAccessControlStorageService.getAccessControl()) {
        return true;
      }
      if (this.isAllowToUpdate(this.userAccessControlStorageService.getAccessControl()) && this.checkForSave()) {
        return true;          
      }
    }
    return false;
  }

  checkForSave() {
    if (!this.manualStateEntityAction) {
      if (this.viewEntity && this.viewEntity._id) {
        if ([Status.DRAFT, Status.INPROGRESS].includes(this.viewEntity.statusCd)) {
          return this.uiRendered && true;
        }
        else {
          return false;
        }
      }
      else {
        return this.uiRendered && true;
      }
    }
    else {
      return false
    }


  }

  checForNavigateToTask() {
    if (this.viewEntity != undefined && this.viewEntity["stateInstanceId"] != null && this.viewEntity["stateInstanceId"] != undefined) {
      this.stateInstanceId = this.viewEntity["stateInstanceId"];
      return true;
    }
    else {
      return false;
    }
  }


  getLookupEntitiesList(field: Field) {
    const commonSearchModel = new CommonSearchModel();
    commonSearchModel.searchParams = [{}];
    commonSearchModel.returnFields = [];

    if (!field.parentAttributeCode || field.parentAttributeCode.trim().length == 0) {
      if (field.lookupType) {
        commonSearchModel.searchParams = [{
          lookupType: field.lookupType
        }];
      }
      this.subscription = this.entityService.getLookupEntities(field.referenceModel, commonSearchModel)
        .subscribe(lookupList => {
          for (let lookupEntity of lookupList) {
            this.lookupEntityList.push(lookupEntity);
          }
          field.lookupEntityList = lookupList;
        });
    } else {
      const parentField = this.selectedDataModelFieldMap.get(field.parentAttributeCode);

      if (parentField && parentField.value) {
        let tempSearchParam = {};
        tempSearchParam["parentValueCode"] = parentField.value;
        if (field.lookupType) {
          tempSearchParam["lookupType"] = field.lookupType;
        }
        commonSearchModel.searchParams = [tempSearchParam];

        this.subscription = this.entityService.getLookupEntities(field.referenceModel, commonSearchModel)
          .subscribe(lookupList => {
            for (let lookupEntity of lookupList) {
              this.lookupEntityList.push(lookupEntity);
            }
            field.lookupEntityList = lookupList;
          });
      }
    }
  }

  onLookupSelect(changedField: Field) {
    let childField: Field = null;

    for (let field of this.selectedDataModel.fields) {
      if (field.parentAttributeCode && field.parentAttributeCode == changedField.name) {
        childField = field;
        childField.value = "";
        childField.lookupEntityList = [];
        this.onLookupSelect(field);
        break;
      }
    }

    if (changedField && changedField.value && childField) {
      const commonSearchModel = new CommonSearchModel();
      let tempSearchParam = {};
      tempSearchParam["parentValueCode"] = changedField.value;
      if (childField.lookupType) {
        tempSearchParam["lookupType"] = childField.lookupType;
      }
      commonSearchModel.searchParams = [tempSearchParam];
      commonSearchModel.returnFields = [];
      this.subscription = this.entityService.getLookupEntities(childField.referenceModel, commonSearchModel)
        .subscribe(lookupList => {
          for (let lookupEntity of lookupList) {
            this.lookupEntityList.push(lookupEntity);
          }
          childField.lookupEntityList = lookupList;
        });
    }
  }

  getRefLookupEntitiesList(field: Field) {
    const commonSearchModel = new CommonSearchModel();
    commonSearchModel.searchParams = [{}];
    commonSearchModel.returnFields = [commonKeys.entityLookUpRefLabel, commonKeys.entityLookUpRefId];
    this.subscription = this.entityService.getRefLookupEntities(field.referenceModel, commonSearchModel)
      .subscribe(lookupList => {
        let lookupRefEntityList = [];
        for (let lookupEntity of lookupList) {
          if (lookupEntity[commonKeys.entityLookUpRefId] != null && lookupEntity[commonKeys.entityLookUpRefId] != undefined) {
            let map = {};
            map["id"] = lookupEntity[commonKeys.entityLookUpRefId];
            map["name"] = lookupEntity[commonKeys.entityLookUpRefLabel];
            lookupRefEntityList.push(map);
          }
        }
        field.lookupEntityList = lookupRefEntityList;
      });
  }

  isValid(field: Field) {
    let required = false;
    for (const validator of field.validators) {
      if (validator.name === 'Required') {
        required = true;
      }
    }
    if (field.mandatory || required) {
      if (field.value != null && field.value != "" && field.value != undefined) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }




  onDocumentUpload(event, field: Field) {
    for (let index = 0; index < event.target.files.length; index++) {
      let uploadedFile = event.target.files[index];
      this.form.controls[field.name].setValue(uploadedFile ? uploadedFile.name : '');

      const fileInputForm = new FormData();
      const file: File = event.target.files[index];
      fileInputForm.append('file', file, file.name);
      var uploadFileName = uuid();
      fileInputForm.append("fileName", file.name);
      fileInputForm.append("functionInstanceName", "Activity");
      fileInputForm.append("entityType", "ACTIVITY_" + this.selectedDataModel.name);
      fileInputForm.append("entityRef", this.selectedDataModel._id);
      this.uploadFile(fileInputForm, field);
      //event.target.value = '';
    }

  }

  uploadFile(formData: FormData, field: Field) {
    this.fileService.upload(formData)
      .subscribe(
        response => {
          if (response && response["url"] && response["fileName"]) {
            if (field.list == true) {
              if (field.value == undefined) {
                field.value = [];
              }
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              doc.interfaceUrl = response["interfaceUrl"];
              if (response["publicURL"]) {
                doc.publicURL = response["publicURL"];
              }
              field.value.push(doc);
            } else {
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              doc.interfaceUrl = response["interfaceUrl"];
              if (response["publicURL"]) {
                doc.publicURL = response["publicURL"];
              }
              field.value = doc;
            }
            this.isEntityFormValid.emit(this.checkFormisValid());
          }
        });
  }

  onFileUpload() {
    this.isEntityFormValid.emit(this.checkFormisValid());
  }

  onFileRemoved() {
    this.runAllDependencyExpressions();
  }

  viewFile(fieldValue) {
    this.fileService.download(fieldValue.downloadFileUrl, true).subscribe(
      objectUrl => {
        if (objectUrl) {
          var type = (objectUrl.split(';')[0]).replace("data:", "");
          fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              // const file = new File([blob], this.doc.fileName,{ type: type })
              // return (file);
            })
          // this.downloadFileObjectUrl = objectUrl;
        }
      }
    );
  }
  openModalWithComponent(field: Field, index?: number) {
    if (index != undefined) {
      const initialState = {
        doc: this.viewFile(field.value[index]),
        title: 'Document Title'
      };
      this.bsModalRef = this.modalService.show(ImagePdfViewerComponent, { initialState });
      this.bsModalRef.content.closeBtnName = 'Close';
    } else {
      const initialState = {
        doc: this.viewFile(field.value),
        title: 'Document Title'
      };
      this.bsModalRef = this.modalService.show(ImagePdfViewerComponent, { initialState });
      this.bsModalRef.content.closeBtnName = 'Close';
    }
  }


  openEntityUpdateModal(item, index?, viewingNestedEntity?,addNew?): void {
    const onSaveNestedEntity = new Subject<any>();

    if (viewingNestedEntity == undefined) {
      this.viewingNestedEntity = false;
    }
    else {
      this.viewingNestedEntity = viewingNestedEntity;
    }

    let viewEntity = null;
    if (index != null && index != undefined && item['list'] && item['value'] && index < (item['value'].length)) {
      viewEntity = this.viewEntity && this.viewEntity[item['name']] && this.viewEntity[item['name']][index] ? this.viewEntity[item['name']][index] : null;
    } else {
      viewEntity = this.viewEntity[item['name']];
    }

    item["listvalue"] = item.value;
    const initialState = {
      entityItem: item,
      title: item.label,
      index: index,
      event: item.value,
      modalId: new Date().getTime(),
      viewingNestedEntity: this.viewingNestedEntity,
      formMode: this.formMode,
      viewEntity: viewEntity,
      onSaveNestedEntity: onSaveNestedEntity
    };
    if(addNew){
      this.addNew = true;
      initialState['addNew']=true;
    }else{
      this.addNew = false
    }
    this.dialogRef = this.dialog.open(DynamicFormModalComponent, {
      data: initialState
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.viewingNestedEntity = false;
      if (result.data) {
        if (!result.data["list"]) {
          this.updateInnerModel(result.data["field"], result.data["event"]);
          this.cd.detectChanges();
        }
        else {
          this.updateInnerModelList(result.data["field"], result.data["event"], result.data["index"])
          this.cd.detectChanges();
        }
        this.emitted.emit(this.selectedDataModel);
      }else{
        if(this.addNew && result.event == 'closeFromTop'){
          this.addNew = false;
          item.value.splice(index,1)
        }else if(this.addNew){
          this.addNew = false;
        }
      }
    });

    onSaveNestedEntity.subscribe(
      payload => {
        if (payload && payload['viewEntity'] && payload['field']) {
          const viewEntity: Entity = payload['viewEntity'];
          const field: Field = payload['field'];

          this.viewEntity[field.name] = viewEntity;
        }
        this.saveViewModeProperties();
      }
    );
  }

  openDialogEntityRef(field: Field): void {
    const initialState = {
      'field': field,
      'formMode': this.formMode
    }
    const dialogRef = this.dialog.open(EntityRefModalComponent, {
      width: '1000px',
      data: initialState
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  closeModal(modalId?: number) {
    this.modalService.hide(modalId);
  }

  onDocumentDeleteCancel() {
    this.selectedDocumentFieldToBeDelete = null;
    this.bsModelDelete.hide();
  }

  onDocumentDeleteConfrimation() {

    const field = this.selectedDocumentFieldToBeDelete;
    var docValue = field.value;

    if (field.list) {
      docValue = docValue[0];
    }
    this.fileService.delete(docValue.url)
      .subscribe(
        response => {
          if (this.selectedDocumentFieldToBeDeleteIndex != undefined) {
            let index = this.selectedDataModel.fields.indexOf(field)
            if (index != -1) {
              this.selectedDataModel.fields[index].value.splice(this.selectedDocumentFieldToBeDeleteIndex, 1);
            }
          } else {
            let index = this.selectedDataModel.fields.indexOf(field)
            if (index != -1) {
              this.selectedDataModel.fields[index].value = undefined;
            }
          }
          this.form = this.toFormGroup(this.selectedDataModel.fields);

          this.onChanges();

          setTimeout(() => {  // To avoid first on change when the form is created
            this.runAllDependencyExpressions();
          }, 10);
          this.bsModelDelete.hide();

        },
        error => {

        }
      )

  }

  onRemoveDocument(templateRef: TemplateRef<any>, field: Field, ind?: number) {
    this.selectedDocumentFieldToBeDelete = field;
    this.selectedDocumentFieldToBeDeleteIndex = ind;
    this.bsModelDelete = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  getFileName(field: Field) {
    return field.value.fileName;
  }

  updateInnerModel(field, event) {

    field.value = event;
    this.emitted.emit(this.selectedDataModel);
  }

  onBooleanChange(event, field?: Field) {

  }

  //Autocomplete

  selectEvent(item, field) {
    // do something with selected item
    field.value = item;
    this.emitted.emit(this.selectedDataModel)
  }

  onSelectChange(item, field) {
    if (!field.list && field["entityRefValue"] != item.source.value.name) {
      field["actualValue"] = item.source.value.id;
      field["viewEntity"] = true;
      field["entityRefValue"] = item.source.value.name;
      this.dataModelService.getDataModelByName(field.referenceModel).subscribe(model => {
        this.entityService.getEntityLookupRef(field.referenceModel, field["actualValue"]).subscribe(entity => {
          this.insertDataForView(model, entity);
          model["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId]
          field.value = model;
        });
      });
    }
  }

  onSelectChangeList(item, field: any) {
    field["actualValue"] = [];
    field.listvalue = [];
    this.dataModelService.getDataModelByName(field.referenceModel).subscribe(model => {
      field["viewEntity"] = true;
      field.value.forEach((val, index) => {
        let modelCopy = parse(stringify(model));
        this.entityService.getEntityLookupRef(field.referenceModel, val.id).subscribe(entity => {
          this.insertDataForView(modelCopy, entity);
          field["actualValue"].push(val.id);
          modelCopy[commonKeys.entityLookUpRefLabel] = entity[commonKeys.entityLookUpRefLabel];
          modelCopy["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId];
          field.listvalue[index] = modelCopy;
        });
      });
    });
  }

  onChangeSearch(event) {
    // fetch remote data from here

    //this.emitted.emit(this.selectedDataModel);
  }

  onFocused(e) {
    // do something when input is focused
  }

  onDateChanged(event) {
    if (event) {
      // this.selectedAgent.uiComponent.startTime = event["jsdate"];
    }
  }

  onTimeChanged(event, field) {
    if (field.value && field.value['date']) {
      let temp = field.value['date']
      let currentTimeRef = new Date(event)
      temp['hour'] = currentTimeRef.getHours();
      temp['min'] = currentTimeRef.getMinutes();
      // this.selectedDataModel.fields.forEach((oldField, index) => {
      //   if (oldField.name == field.name) {
      //     oldField.value = "";
      //     oldField.value = temp;
      //   }
      // });
    }
  }

  closeDialogSave(field?: any) {
    if (!this.validateCurrentPage()) {
      this.toastrService.error('Please address the highlighted errors to save the record.');
      return;
    }

    if (this.checkFormisValid()) {
      let validationArr = this.entitySharingService.checkValidation(this.selectedDataModel);
      if (validationArr.length == 0 && !validationArr.includes(false)) {
        this.selectedDataModel["dialogRef"].close({ event: 'closeonsave', data: this.selectedDataModel["map"] });
      }
    }
  }

  closeDialog(field?: any) {
    this.selectedDataModel["map"] = {};
    this.selectedDataModel["dialogRef"].close({ event: 'closewithoutsave', data: false });
  }

  runAllDependencyExpressions() {
    let payloadToEvaluateMVEL = this.entitySharingService.getPayloadToEvaluateMVEL(this.selectedDataModel, this.viewEntity, this.state);
    payloadToEvaluateMVEL['onChange'] = false;

    if (this.selectedDataModel && this.selectedDataModel.fields) {
      for (const field of this.selectedDataModel.fields) {
        if (field && field.dependencyExpressionList && field.dependencyExpressionList.length > 0) {
          if (!payloadToEvaluateMVEL['fields']) {
            payloadToEvaluateMVEL['fields'] = [];
          }

          payloadToEvaluateMVEL['fields'].push(field.name);
        }
      }
    }

    if (payloadToEvaluateMVEL && payloadToEvaluateMVEL['fields'] && payloadToEvaluateMVEL['fields'].length > 0) {
      this.getMVELResult(payloadToEvaluateMVEL);
    }
  }

  getOnChangeAPIResult(field: Field, payload: any) {
    const apiMetadata = new APIMetadata(this.form, this.selectedDataModelFieldMap, this.errorMap, this.warningMap,this.backendErrorMap);

    const subscription = this.apiService.triggerApiOrRouteOrMvel(field.onChangeApi, payload, false, apiMetadata);

    if (subscription) {
      if (field.uiProperties && field.uiProperties.onChangeApiShowLoader) {
        this.loaderService.show(field.uiProperties.onChangeApiLoaderMessage);
      }
      subscription.subscribe(
        result => {
          if (field.uiProperties && field.uiProperties.onChangeApiShowLoader) {
            this.loaderService.hide();
          }

          if (result != null && result != undefined && result[APIService._IS_FORM_VALIDATION_NEEDED]) {
            this.isEntityFormValid.emit(this.checkFormisValid());
          }
        },
        error => {
          if (field.uiProperties && field.uiProperties.onChangeApiShowLoader) {
            this.loaderService.hide();
          }
        }
      );
    }
  }

  getMVELResult(payloadToEvaluaetMVEL, field?: Field) {
    const apiMetadata = new APIMetadata(this.form, this.selectedDataModelFieldMap, this.errorMap, this.warningMap,this.backendErrorMap);

    const subscription = this.apiService.triggerApiOrRouteOrMvel(this.selectedDataModel.name, payloadToEvaluaetMVEL, true, apiMetadata);

    if (subscription) {
      if (!field) {
        this.loaderService.show();
      } else if (field && field.uiProperties && field.uiProperties.onChangeApiShowLoader) {
        this.loaderService.show(field.uiProperties.onChangeApiLoaderMessage);
      }

      subscription.subscribe(
        result => {
          if (!field || field.uiProperties && field.uiProperties.onChangeApiShowLoader) {
            this.loaderService.hide();
          }

          if (result != null && result != undefined && result[APIService._IS_FORM_VALIDATION_NEEDED]) {
            this.isEntityFormValid.emit(this.checkFormisValid());
          }
        },
        error => {
          if (!field || field.uiProperties && field.uiProperties.onChangeApiShowLoader) {
            this.loaderService.hide();
          }
        }
      );
    }
  }

  removeSelectValue(field: Field) {
    field.value = null;
    this.onLookupSelect(field);
  }


  navigateToTask() {
    this.router.navigate(['/task', this.stateInstanceId]);
  }

  downloadFile(field: Field) {
    if (field.value) {
      var docValue = field.value;
      if (field.list && field.value.length > 0) {
        docValue = field.value
      }
      if (docValue.downloadFileUrl.startsWith("http")) {
        window.open(docValue.downloadFileUrl, "_blank");
        return;
      }
      this.fileService.download(docValue.downloadFileUrl);

    }

  }

  getFieldClass(field: Field, defaultClass?: string) {
    if (field && field.uiProperties && field.uiProperties['className']) {
      return field.uiProperties['className'];
    }

    if (defaultClass) {
      return defaultClass;
    }

    return '';
  }

  getPageClass(page: Page, defaultClass?: string) {
    if (page && page.uiProperties && page.uiProperties['className']) {
      return page.uiProperties['className'];
    }

    if (defaultClass) {
      return defaultClass;
    }

    return '';
  }

  saveViewModeProperties(data?: any) {
    if (data) {
      this.updateEntityViewModeProperties(data);
    }

    if (this.innerForm) {
      const payload = {
        'viewEntity': data['parentViewEntity']
      }
      this.onNestedEntitySave.emit(payload);
    } else {
      const tempDataModel = new DataModel();

      tempDataModel.fields = this.selectedDataModel.fields;
      tempDataModel.label = this.selectedDataModel.label;
      tempDataModel.name = this.selectedDataModel.name;
      tempDataModel.version = this.selectedDataModel.version;
      tempDataModel.process = this.selectedDataModel.process;

      const mvelPayloadMap = this.entitySharingService.getPayloadToEvaluateMVEL(tempDataModel, this.viewEntity, this.state);
      if (mvelPayloadMap && mvelPayloadMap.payload) {
        const entityMap = mvelPayloadMap.payload;

        if(entityMap.statusCd != Status.DRAFT && entityMap.statusCd != Status.ACTIVE){
          entityMap.statusCd = Status.INPROGRESS;
        }

        this.entityService.saveEntity(tempDataModel, entityMap).subscribe(
          viewEntity => {
            if (viewEntity) {
              this.viewEntity = viewEntity;
            }
          }
        );
      } else {
        console.error("Failed to generate payload to save entity's view mode properties.");
      }
    }
  }

  updateEntityViewModeProperties(data: any) {
    if (data) {
      if (data['currentPage'] != null && data['currentPage'] != undefined) {
        const currentPage = (<Page>data['currentPage']);

        if (this.selectedDataModel.retainPosition) {
          this.viewEntity['lastKnownPage'] = currentPage.name;
        }

        if (this.selectedDataModel.trackProgress) {
          if (this.viewEntity['pageProgress'] != null && this.viewEntity['pageProgress'] != undefined) {
            const pageProgress = this.viewEntity['pageProgress'];

            if (this.pageMap.get(pageProgress) && currentPage.sortOrder > this.pageMap.get(pageProgress).sortOrder) {
              this.viewEntity['pageProgress'] = currentPage.name;
            }
          } else {
            this.viewEntity['pageProgress'] = currentPage.name;
          }

          if (data['pageFinished']) {
            this.viewEntity['pageFinished'] = true;
          }
        }
      }
    }
  }

  validateCurrentPage(wizardInstance?: WizardComponent,checkBackendError?:boolean): boolean {
    
    for (const field of this.selectedDataModel.fields) {
      let currentPage = null;
      if (wizardInstance && wizardInstance.currentStep && wizardInstance.currentStep.stepId) {
        currentPage = this.pageMap.get(wizardInstance.currentStep.stepId);
        if (!checkBackendError && this.backendErrorMap){
          for (const backEndField of Object.keys(this.backendErrorMap)){
            if (field.pageName == currentPage.name && field.name == backEndField){
              delete this.errorMap[backEndField];
            }
            }
          }
      }
      if (field && field.mandatory && (!currentPage || (currentPage && field.pageName && field.pageName == currentPage.name))) {
        if (field.type != 'BUTTON' && field.value === null || field.value === "" || field.value === undefined) {
          this.highlightErrors = true;
          return false;
        } else if (field.type == 'BUTTON' && (!field.value || Object.keys(field.value).length == 0 || !field.value['_status'])) {
          this.highlightErrors = true;
          return false;
        } else {
          if (field.validationExpression && field.validationExpression.length > 0 && (field.value instanceof String || typeof field.value === 'string')) {
            const matchValues = field.value.match(field.validationExpression);

            if (!matchValues || matchValues.length == 0) {
              this.highlightErrors = true;
              return false;
            }
          }
        }
      }
      if (field && this.errorMap && this.errorMap[field.name] && (!currentPage || (currentPage && field.pageName && field.pageName == currentPage.name))) {
        return false;
      }
    }

    this.highlightErrors = false;
    return true;
  }

  getSanitisedHtml(html: string) {
    if(html){
      var re = /{{{(.*?)}}}/;
      let splitString = html.split(re);
      if (splitString.length == 1 ) {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
      }else {
        let outputHtml ="";
        for (let index = 0; index < splitString.length; index++) {
          const element = splitString[index];
          
          if (element.indexOf('routeVar::') > -1) {
            let varPath = element.replace("routeVar::","");
            if(this.dataSharingService.transientContext){
            outputHtml += this.dataSharingService.transientContext[varPath];
          }
          }else{
            outputHtml += element;
          }
        }
        return this.domSanitizer.bypassSecurityTrustHtml(outputHtml);
      }
    }
  }

  onButtonClick(field: Field) {
    if (field && field.onClickApi) {
      const apiMetadata = new APIMetadata(this.form, this.selectedDataModelFieldMap, this.errorMap, this.warningMap,this.backendErrorMap);

      const payload = this.entitySharingService.getPayloadToEvaluateMVEL(this.selectedDataModel, this.viewEntity, this.state);

      const subscription = this.apiService.triggerApiOrRouteOrMvel(field.onClickApi, payload, false, apiMetadata);

      if (subscription) {
        const customLoaderMessage = field.uiProperties && field.uiProperties.onClickApiLoaderMessage ? field.uiProperties.onClickApiLoaderMessage : null;

        this.loaderService.show(customLoaderMessage);
        subscription.subscribe(
          result => {
            this.loaderService.hide();

            const value = result && result['payload'] ? result && result['payload'] : null;
            this.fieldOnChangeService.onChange(field.name, value, this.form);

            if (result != null && result != undefined && result[APIService._IS_FORM_VALIDATION_NEEDED]) {
              this.isEntityFormValid.emit(this.checkFormisValid());
            }
          }
        );
      }
    }
  }

  getButtonLabel(buttonType: 'Create' | 'Save' | 'Submit' | 'Done' | 'Close'): string {
    if (buttonType && this.selectedDataModel && this.selectedDataModel.uiProperties && this.selectedDataModel.uiProperties[buttonType.toLocaleLowerCase() + 'ButtonLabel']) {
      return this.selectedDataModel.uiProperties[buttonType.toLocaleLowerCase() + 'ButtonLabel'];
    }

    return buttonType;
  }

  decodeEntities(encodedString) {
    if (encodedString && typeof encodedString == "string") {
      var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
      var translate = {
        "nbsp": " ",
        "amp": "&",
        "quot": "\"",
        "lt": "<",
        "gt": ">"
      };
      return encodedString.replace(translate_re, function (match, entity) {
        return translate[entity];
      }).replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
    }
    return encodedString;
  }

  isAllowToCreate(accessControleResponse: AccessControlResponse) {
    if (accessControleResponse.operationAccessList == null) {
        return true;
    }
    for(let operation of accessControleResponse.operationAccessList) {
        if (operation.operationName == "Create" && operation.enabled) {
            return true;                
        }            
    }
    return false;     
  }

  isAllowToUpdate(accessControleResponse: AccessControlResponse) {
      if (accessControleResponse.operationAccessList == null) {
          return true;
      }
      for(let operation of accessControleResponse.operationAccessList) {
          if (operation.operationName == "Update" && operation.enabled) {
              return true;                
          }            
      }
      return false;
  }

  isAllowToDelete(accessControleResponse: AccessControlResponse) {
      if (accessControleResponse.operationAccessList == null) {
          return true;
      }
      for(let operation of accessControleResponse.operationAccessList) {
          if (operation.operationName == "Delete" && operation.enabled) {
              return true;                
          }            
      }
      return false;
  }

  isAllowToView(accessControleResponse: AccessControlResponse) {
      if (accessControleResponse.operationAccessList == null) {
          return true;
      }
      for(let operation of accessControleResponse.operationAccessList) {
          if (operation.operationName == "Create" && operation.enabled) {
              return true;                
          }
          if (operation.operationName == "View" && operation.enabled) {
              return true;                
          }
      }
      return false; 
  }
  toggleCollapse(group){
    group.uiProperties.iscollapsed = (!group.uiProperties.iscollapsed);
  }
  areGroupFieldsPresentInPage(group,page){
    let fieldsWithGroupName = this.selectedDataModel.fields.filter(itm=>itm.groupName == group.name);
    if (fieldsWithGroupName.length>0) {
      let checkSameFieldsForPageNumber = fieldsWithGroupName.filter(item=>item.pageName == page.name);
      if(checkSameFieldsForPageNumber.length>0){
        return true;
      }else{
        return false;
      }
    }else{
      return false
    }
  }
  areFieldsPresentinGroup(group,fields){
    let fieldsInGroup = []
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];

      if(field.groupName == group.name){

        if(field.hide==false){
          fieldsInGroup.push(field)
        }

      }
    }
    return fieldsInGroup.length
  }
}
