import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiResponseQueue, DataSharingService, LoaderService, SecuredStorageService, UserAccessControlStorageService } from '../../../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { DataModel, Field, Group, Page } from 'src/app/models/data-model.model';
import { APIMetadata, APIService } from 'src/app/services/api.service';
import { DataModelService } from 'src/app/services/data-model.service';
import { EntityService } from 'src/app/services/entity.service';
import { CommunicationService, EntitySharingService, FieldOnChangeService, FileService } from 'src/app/services/shared.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-wizard-form',
  templateUrl: './wizard-form.component.html',
  styleUrls: ['./wizard-form.component.scss']
})
export class WizardFormComponent extends DynamicFormComponent implements AfterViewChecked {

  protected readonly formMode: string = 'Wizard Mode';

  @ViewChild('wizard', { static: false })
  public wizardInstance: WizardComponent;

  reInitializeWizard = false;

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    protected cd: ChangeDetectorRef,
    protected modalService: BsModalService,
    protected communicationService: CommunicationService,
    protected dataModelService: DataModelService,
    protected entityService: EntityService,
    protected fileService: FileService,
    protected entitySharingService: EntitySharingService,
    protected apiResponseQueue: ApiResponseQueue,
    protected toastrService: ToastrService,
    protected apiService: APIService,
    protected fieldOnChangeService: FieldOnChangeService,
    protected loaderService: LoaderService,
    protected domSanitizer: DomSanitizer,
    public dialog: MatDialog,
    protected userAccessControlStorageService: UserAccessControlStorageService,
    protected storageService: SecuredStorageService, 
    protected dataSharingService: DataSharingService
  ) {
    super(
      router, snackBar, cd, modalService, communicationService,
      dataModelService, entityService, fileService,
      entitySharingService, apiResponseQueue, toastrService, apiService,
      fieldOnChangeService, loaderService, domSanitizer, dialog, userAccessControlStorageService, storageService,dataSharingService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.selectedDataModel && this.selectedDataModel!= null) {
      this.createPageMap(this.model);
    }
  }

  ngAfterViewChecked(): void {
    if (!this.reInitializeWizard && this.wizardInstance && !this.wizardInstance.currentStep) {
      this.reInitializeWizard = true;

      setTimeout(() => {
        this.reInitializeWizard = false;
      }, 500);
    }
  }

  createPageMap(model: DataModel) {
    if (model && model.pages) {
      model.pages.sort((a: Page, b: Page) => {
        return a.sortOrder > b.sortOrder ? 1 : a.sortOrder ? -1 : 0
      });
      for (const page of model.pages) {
        if (page && page.name != null && page.name != undefined) {
          this.pageMap.set(page.name, page);
        }
      }
    }
  }

  arePagesPresent() {
    if (this.reInitializeWizard) {
      return false;
    }

    return this.selectedDataModel && this.selectedDataModel.pages && this.selectedDataModel.pages.length > 0;
  }

  isFieldInPage(page: Page, field: Field): boolean {
    if (page && field) {
      return field.pageName && page.name && field.pageName == page.name;
    }

    return false;
  }

  isFieldInGroup(group: Group, field: Field): boolean {
    if (group && field) {
      return field.groupName && group.name && field.groupName == group.name;
    }

    return false;
  }

  getDefaultStepIndex() {
    // Removing sort order and basing on last page itself
    let currEntity = this.apiService.getEntity();
    if(this.innerForm){
      return 0;
    }else{
      if (currEntity && this.selectedDataModel.retainPosition) {
        if (currEntity['lastKnownPage'] != null && currEntity['lastKnownPage'] != undefined) {
          const lastKnownPage = this.pageMap.get(currEntity['lastKnownPage']);
          let pos = this.selectedDataModel.pages.map(item=>item.name).indexOf(lastKnownPage.name);
          if(pos == -1){
            return 0;
          }else{
            return pos;
          }
          // if (lastKnownPage.sortOrder == this.selectedDataModel.pages.length) {
          //   return lastKnownPage.sortOrder - 1;
          // } else {
          //   return lastKnownPage.sortOrder;
          // }
  
        }
      }
    }
    return 0;
  }

  isCompleted(page: Page) {
    if (page && this.viewEntity && this.selectedDataModel.retainPosition) {
      if (this.viewEntity['lastKnownPage'] != null && this.viewEntity['lastKnownPage'] != undefined) {
        const lastKnownPage = this.pageMap.get(this.viewEntity['lastKnownPage']);
        return lastKnownPage.sortOrder > page.sortOrder;
      }
    }

    return false;
  }

  getCurrentWizardStep() {
    return this.wizardInstance && this.wizardInstance.currentStep ? this.wizardInstance.currentStep : '';
  }

  onEnterStep(page: Page, movingDirection: any) {
    if (!page && this.wizardInstance && this.wizardInstance.currentStep) {
      const pageName = this.wizardInstance.currentStep.stepId;
      page = this.pageMap.get(pageName);
    }

    if (page) {
      if (this.selectedDataModel.retainPosition || this.selectedDataModel.trackProgress) {
        const data = {
          'currentPage': page
        };

        if (this.selectedDataModel && this.selectedDataModel.pages && this.selectedDataModel.pages.length > 0
          && this.selectedDataModel.pages[this.selectedDataModel.pages.length - 1] == page) {
          data['pageFinished'] = true;
        }

        this.saveViewModeProperties(data);
      }

      if (page.onLoadApi) {
        const payload = {
          'pageName': page.name,
          'event': 'ON_PAGE_LOAD'
        };

        const apiMetadata = new APIMetadata(this.form, this.selectedDataModelFieldMap, this.errorMap, this.warningMap,this.backendErrorMap);

        const subscription = this.apiService.triggerApiOrRouteOrMvel(page.onLoadApi, payload, false, apiMetadata);

        if (subscription) {
          this.loaderService.show();
          subscription.subscribe(
            result => {
              this.loaderService.hide();

              if (result != null && result != undefined && result[APIService._IS_FORM_VALIDATION_NEEDED]) {
                this.isEntityFormValid.emit(this.checkFormisValid());
              }
            }
          );
        }
      }
    }
  }

  onExitStep(page: Page, movingDirection: any) {

  }

  
navigateWizard(navigateBy: number) {
  if (this.wizardInstance) {
    if (!navigateBy) {
      navigateBy = 0;
    }

    const newIndex = this.wizardInstance.currentStepIndex + navigateBy;
    const pageName = this.wizardInstance.currentStep.stepId;
    let page = this.pageMap.get(pageName);
    if (newIndex != this.wizardInstance.currentStepIndex && newIndex >= 0 && newIndex < this.selectedDataModel.pages.length) {
      if (newIndex > this.wizardInstance.currentStepIndex) {
        // Check for errors only when transiting to next page, not previous
        if (this.validateCurrentPage(this.wizardInstance)) {
          if (page.onCompleteApi) {
            const payload = {
              'pageName': page.name,
              'event': 'ON_PAGE_EXIT'
            };
            const apiMetadata = new APIMetadata(this.form, this.selectedDataModelFieldMap, this.errorMap, this.warningMap,this.backendErrorMap);
            const dataMap = this.entitySharingService.getPayloadToEvaluateMVEL(this.selectedDataModel, this.viewEntity, this.state);
            payload["payload"] = dataMap.payload;
            const subscription = this.apiService.triggerApiOrRouteOrMvel(page.onCompleteApi, payload, false, apiMetadata);
            if (!page.onCompleteApiSync) {
              this.wizardInstance.goToStep(newIndex);
              window.scroll(0, 0);
            }
            if (subscription) {
              this.loaderService.show();
              subscription.subscribe(
                result => {
                  this.loaderService.hide();
                  if (result != null && result != undefined && result[APIService._IS_FORM_VALIDATION_NEEDED]) {
                    this.isEntityFormValid.emit(this.checkFormisValid());
                    if (page.onCompleteApiSync) {
                      if (this.validateCurrentPage(this.wizardInstance,true)) {
                        this.wizardInstance.goToStep(newIndex);
                        window.scroll(0, 0);
                      } 
                      else {
                        this.toastrService.error('Please address the highlighted errors to proceed.');
                      }
                    }
                  }
                  else {
                    if (result[APIService._IS_FORM_VALIDATION_NEEDED] != null && result[APIService._IS_FORM_VALIDATION_NEEDED] == false) {
                      this.wizardInstance.goToStep(newIndex);
                      window.scroll(0, 0);
                    }
                    else {
                      this.toastrService.error('Something went wrong');
                    }
                  }
                }
              );
            }
          } 
          else {
            this.wizardInstance.goToStep(newIndex);
            window.scroll(0, 0);
          }
        } 
        else {
          this.toastrService.error('Please address the highlighted errors to proceed.');
        }
      } else {
        this.wizardInstance.goToStep(newIndex);
        window.scroll(0, 0);
      }
    }
  }
}

areFieldsPresentinGroup(group,fields){
  let fieldsInGroup = []
  for (let index = 0; index < fields.length; index++) {
    const field = fields[index];

    if(field.groupName == group.name){
      if(field.hide==false){
        fieldsInGroup.push(field)
      }

    }
  }
  return fieldsInGroup.length
}
onSignatureUpload(event,field){
  field.value = event
}
}