import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment'
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentData {
    private subject = new Subject<any>();
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    private envData: any = null;
    private baseURL: string = null;
    constructor(private httpClient: HttpClient) { }
    fetchEnvData(): Observable<any> {
        const url = './assets/wizard-env.json';
        this.httpClient.get(
            url,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true,
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                this.envData = response.body;
                if (this.baseURL) {
                    this.envData.baseURL = this.baseURL;
                }
                this.subject.next(response.body);
                
            },
            (err: HttpErrorResponse) => {
                this.envData = environment;
                if (this.baseURL) {
                    this.envData.baseURL = this.baseURL;
                }
                this.subject.next(environment);
            }
        )
        return this.subject.asObservable();
    }
    getEnvData() {
        return this.envData;
    }

    setRootUrl(baseURL: string) {
        this.baseURL = baseURL;
        if (!this.envData) {
            this.envData = {};
        }
        this.envData.baseURL = this.baseURL;
    }

    setFlowApiByUser(user: User) {
        if (user.authorities && user.authorities.includes("ROLE_EXTERNAL_USER")) {
            this.envData.flowAPI = "/flow/externaluser/";
        }
        else {
            this.envData.flowAPI = "/flow/console/";
        }
    }

    getEnvDataObservable(): Observable<any>{
        return this.subject.asObservable();
    }
}