<ng-container *ngIf="envLoaded && loginRequired()">
  <auto-auth [apiKey]="_apikey" (onAuthSuccess)="onAuthSuccess($event)">

  </auto-auth>
</ng-container>
<ng-container *ngIf="envLoaded && !loginRequired()">
  {{ errorMsg }}
  <div class="flex-container zIndexTop" *ngIf="loaderService.isLoaderVisible()">
    <div class="loading-dots" style="vertical-align: middle;">
      <div [innerHTML]="loaderService.getLoaderMessage()"></div>
      <br>
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
    </div>
  </div>
  
  <!-- <router-outlet></router-outlet> -->
  <form *ngIf="!uimode">
    <div class="col-md-3">
      <label class="fieldTitle">
        Select Form Mode:
      </label>
  
      <select class="col-md-12 col-sm-12 col-xs-12 form-control" title="Choose Form Mode" [(ngModel)]="uimode"
        name="uimode">
        <option value="Default">Default</option>
        <option value="Wizard Mode">Wizard Mode</option>
        <option value="View Mode">View Mode</option>
      </select>
    </div>
  </form>
  
  <ng-container *ngIf="!showThankYouPage; else elseThankYouTemplate">
    <ng-container *ngIf="viewRefreshed">
      <ng-container *ngIf="renderui =='true'">
        <div *ngIf="dataModel && entity && dataModel.uiProperties && dataModel.uiProperties.modelStartHtml"
          class="app-component container-fluid startHtml"
          [innerHTML]="getSanitisedHtml(dataModel.uiProperties.modelStartHtml)"></div>
      </ng-container>
      <app-dynamic-form *ngIf="dataModel && entity && uimode == 'Default'" class="col-md-12 row no-horizontal-margin"
        [model]="dataModel" [manualStateEntityAction]="manualStateEntityAction" [viewEntity]="entity"
        (finalEmit)="onSaveOrSubmit($event)">
      </app-dynamic-form>
  
      <app-wizard-form *ngIf="dataModel && entity && uimode == 'Wizard Mode'" class="col-md-12 row no-horizontal-margin"
        [model]="dataModel" [manualStateEntityAction]="manualStateEntityAction" [viewEntity]="entity"
        (finalEmit)="onSaveOrSubmit($event)">
      </app-wizard-form>
  
      <app-view-mode *ngIf="dataModel && entity && uimode == 'View Mode'" class="col-md-12 row no-horizontal-margin"
        [model]="dataModel" [manualStateEntityAction]="manualStateEntityAction" [viewEntity]="entity"
        (finalEmit)="saveEntity($event)">
      </app-view-mode>
      <ng-container *ngIf="renderui =='true'">
        <div *ngIf="dataModel && entity && dataModel.uiProperties && dataModel.uiProperties.modelEndHtml"
          class="app-component container-fluid endHtml"
          [innerHTML]="getSanitisedHtml(dataModel.uiProperties.modelEndHtml)">
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #elseThankYouTemplate>
    <div class="container" style="width: 100%;">
      <div class="row mt-4" [innerHTML]="this.thankYouPageHtml"></div>
    </div>
  </ng-template>
</ng-container>