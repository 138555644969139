import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { parse, stringify } from 'flatted';
import { Subscription } from 'rxjs';
import { commonKeys } from 'src/app/models/constants';
import { Field } from 'src/app/models/data-model.model';
import { CommonSearchModel } from 'src/app/models/shared.model';
import { DataModelService } from 'src/app/services/data-model.service';
import { EntityService } from 'src/app/services/entity.service';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends BaseFieldDirective {

  @Input()
  private fields: Field[];

  @Input()
  private fieldsMap: Map<string, Field>;

  @Input()
  private insertDataForView: Function;

  @Input()
  private dataModelName: string;

  @Output()
  private onEntityUpdateModalOpen: EventEmitter<any> = new EventEmitter();

  @Output()
  private onDialogEntityRefOpen: EventEmitter<any> = new EventEmitter();

  booleanList: boolean[];
  subscription: Subscription;

  matSelectClass: string = 'mat-select col-md-12 col-sm-12 col-xs-12 form-control';

  constructor(
    private entityService: EntityService,
    private dataModelService: DataModelService,
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.field && changes.field.isFirstChange() && this.field.type) {
      if (this.field.type == 'BOOLEAN') {
        this.booleanList = [true, false];
      } else if (this.field.type == 'LOOKUP') {
        this.getLookupEntitiesList(this.field);
      } else if (this.field.type == 'LOOKUP_REFERENCE') {
        this.getRefLookupEntitiesList(this.field);
      } else if(this.field.type == 'USER'){
        this.getRefList(this.field,this.dataModelName);
      } else if(this.field.type == 'GROUP'){
        this.getRefList(this.field,this.dataModelName);
      } else if(this.field.type == 'STATUS'){
        
      } else if(this.field.type == 'PRIORITY'){
        
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  updateFieldValue(changedField: { fieldName: string, inputSource: any, value: any, formGroup: FormGroup }): void {
    if (this.field.type == commonKeys.entityLookupReference) {
      this.field['actualValue'] = changedField.value;
      this.parentFormGroup.controls[this.field.name].patchValue(changedField.value);
    } else {
      this.field.value = changedField.value;
      this.parentFormGroup.controls[this.field.name].patchValue(changedField.value);
    }
  }

  showRemoveButton(field: Field): boolean {
    return ['BOOLEAN', 'SINGLE_SELECT', 'LOOKUP'].includes(field.type) && !field.list && field.value != null && field.value != undefined;
  }

  removeSelectValue(field: Field) {
    field.value = undefined;
    this.onLookupSelect(field);
  }

  onLookupSelect(changedField: Field) {
    let childFields: Field[] = [];

    for (let field of this.fields) {
      if (field.parentAttributeCode && field.parentAttributeCode == changedField.name) {
        const childField = field;
        childField.value = undefined;
        childField.lookupEntityList = [];
        childFields.push(childField);
        this.onLookupSelect(field);
      }
    }

    if (changedField && changedField.value && childFields) {
      for (const childField of childFields) {
        const commonSearchModel = new CommonSearchModel();
        let tempSearchParam = {};
        tempSearchParam["parentValueCode"] = changedField.value;
        if (childField.lookupType) {
          tempSearchParam["lookupType"] = childField.lookupType;
        }
        commonSearchModel.searchParams = [tempSearchParam];
        commonSearchModel.returnFields = [];
        this.subscription = this.entityService.getLookupEntities(childField.referenceModel, commonSearchModel)
          .subscribe(lookupList => {
            childField.lookupEntityList = lookupList;
          });
      }
    }
  }

  getLookupEntitiesList(field: Field) {
    const commonSearchModel = new CommonSearchModel();
    commonSearchModel.searchParams = [{}];
    commonSearchModel.returnFields = [];

    if (!field.parentAttributeCode || field.parentAttributeCode.trim().length == 0) {
      if (field.lookupType) {
        commonSearchModel.searchParams = [{
          lookupType: field.lookupType
        }];
      }
      this.subscription = this.entityService.getLookupEntities(field.referenceModel, commonSearchModel)
        .subscribe(lookupList => {
          field.lookupEntityList = lookupList;
        });
    } else {
      const parentField = this.fieldsMap.get(field.parentAttributeCode);

      if (parentField && parentField.value) {
        let tempSearchParam = {};
        tempSearchParam["parentValueCode"] = parentField.value;
        if (field.lookupType) {
          tempSearchParam["lookupType"] = field.lookupType;
        }
        commonSearchModel.searchParams = [tempSearchParam];

        this.subscription = this.entityService.getLookupEntities(field.referenceModel, commonSearchModel)
          .subscribe(lookupList => {
            field.lookupEntityList = lookupList;
          });
      }
    }
  }

  getRefLookupEntitiesList(field: Field) {
    const commonSearchModel = new CommonSearchModel();
    commonSearchModel.searchParams = [{}];
    commonSearchModel.returnFields = [commonKeys.entityLookUpRefLabel, commonKeys.entityLookUpRefId];
    this.subscription = this.entityService.getRefLookupEntities(field.referenceModel, commonSearchModel)
      .subscribe(lookupList => {
        let lookupRefEntityList = [];
        for (let lookupEntity of lookupList) {
          if (lookupEntity[commonKeys.entityLookUpRefId] != null && lookupEntity[commonKeys.entityLookUpRefId] != undefined) {
            let map = {};
            map["id"] = lookupEntity[commonKeys.entityLookUpRefId];
            map["name"] = lookupEntity[commonKeys.entityLookUpRefLabel];
            lookupRefEntityList.push(map);
          }
        }
        field.lookupEntityList = lookupRefEntityList;
      });
  }

  onSelectChange(item, field) {
    if (!field.list && field["entityRefValue"] != item.source.value.name) {
      field["actualValue"] = item.source.value.id;
      field["viewEntity"] = true;
      field["entityRefValue"] = item.source.value.name;

      this.subscription = this.dataModelService.getDataModelByName(field.referenceModel)
        .subscribe(
          model => {
            this.subscription = this.entityService.getEntityLookupRef(field.referenceModel, field["actualValue"])
              .subscribe(entity => {
                this.insertDataForView(model, entity);
                model["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId];
                field.value = model;
              });
          }
        );
    }
  }

  onSelectChangeList(item, field: any) {
    field["actualValue"] = [];
    field.listvalue = [];
    this.subscription = this.dataModelService.getDataModelByName(field.referenceModel)
      .subscribe(
        model => {
          field["viewEntity"] = true;
          field.value.forEach((val, index) => {
            let modelCopy = parse(stringify(model));
            this.subscription = this.entityService.getEntityLookupRef(field.referenceModel, val.id)
              .subscribe(entity => {
                this.insertDataForView(modelCopy, entity);
                field["actualValue"].push(val.id);
                modelCopy[commonKeys.entityLookUpRefLabel] = entity[commonKeys.entityLookUpRefLabel];
                modelCopy["lookupEntityRefId"] = entity[commonKeys.entityLookUpRefId];
                field.listvalue[index] = modelCopy;
              });
          });
        });
  }

  compareObjects(o1: any, o2: any) {
    if (o2 != undefined) {
      if (o1.id == o2.lookupEntityRefId || o1.id == o2.actualValue)
        return true;
      else return false
    }
  }

  compareObjectsList(o1: any, o2: any) {
    if (o2 != undefined) {
      return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }
  }

  openEntityUpdateModal(item: any, index?: number, viewingNestedEntity?: boolean) {
    const event = {
      'item': item,
      'index': index,
      'viewingNestedEntity': viewingNestedEntity
    };

    this.onEntityUpdateModalOpen.emit(event);
  }

  openDialogEntityRef(field: Field) {
    this.onDialogEntityRefOpen.emit(field);
  }

  getObjectLabel() {
    if (this.field && this.field.value && this.field.lookupEntityList) {
      for (const entity of this.field.lookupEntityList) {
        if (!this.field.list && this.compareObjects(this.field.value, entity)) {
          return entity.name;
        }
      }
    }

    return '';
  }

  getObjectLabelForList() {
    let label = [];

    if (this.field && this.field.value && this.field.lookupEntityList) {
      for (const value of this.field.value) {
        for (const entity of this.field.lookupEntityList) {
          if (this.compareObjectsList(value, entity)) {
            label.push(entity.name);
            break;
          }
        }
      }
    }

    return label;
  }
  checkChecked(field,parentFormGroup,val){
    const formControlValue = field.value;
    if(typeof formControlValue == 'boolean' || !formControlValue){
      return false;
    }else{
      let valArr =formControlValue;
      if(valArr.indexOf(val) > -1) return true;
    }
    return false;
  }
  onCheckChange(event,field,parentFormGroup:FormGroup) {
    const formControlValue = field.value;
    let valArr =[];
    // console.log(typeof formControlValue)
    if(typeof formControlValue == 'boolean' || !formControlValue) {field.value=[];}else{valArr = field.value;}
    let pos = valArr.indexOf(event.target.value);
    if (pos == -1) {
      valArr.push(event.target.value);
    }else{
      valArr.splice(pos,1);
    }
    field.value=valArr;
  }


  userList:any[]=[];
  groupList:any[]=[];
  getRefList(field:Field,dataModelName){
    let datmodelName = dataModelName;
    if (field.type == 'USER' && datmodelName) {
      this.dataModelService.getDataModelLookupByType(datmodelName,field.type,field.name).subscribe(
        response=>{
          this.userList = response;
        }
      );
    }
    if (field.type == 'GROUP' && datmodelName) {
      this.dataModelService.getDataModelLookupByType(datmodelName,field.type,field.name).subscribe(
        response=>{
          this.groupList = response;
        }
      );
    }
  }
}
