<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle view-label')">
        {{field.label}}:
    </label>

    <div *ngIf="field.value != null && field.value != undefined" class="form-control disabled view-value">
        {{ getViewModeValue() }}
    </div>
    <div *ngIf="field.value == undefined || field.value == null" class="form-control disabled view-value">&nbsp;</div>
</div>

<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    <label style="color: red;" *ngIf="!isValid(field)">
        <strong>*</strong>
    </label>

    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        {{field.label}}:
    </label>

    <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
        <img src="assets/faIcons/fa-info-circle.svg" height="12" />
    </span>

    <input *ngIf="field.type == 'INT'"
        [class]="getFieldClass(field, '-value', defaultClass)"
        type="number"
        [id]="field.name"
        [placeholder]="field.placeholder"
        [(ngModel)]="field.value"
        min="0"
        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name" />
    
    <input *ngIf="field.type == 'LONG'"
        [class]="getFieldClass(field, '-value', defaultClass)"
        type="number"
        [id]="field.name"
        [placeholder]="field.placeholder"
        [(ngModel)]="field.value"
        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name" />
    
    <input *ngIf="field.type == 'FLOAT'"
        [class]="getFieldClass(field, '-value', defaultClass)"
        type="number"
        [id]="field.name"
        [(ngModel)]="field.value"
        [placeholder]="field.placeholder"
        step="0.01"
        ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name" />
    
    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>
    
    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>