<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    <button
        [class]="getFieldClass(field, '-view-value', defaultClass)"
        type="button"
        [id]="field.name"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        (click)="onButtonClick()">
            <span style="color: red;" *ngIf="!isValid(field)">
                <strong>*</strong>
            </span>
            {{ field.label }}
    </button>

    <br>
    
    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        Perfoming '{{ field.label }}' action is mandatory
    </span>
    
    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>

    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>

    <span *ngIf="isValid(field) && !errorMap[field.name]" class="text-success">
        {{ getStatusMessage() }}
    </span>
</div>