<div class="col-md-12 no-horizontal-padding">
    <label class="fieldTitle">
        {{ childDataModel && childDataModel.label ? childDataModel.label : '' }}:
    </label>

    <br>

    <div class="api-wrapper">
        <div class="api-scroller">
            <table mat-table #table [dataSource]="entityMaterialList" class="api-table" matSort matSortDirection="asc" matSortActive="createdAt" matSortDisableClear>
                <thead></thead>
                <tbody>
                    <ng-container *ngFor="let colDet of otherColumns">
                        <ng-container matColumnDef="{{colDet['name']}}">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="{{colDet['name']}}">{{colDet['label']}}</th>
                            <td mat-cell *matCellDef="let element" [innerHTML]="formatDate(element[colDet.name], colDet.name)"></td>
                        </ng-container>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">Created At</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.createdAt | date: 'dd/MM/yy hh:mm A' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="updatedAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt">Updated At</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.updatedAt | date: 'dd/MM/yy hh:mm A' }}</td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="statusCd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="statusCd">Status</th>
                        <td mat-cell *matCellDef="let element">{{ element.statusCd }}</td>
                    </ng-container> -->
                    <!-- <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="api-sticky-col">Actions</th>
                        <td mat-cell *matCellDef="let row let i = index" class="api-sticky-col">
                            <button class="btn btn-sm std-icons" (click)="viewEntity(row)"><img src="assets/faIcons/fa-eye.svg" height="12" /> View</button>
                        </td>
                    </ng-container> -->
                    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
                </tbody>
            </table>
        </div>
    </div>
</div>