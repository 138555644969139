import { ViewEncapsulation, Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BaseFieldDirective } from '../base-field/base-field.component';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'autopi-rating-comp',
  templateUrl: './rating-comp.component.html',
  styleUrls: ['./rating-comp.component.scss'],
})
export class RatingCompComponent extends BaseFieldDirective implements OnInit {
  starArray = [];

  // For Number Scale
  colours: string[];
  startText: string;
  endText: string;

  // For Picture Scale
  pictureArray: string[];
  // For Smiley Scale
  smileyArray: any[] = [
    { img: 'sad-2.png', color: "#E1242A" },
    { img: 'sad-1.png', color: "#F15A2D" },
    { img: 'neutral.png', color: "#FBAE42" },
    { img: 'happy-1.png', color: "#8FC962" },
    { img: 'happy-2.png', color: "#3AB34A" }];

  // For SLider
  startNumber: number;
  endNumber: number;

  // /////////////////////////////
  @Input() selectedValue: number;
  @Output()
  clicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  ngOnInit() {
    if (this.field) {
      if (!this.selectedValue) this.selectedValue = 0;
      switch (this.field.uiProperties && this.field.uiProperties.ratingType) {
        case 'numberScale':
          this.colours = (!this.field.uiProperties.ratingColourArray || this.field.uiProperties.ratingColourArray.length == 0) ? ["#FE7777", "#FE7777", "#F6BB34", "#F6BB34", "#00C178", "#00C178"] : this.field.uiProperties.ratingColourArray;
          this.startText = (!this.field.uiProperties.ratingStartText) ? "Strongly Disagree" : this.field.uiProperties.ratingStartText;
          this.endText = (!this.field.uiProperties.ratingEndText) ? "Strongly Agree" : this.field.uiProperties.ratingEndText;
          break;
        case 'pictureScale':
          this.pictureArray = (!this.field.uiProperties.pictureScaleArray || this.field.uiProperties.pictureScaleArray.length == 0) ? ['cross.png'] : this.field.uiProperties.pictureScaleArray;
          break;
        case 'smileyScale':
          break;
        case 'slider':
          this.startNumber = (!this.field.uiProperties.ratingSliderStart) ? 1 : this.field.uiProperties.ratingSliderStart;
          this.endNumber = (!this.field.uiProperties.ratingSliderEnd) ? 10 : this.field.uiProperties.ratingSliderEnd;
          this.selectedValue = (this.selectedValue==-1) ? this.startNumber: this.field.value;
          break;
        default:
          this.colours = (!this.field.uiProperties.ratingColourArray || this.field.uiProperties.ratingColourArray.length == 0) ? ["#FE7777", "#FE7777", "#F6BB34", "#F6BB34", "#00C178", "#00C178"] : this.field.uiProperties.ratingColourArray;
          this.startText = (!this.field.uiProperties.ratingStartText) ? "Strongly Disagree" : this.field.uiProperties.ratingStartText;
          this.endText = (!this.field.uiProperties.ratingEndText) ? "Strongly Agree" : this.field.uiProperties.ratingEndText;
          break;
      }
    }
  }
  hoveredDiv: number = -1;

  divHoveredOn(event, index) {
    this.hoveredDiv = index;
  }
  hoverOut() {
    this.hoveredDiv = -1;
  }
  divSelected(index) {
    this.selectedValue = index+1;
    this.parentFormGroup.controls[this.field.name].patchValue(+index+1);

    this.clicked.emit(index+1)
  }
  changeSLider(ev){
    this.parentFormGroup.controls[this.field.name].patchValue(+this.selectedValue);
    this.clicked.emit(+this.selectedValue)
  }
  getViewModeValue() {
    return +parseFloat('' + this.field.value);
  }
}
