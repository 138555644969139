<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle view-label')">
        {{field.label}}:
    </label>

    <ng-container *ngIf="field.type != 'LOOKUP_REFERENCE'">
        <div *ngIf="field.value && !field.list" class="form-control disabled view-value">
            {{field.value}}
        </div>

        <ng-container *ngIf="field.value && field.list">
            <label *ngFor="let item of field.value; let lastIndex = last" class="form-control disabled view-value">
                {{ item + (lastIndex ? '' : ',&nbsp;') }}
            </label>
        </ng-container>
        <div *ngIf="field.value == undefined || field.value == null" class="form-control disabled view-value">&nbsp;</div>
    </ng-container>

    <ng-container *ngIf="field.type == 'LOOKUP_REFERENCE'">
        <a [class]="getFieldClass(field, '-view-value')" href="javascript:void(0);" *ngIf="field.value && !field.list" (click)="openEntityUpdateModal(field.value,undefined,true)">
            {{getObjectLabel()}}
        </a>

        <ng-container *ngIf="field.value && field.list">
            <a [class]="getFieldClass(field, '-view-value')" href="javascript:void(0);" *ngFor="let item of field.value; let lastIndex = last" (click)="openDialogEntityRef(field)">
                {{ getObjectLabelForList() + (lastIndex ? '' : ',&nbsp;') }}
            </a>
        </ng-container>
    </ng-container>
</div>
<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    
    
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
        <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
            <img src="assets/faIcons/fa-info-circle.svg" height="12" />
        </span>
    </label>

    

    <span *ngIf="showRemoveButton(field)"
        class="pull-right clsBtnSelect" (click)="removeSelectValue(field)">
        <img src="assets/faIcons/fa-times.svg" height="12" />
    </span>

    <select *ngIf="field.type == 'BOOLEAN'"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        title="Choose Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option *ngFor="let bool of booleanList" [ngValue]="bool">
            {{bool}}
        </option>
    </select>
    
    <select *ngIf="field.type == 'SINGLE_SELECT' && !field.uiProperties.radio"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        title="Choose Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option [ngValue]="undefined" disabled>
            Please Select
        </option>
        <option *ngFor="let value of field.inputSource" [ngValue]="value">
            {{ value }}
        </option>
    </select>

    <select *ngIf="field.type == 'USER' && !field.uiProperties.radio"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        title="Choose Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option [ngValue]="undefined" disabled>
            Please Select
        </option>
        <option *ngFor="let record of userList" [ngValue]="record.value">
            {{ record.label }}
        </option>
    </select>

    <select *ngIf="field.type == 'GROUP' && !field.uiProperties.radio"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        title="Choose Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option [ngValue]="undefined" disabled>
            Please Select
        </option>
        <option *ngFor="let record of groupList" [ngValue]="record.value">
            {{ record.label }}
        </option>
    </select>

    <select *ngIf="field.type == 'STATUS' && !field.uiProperties.radio"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        title="Choose Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option [ngValue]="undefined" disabled>
            Please Select
        </option>
        <option *ngFor="let value of field.inputSource" [ngValue]="value">
            {{ value }}
        </option>
    </select>

    <select *ngIf="field.type == 'PRIORITY' && !field.uiProperties.radio"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        title="Choose Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option [ngValue]="undefined" disabled>
            Please Select
        </option>
        <option *ngFor="let value of field.inputSource" [ngValue]="value">
            {{ value }}
        </option>
    </select>

    <ng-container *ngIf="field.type == 'SINGLE_SELECT' && field.uiProperties.radio">
        <div class="optionForRadio">
            <ng-container *ngFor="let radio of field.inputSource;index as radIndex">
                <span><input type="radio"  [formControlName]="field.name" [name]="field.name" [id]="field.name +'_'+ radIndex" [(ngModel)]="field.value" [attr.disabled]="checkForRefrenceField(field) ? '' : null" [value]="radio"> <label [for]="field.name +'_'+ radIndex">{{radio}}</label></span>
            </ng-container>
        </div>
    </ng-container>

    <select *ngIf="field.type == 'MULTI_SELECT' && !field.uiProperties.checkbox"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        multiple="true"
        title="Choose Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option *ngFor="let value of field.inputSource" [ngValue]="value">
            {{ value }}
        </option>
    </select>
    <ng-container *ngIf="field.type == 'MULTI_SELECT' && field.uiProperties.checkbox">
        <div class="optionForCheckBox">
            <ng-container *ngFor="let checkbox of field.inputSource;index as radIndex">
                <span><input type="checkbox" [name]="field.name+'_'+ radIndex" [id]="field.name +'_'+ radIndex" [attr.disabled]="checkForRefrenceField(field) ? '' : null" [value]="checkbox" [checked]="checkChecked(field,form,checkbox)" (change)="onCheckChange($event,field,form)"> <label [for]="field.name +'_'+ radIndex">{{checkbox }}</label></span>
            </ng-container>
        </div>
    </ng-container>
    <select *ngIf="field.type == 'LOOKUP' && field.list"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        multiple
        (change)="onLookupSelect(field)"
        title="Choose Lookup Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option *ngFor="let lookup of field.lookupEntityList" [ngValue]="lookup.labelValue">
            {{ lookup.labelCode }}
        </option>
    </select>

    <select *ngIf="field.type == 'LOOKUP' && !field.list"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        (change)="onLookupSelect(field)"
        title="Choose Lookup Value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <option [ngValue]="undefined" disabled>
            Please Select
        </option>
        <option *ngFor="let lookup of field.lookupEntityList" [ngValue]="lookup.labelValue">
            {{ lookup.labelCode }}
        </option>
    </select>

    <i *ngIf="field.type == 'LOOKUP_REFERENCE' && field.viewEntity && !field.list"
        class="fa fa-eye pull-right" (click)="openEntityUpdateModal(field.value,undefined,true)">
    </i>

    <mat-select *ngIf="field.type == 'LOOKUP_REFERENCE' && !field.list"
        [class]="getFieldClass(field, '-value', matSelectClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        (selectionChange)="onSelectChange($event,field)"
        [compareWith]="compareObjects"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <mat-option [value]="undefined" disabled>
            Please Select
        </mat-option>
        <mat-option *ngFor="let lookup of field.lookupEntityList" [value]="lookup">
            {{ lookup.name }}
        </mat-option>
    </mat-select>

    <i *ngIf="field.type == 'LOOKUP_REFERENCE' && field.viewEntity && field.list && field.value && field.value.length > 0"
        class="fa fa-eye pull-right" (click)="openDialogEntityRef(field)">
    </i>

    <mat-select *ngIf="field.type == 'LOOKUP_REFERENCE' && field.list"
        [class]="getFieldClass(field, '-value', matSelectClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        multiple
        (selectionChange)="onSelectChangeList($event,field)"
        [compareWith]="compareObjectsList"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
        <mat-option *ngFor="let lookup of field.lookupEntityList" [value]="lookup">
            {{ lookup.name }}
        </mat-option>
    </mat-select>
    
    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>
    
    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>