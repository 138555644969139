import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { EnvironmentData } from 'src/app/services/environments.service';
import { SecuredStorageService } from './shared.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private storageService: SecuredStorageService,
    private environmentData: EnvironmentData
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.environmentData.getEnvData() && req.url && !req.url.includes(this.environmentData.getEnvData().baseURL + this.environmentData.getEnvData().tokenAuthorizer)) {
      const updatedHeaders = {};
      for (const key of req.headers.keys()) {
        if (key && req.headers.get(key)) {
          updatedHeaders[key] = req.headers.get(key);
        }
      }

      if (this.storageService.getItem(SecuredStorageService._GENERATE_TOKEN) || this.storageService.getItem(SecuredStorageService._BEARER_TOKEN)) {
        updatedHeaders[SecuredStorageService._AUTHORIZATION] = 'Bearer ' + this.storageService.getItem(SecuredStorageService._BEARER_TOKEN);
      }
      if ( !req.url.includes(this.environmentData.getEnvData().baseURL + "/flow-api/") && localStorage.getItem(SecuredStorageService._X_AUTH_TOKEN)) {
        updatedHeaders["X-Auth-Token"] = localStorage.getItem(SecuredStorageService._X_AUTH_TOKEN);
      }
      // updatedHeaders["X-Consumer-Custom-Id"] = "5d6625dce48d91571849c3c4";
      // updatedHeaders["X-Auth-Token"] = "e88cac13-b823-4d90-a9e0-96fac0fc9d61";

      req = req.clone({
        setHeaders: updatedHeaders
      });
    }

    return next.handle(req).pipe(
      tap(event => {

      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            if (err && err.error && err.error.message) {
              console.error(err.error.message);
            }
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (err) {
              let statusCd = 0;
              let message = '';

              if (err.status) {
                statusCd = err.status;
              }
              if (err.error && err.error.message) {
                message = err.error.message;
              }
              if (statusCd == 401 || statusCd == 403) {
                this.storageService.clearStorage();
              }
              if (statusCd == 302) {
                let origin : string;
                if(err.headers.has('origin')) {
                  origin = err.headers.get('origin');
                  window.location.href = origin;
              }
              }
            }
          }
        }
      })
    );
  }
}