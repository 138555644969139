<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-view-label', 'fieldTitle')">
        {{field.label}}:
    </label>

    <br>

    <ng-container *ngIf="field.value && !field.list">
        <label [class]="getFieldClass(field, '-view-button', 'btn btn-outline-info')" (click)="downloadFile(field)">
            <img src="assets/faIcons/fa-download.svg" height="12" />&nbsp;Download
        </label>

        <label [class]="getFieldClass(field, '-view-value', 'ml-2')">
            {{field.value.fileName}}
        </label>
    </ng-container>

    <ng-container *ngIf="field.value && field.list">
        <label [class]="getFieldClass(field, '-view-button', 'btn btn-outline-info')" (click)="downloadFile(field)">
            <img src="assets/faIcons/fa-download.svg" height="12" />&nbsp;Download All
        </label>

        <label [class]="getFieldClass(field, '-view-value', 'ml-2')"
            *ngFor="let item of field.value; let lastIndex = last">
            {{ item.fileName + (lastIndex ? '' : ',&nbsp;') }}
        </label>
    </ng-container>
</div>

<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
    </label>

    <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
        <img src="assets/faIcons/fa-info-circle.svg" height="12" />
    </span>
    
    <ng-container *ngIf="field.uiProperties && field.uiProperties.otherComponent; else elseNoComponentTemplate">
        <ng-container [ngSwitch]="field.uiProperties.otherComponent">
            <ng-container *ngSwitchCase="'signaturePad'">
                <br>
                <button mat-button (click)="openSignatuerPad()">Open Signature Pad</button>
                <label *ngIf="field.value" class="btn btn-sm btn-outline-info ml-2" (click)="openModalWithComponent(field)">
                    <img src="assets/faIcons/fa-eye.svg" height="12" />
                </label>
                <ng-template #dialogRef>
                    <div [id]="field.uiProperties.idName" class="padCont">
                        <button class="btn btn-secondary clsBtn" mat-dialog-close ><i class="fa fa-times"></i></button>
                        <signature-pad [options]="signaturePadOptions"></signature-pad>
                        <button class="btn btn-secondary submitButton" (click)="drawComplete(field)"  mat-dialog-close>Submit</button>
                        <button class="btn btn-light clearBrn" (click)="clearPad()">Clear</button>
                    </div>
                </ng-template>
            </ng-container>
            <ng-container *ngSwitchDefault>
                Not Configured
            </ng-container>
                    
        </ng-container>
    </ng-container>
    <ng-template #elseNoComponentTemplate>
        <!--  This is for Traditional file Upload  -->
        <ng-container *ngIf="field.list; else elseSingleFileTemplate">
            <ng-container *ngIf="field.value && field.value.length > 0 && field.value[0].url;">
                <div *ngFor="let item of field.value; let i = index" style="min-height: 35px;">
                    
                    <label>
                        {{ decodeEntities(item.fileName) }}
                    </label>
                    
                    <button [class]="getFieldClass(field, '-button', 'btn btn-sm btn-info fl-right mr-2')" (click)="downloadFile(field,i)">
                        <img src="assets/faIcons/fa-download.svg" height="12" />
                    </button>
                    <button [class]="getFieldClass(field, '-button', 'btn btn-sm btn-secondary fl-right mr-2')"
                        (click)="onRemoveDocument(deleteBox, field, i)" [attr.disabled]="checkForRefrenceField(field) ? '' : null">
                        <img src="assets/faIcons/fa-trash.svg" height="12" />
                    </button>
                    <button [class]="getFieldClass(field, '-button', 'btn btn-sm btn-outline-info fl-right mr-2')"
                        (click)="openModalWithComponent(field,i)">
                        <img src="assets/faIcons/fa-eye.svg" height="12" />
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="field.value && field.value.length > 0 && field.value[0].length>0;">
                <div *ngFor="let item of field.value; let i = index" style="min-height: 35px;">
                    <div style="min-height: 35px;" *ngFor="let file of item; index as j">
                        <label>
                            {{file.fileName}}
                        </label>
                        
                        <button [class]="getFieldClass(field, '-button', 'btn btn-sm btn-info fl-right mr-2')" (click)="downloadFile(file,j)">
                            <img src="assets/faIcons/fa-download.svg" height="12" />
                        </button>
                        <button [class]="getFieldClass(field, '-button', 'btn btn-sm btn-secondary fl-right mr-2')"
                            (click)="onRemoveDocument(deleteBox, file, j)" [attr.disabled]="checkForRefrenceField(field) ? '' : null">
                            <img src="assets/faIcons/fa-trash.svg" height="12" />
                        </button>
                        <button [class]="getFieldClass(field, '-button', 'btn btn-sm btn-outline-info fl-right mr-2')"
                            (click)="openModalWithComponent(file,j)">
                            <img src="assets/faIcons/fa-eye.svg" height="12" />
                        </button>
                    </div>
                    
                </div>
            </ng-container>
            <input [id]="'fileUpload' + field.name + index" type="file" [multiple]="field.list"
                (change)="onDocumentUpload($event,field)" [attr.disabled]="checkForRefrenceField(field) ? '' : null" />

            <input type="hidden" name="fileHidden" [attr.disabled]="checkForRefrenceField(field) ? '' : null" />

            <br>


            <label *ngIf="!checkForRefrenceField(field)" [attr.for]="'fileUpload' + field.name + index"
                [class]="getFieldClass(field, '-button', 'btn btn-outline-info')">
                <img src="assets/faIcons/fa-upload.svg" height="12" /> Upload 
            </label>
        </ng-container>

        <ng-template #elseSingleFileTemplate>
            <label [class]="getFieldClass(field, '-label', 'ml-2')" *ngIf="field.value">
                {{ decodeEntities(field.value.fileName) }}
            </label>

            <input [id]="'fileUpload' + field.name + getUniqueIndex(field)" type="file"
                (change)="onDocumentUpload($event,field)" [attr.disabled]="checkForRefrenceField(field) ? '' : null" />

            <input type="hidden" name="fileHidden" [attr.disabled]="checkForRefrenceField(field) ? '' : null" />

            <br>

            <label *ngIf="!checkForRefrenceField(field)" [attr.for]="'fileUpload' + field.name + getUniqueIndex(field)"
                [class]="getFieldClass(field, '-button', 'btn btn-outline-info')">
                <img src="assets/faIcons/fa-upload.svg" height="12" /> Upload 
            </label>

            <ng-container *ngIf="field.value">
                
                <label class="btn btn-sm btn-outline-info ml-2" (click)="openModalWithComponent(field)">
                    <img src="assets/faIcons/fa-eye.svg" height="12" />
                </label>
                <label [class]="getFieldClass(field, '-button', 'btn btn-sm btn-secondary ml-2')"
                    (click)="onRemoveDocument(deleteBox, field)">
                    <img src="assets/faIcons/fa-trash.svg" height="12" />
                </label>

                <label [class]="getFieldClass(field, '-button', 'btn btn-sm btn-info ml-2 btnDownload')" (click)="downloadFile(field)">
                    <img src="assets/faIcons/fa-download.svg" height="12" />
                </label>
            </ng-container>
        </ng-template>
    </ng-template>
    
    



    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>

    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>

<!-- Delete file Modal -->
<ng-template #deleteBox>
    <div class="alertbox">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" (click)="onDocumentDeleteCancel()">&times;</button>
        </div>
        <div class="modal-body">
            <p>Are you sure you want to remove document?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="onDocumentDeleteConfirmation()">Yes</button>
            <button type="button" class="btn btn-success" data-dismiss="modal"
                (click)="onDocumentDeleteCancel()">No</button>
        </div>
    </div>
</ng-template>