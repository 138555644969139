import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { EnvironmentData } from 'src/app/services/environments.service';
import { AuthService, SecuredStorageService } from './shared.service';
import * as i0 from "@angular/core";
import * as i1 from "./shared.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./environments.service";
export class EntityService {
    constructor(authService, httpClient, environmentData, storageService) {
        this.authService = authService;
        this.httpClient = httpClient;
        this.environmentData = environmentData;
        this.storageService = storageService;
        this.onSaveEntity = new Subject();
    }
    getParameterizedEntityList(entityName, pageNumber, fetchRecords, entitySearchCriteria) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + entityName + "/find/" + pageNumber + "," + fetchRecords;
        this.httpClient.post(url, entitySearchCriteria, {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
        })
            .subscribe((response) => {
            if (response.body) {
                subject.next(response.body);
            }
        }, (err) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
            subject.error(err);
        });
        return subject.asObservable();
    }
    getEntityList(entityName, pageNumber, fetchRecords) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + entityName + '/findAll/' + pageNumber + "," + fetchRecords;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getSaveEntityObservable() {
        return this.onSaveEntity;
    }
    /**
     * This is a very special condition where existing linkage (_linkageId) is broken when
     * parent id (_parentId) is not present while making the save/submit entity call.
     *
     * The API actually supports entity without parentId, with just linkageId to retain the
     * existing linkage, but then it leave us no way to clear a linkage.
     *
     * Since field is only present for parentId and mechanically, parentId will never be
     * absent unless cleared manually, we're going to treat this action as removal of
     * existing linkage.
     *
     * The API is designed in such fashion to support all the save/submit call that are not
     * happening from UI (backend/internal calls), where the response of save/submit never
     * contains the parentId (it gets removed the moment linkage is created and linkageId
     * is persisted instead), thus retains the existing linkage as long as the linkageId is
     * present in the request payload.
     */
    manageLinkageFields(entityMap) {
        if (entityMap && (!entityMap['_parentId'] || entityMap['entityMap'].trim().length == 0)) {
            delete entityMap['_linkageId'];
            delete entityMap['_parentId'];
            delete entityMap['_parentDataModelName'];
        }
    }
    saveEntity(entity, map, isSavingWizardProgress) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + entity.name;
        this.manageLinkageFields(map);
        if (this.storageService.getItem(SecuredStorageService._USER_INFO)) {
            map["_userInfo"] = this.storageService.getItem(SecuredStorageService._USER_INFO);
        }
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, map, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                    this.onSaveEntity.next();
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    submitEntity(entity, map) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + entity.name + '/submit';
        this.manageLinkageFields(map);
        if (this.storageService.getItem(SecuredStorageService._USER_INFO)) {
            map["_userInfo"] = this.storageService.getItem(SecuredStorageService._USER_INFO);
        }
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, map, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    updateEntity(entity, map) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + entity.name + '/update';
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, map, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getEntity(entityName, id) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + entityName + "/" + id;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getDuplicateEntity(entityName, id) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + "duplicateEntity/" + entityName + "/" + id;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getEntityLookupRef(referenceModel, id) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.entitrefurl + referenceModel + "/" + id;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getLookupEntities(referenceModel, map) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.lookupurl + referenceModel;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, map, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getRefLookupEntities(referenceModel, map) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.reflookupurl + referenceModel;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, map, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getReferenceEntity(referenceModel, map) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.getrefentity + referenceModel;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, map, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    // entityUspSearch(scrollId?: string, searchText?: string): Observable<any> {
    //   const subject = new Subject<any>();
    // 
    //   const url = `${environment.interfaceService + environment.uspsearch}`;
    //   const body = {
    //     "searchText":searchText,
    //     "entityType":"entity"
    //   }
    //   this.authService.validateAuthToken().subscribe(
    //     (isValidToken: boolean) => {
    //       this.httpClient.post<any>(
    //         url,
    //         body,
    //         {
    //           observe: 'response',
    //           reportProgress: true,
    //           withCredentials: true
    //         }
    //       )
    //         .subscribe(
    //         (response: HttpResponse<any>) => {
    //           if (response.body) {
    //             subject.next(response.body);
    //           }
    //         },
    //         (err: HttpErrorResponse) => {
    //           // All errors are handled in ErrorInterceptor, no further handling required
    //           // Unless any specific action is to be taken on some error
    // 
    //           subject.error(err);
    //         }
    //         );
    //     }
    //   );
    // 
    //   return subject.asObservable();
    // }
    evaluateMvel(entityName, payload) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.evaluateMvel + entityName;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, payload, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    getDataModelLookupByType(datamodelName, type, attributeName, parentGroupName) {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject();
        let url = "";
        if (parentGroupName) {
            url = `${envData.baseURL + '/flow-api/' + envData.datamodelurl + 'lookup/' + datamodelName + '/' + attributeName + '/USER/' + parentGroupName}`;
        }
        else {
            let suffix = "";
            switch (type) {
                case 'GROUP':
                    suffix = '/' + attributeName + '/GROUP';
                    break;
                case 'USER':
                    suffix = '/' + attributeName + '/USER';
                    break;
                case 'PRIORITY':
                    suffix = '/priority/PRIORITY';
                    break;
                case 'STATUS':
                    suffix = '/status/STATUS';
                    break;
                default:
                    break;
            }
            url = `${envData.baseURL + '/flow-api/' + envData.datamodelurl + 'lookup/' + datamodelName + suffix}`;
        }
        this.httpClient.get(url, {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
        })
            .subscribe((response) => {
            if (response.body) {
                subject.next(response.body);
            }
        }, (err) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
            subject.error(err);
        });
        return subject.asObservable();
    }
    /**
   *  This section checks Validation for entire Entity
   */
    getValidationForEntityId(entityId) {
        const subject = new Subject();
        if (entityId) {
            const envData = this.environmentData.getEnvData();
            const url = envData.baseURL + envData.flowAPI + "entity/validation/" + entityId + "/0/50";
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        }
        else {
            subject.error(false);
        }
        return subject.asObservable();
    }
    getValidationCountForEntityId(entityId) {
        const subject = new Subject();
        if (entityId) {
            const envData = this.environmentData.getEnvData();
            const url = envData.baseURL + envData.flowAPI + "entity/validation/count/" + entityId;
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        }
        else {
            subject.error(false);
        }
        return subject.asObservable();
    }
    /**
     * This section is for Getting not required field that are shown for a Datamodel
     */
    getEntityCommonFields() {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject();
        let url = envData.baseURL + envData.flowAPI;
        if (!url.includes("/flow-api/") && localStorage.getItem(SecuredStorageService._X_AUTH_TOKEN)) {
            url += "entity/getEntityCommonFields";
        }
        else {
            url += "dataModel/getEntityCommonFields";
        }
        this.httpClient.get(url, {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
        })
            .subscribe((response) => {
            if (response.body) {
                subject.next(response.body);
            }
        }, (err) => {
            subject.error(err);
        });
        return subject.asObservable();
    }
}
EntityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntityService_Factory() { return new EntityService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.EnvironmentData), i0.ɵɵinject(i1.SecuredStorageService)); }, token: EntityService, providedIn: "root" });
