/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./html-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./html-viewer.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../services/shared.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../services/api.service";
var styles_HtmlViewerComponent = [i0.styles];
var RenderType_HtmlViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HtmlViewerComponent, data: {} });
export { RenderType_HtmlViewerComponent as RenderType_HtmlViewerComponent };
function View_HtmlViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["placement", "right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openInfoModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["height", "12"], ["src", "assets/faIcons/fa-info-circle.svg"]], null, null, null, null, null))], null, null); }
export function View_HtmlViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlViewerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-md-12 no-horizontal-padding"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPopupInfoApplicable(); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getHtmlContent(_co.field.uiProperties.fieldStartHtml); _ck(_v, 2, 0, currVal_1); }); }
export function View_HtmlViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-html-viewer", [], null, null, null, View_HtmlViewerComponent_0, RenderType_HtmlViewerComponent)), i1.ɵdid(1, 1818624, null, 0, i3.HtmlViewerComponent, [i4.DomSanitizer, i1.ChangeDetectorRef, i5.FieldOnChangeService, i6.MatDialog, i7.APIService, i5.DataSharingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HtmlViewerComponentNgFactory = i1.ɵccf("app-html-viewer", i3.HtmlViewerComponent, View_HtmlViewerComponent_Host_0, { parentFormGroup: "parentFormGroup", field: "field", errorMap: "errorMap", warningMap: "warningMap", highlightErrors: "highlightErrors", viewMode: "viewMode", currentWizardStep: "currentWizardStep" }, { onRendered: "onRendered" }, []);
export { HtmlViewerComponentNgFactory as HtmlViewerComponentNgFactory };
