import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DataModel, Field } from 'src/app/models/data-model.model';
import { FieldOnChangeService, FileService } from 'src/app/services/shared.service';
import { ImagePdfViewerComponent } from '../../utility/image-pdf-viewer/image-pdf-viewer.component';
import { BaseFieldDirective } from '../base-field/base-field.component';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent extends BaseFieldDirective {

  @Input()
  protected dataModel: DataModel;

  @Input()
  protected viewEntity: any;

  @Output()
  protected onFileUpload: EventEmitter<any> = new EventEmitter();

  @Output()
  protected onFileRemoved: EventEmitter<any> = new EventEmitter();

  protected bsModalRef: BsModalRef;
  protected indexOfDocumentToBeRemoved: number;

  constructor(
    protected fileService: FileService,
    protected modalService: BsModalService,
    protected snackBar: MatSnackBar,
    protected changeDetector: ChangeDetectorRef,
    protected toastrService: ToastrService,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  onDocumentUpload(event, field: Field) {
    let selectedFiles = event.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      for (let index = 0; index < selectedFiles.length; index++) {
        let validSize = true;
        let sizeInBytes: number = selectedFiles[index].size;
        if (field.validationExpression != undefined && field.validationExpression && field.validationExpression.length > 0) {
          validSize = this.checkSizeValidation(field.validationExpression, sizeInBytes);
        }

        if (validSize) {
          const fileInputForm = new FormData();
          const file: File = selectedFiles[index];
          fileInputForm.append('file', file, file.name);
          fileInputForm.append("fileName", file.name);
          fileInputForm.append("functionInstanceName", field.functionInstanceNameForFile ? field.functionInstanceNameForFile : "API");
          fileInputForm.append("entityType", "ACTIVITY_" + this.dataModel.name);
          let entityRef = this.viewEntity && this.viewEntity._documentId ? this.viewEntity._documentId : this.dataModel._id;
          entityRef = entityRef + "_" + field.name;
          fileInputForm.append("entityRef", entityRef);
          console.log(fileInputForm)
          if (field.value && field.value.vimeoVideoUrl) {
            fileInputForm.append("vimeoUri", field.value.vimeoVideoUrl);

            let videoPayload = this.getVideoNameAndDescription(this.dataModel.fields);
            fileInputForm.append("videoName", videoPayload['name']);
            fileInputForm.append("videoDescription", videoPayload['description']);
          }
          if (field.publicFile) {
            fileInputForm.append("publicRead", "true");
          }
          this.uploadFile(fileInputForm, field).subscribe(
            onFileUpload => {
              if (field.list) {
                if (field.value.length == selectedFiles.length) {
                  this.parentFormGroup.controls[field.name].patchValue(field.value);
                }
              } else {
                this.parentFormGroup.controls[field.name].patchValue(field.value);
                // console.log(this.parentFormGroup.controls[field.name],field.value)
              }
            }
          );
        } else {
          let errorMessage = '';
          if (field.errorMessage.length > 0) {
            errorMessage = field.errorMessage;
          }
          else {
            errorMessage = "File size exceeded, size limit is " + field.validationExpression;
          }
          this.snackBar.open(errorMessage, "Dismiss", {
            duration: 10000
          });
        }
      }
    }
  }

  uploadFile(formData: FormData, field: Field) {
    const subject = new Subject<any>();

    this.fileService.upload(formData)
      .subscribe(
        response => {
          this.toastrService.success('File uploaded successfully!');

          if (response && response["url"] && response["fileName"]) {
            if (field.list == true) {
              if (field.value == undefined) {
                field.value = [];
              }
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              doc.interfaceUrl = response["interfaceUrl"];
              if (response["publicURL"]) {
                doc.publicURL = response["publicURL"];
              }
              field.value.push(doc);
            } else {
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              doc.interfaceUrl = response["interfaceUrl"];
              if (response["publicURL"]) {
                doc.publicURL = response["publicURL"];
              }
              field.value = doc;
            }
            
            subject.next();
            this.onFileUpload.emit();
          }
        },
        error => {
          if (error.error && error.error.error && error.error.error.message) {
            this.toastrService.error(error.error.error.message);  
          }
          else {
            this.toastrService.error('Failed to upload file, please try again.');
          }
        });

    return subject.asObservable();
  }

  downloadFile(field: Field, index?: number) {
    if (index != undefined) {
      if (field.value && field.value[index]) {
        let docValue = field.value[index];

        if (field.list && field.value[index].length > 0) {
          docValue = field.value[index]
        }

        if (docValue.downloadFileUrl.startsWith("http")) {
          window.open(docValue.downloadFileUrl, "_blank");
          return;
        }
        this.fileService.download(docValue.downloadFileUrl);
      }else if(field['downloadFileUrl']){
        let docValue = field;
        if (docValue['downloadFileUrl'].startsWith("http")) {
          window.open(docValue['downloadFileUrl'], "_blank");
          return;
        }

        this.fileService.download(docValue['downloadFileUrl']);
      }
    } else {
      if (field.value) {
        let docValue = field.value;

        if (field.list && field.value.length > 0) {
          docValue = field.value
        }

        if (docValue && docValue.downloadFileUrl && docValue.downloadFileUrl.startsWith("http")) {
          window.open(docValue.downloadFileUrl, "_blank");
          return;
        }
        if(docValue && !docValue.downloadFileUrl && docValue.length>0){
          if(docValue.length==1){
            this.fileService.download(docValue[0].downloadFileUrl);
          }
          else{
            this.fileService.downloadMultipleFiles(docValue);
          }
          // for (let index = 0; index < docValue.length; index++) {
          //   const singleDoc = docValue[index];
          //   this.fileService.download(singleDoc.downloadFileUrl);
          // }
        }else{
          this.fileService.download(docValue.downloadFileUrl);
        }
      }
    }
  }

  openModalWithComponent(field: Field, index?: number) {
    if (index != undefined) {
      let initialState={};
      if (field.value && field.value[index]) {
        initialState = {
          doc: (field.value[index]),
          title: 'Document Title'
        };
      }else if(field['downloadFileUrl']){
        initialState = {
          doc: (field),
          title: 'Document Title'
        };
      } 

      this.bsModalRef = this.modalService.show(ImagePdfViewerComponent, { initialState });
      this.bsModalRef.content.closeBtnName = 'Close';
    } else {
      const initialState = {
        doc: (field.value),
        title: 'Document Title'
      };
      this.bsModalRef = this.modalService.show(ImagePdfViewerComponent, { initialState });
      this.bsModalRef.content.closeBtnName = 'Close';
    }
  }

  viewFile(fieldValue) {
    this.fileService.download(fieldValue.downloadFileUrl, true).subscribe(
      objectUrl => {
        if (objectUrl) {
          var type = (objectUrl.split(';')[0]).replace("data:", "");
          fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              // const file = new File([blob], this.doc.fileName,{ type: type })
              // return (file);
            })
          // this.downloadFileObjectUrl = objectUrl;
        }
      }
    );
  }

  onRemoveDocument(templateRef: TemplateRef<any>, field: Field, ind?: number) {
    this.indexOfDocumentToBeRemoved = ind;
    this.bsModalRef = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });
  }

  onDocumentDeleteConfirmation() {
    var docValue = this.field.value;

    if (this.field.list) {
      docValue = docValue[0];
    }

    let vimeoId = null
    if (docValue.vimeoVideoUrl && docValue.vimeoVideoUrl.length > 0) {
      vimeoId = docValue.vimeoVideoUrl.match(/\d+/)[0];
    }

    this.fileService.delete(docValue.url, vimeoId)
      .subscribe(
        response => {
          this.toastrService.success('File deleted successfully!');

          if (this.indexOfDocumentToBeRemoved != undefined) {
            this.field.value.splice(this.indexOfDocumentToBeRemoved, 1);
            this.parentFormGroup.controls[this.field.name].patchValue(this.field.value);
          } else {
            this.field.value = undefined;
            this.parentFormGroup.controls[this.field.name].patchValue(this.field.value);
          }

          setTimeout(() => {  // To avoid first on change when the form is created
            this.onFileRemoved.emit();
          }, 10);

          this.bsModalRef.hide();
        },
        error => {
          this.toastrService.error('Failed to delete file, please try again.');
        }
      )
  }

  onDocumentDeleteCancel() {
    this.bsModalRef.hide();
  }

  getUniqueIndex(field) {
    if (field.currentIndex) {
      return field.currentIndex;
    }

    return 0;
  }

  checkSizeValidation(validationRegex: string, size: number): boolean {
    let configuredSize = validationRegex.match(/\d+/);
    let actualSize = null;

    if (validationRegex.toLowerCase().includes("m")) {
      actualSize = size / 1024 / 1024;
    } else if (validationRegex.toLowerCase().includes("k")) {
      actualSize = size / 1024;
    }

    if (configuredSize.length > 0 && !isNaN(Number(configuredSize[0]))) {
      if (actualSize < +configuredSize) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  /**
   * In case the model containing the video file holds 'name' and
   * 'description' keys/fields their values are being used to define
   * the name and the description for the video being uploaded.
   */
  getVideoNameAndDescription(fields: Field[]) {
    let videoPayload = {}

    for (const field of fields) {
      if (field.name.toLowerCase() == "name") {
        videoPayload["name"] = field.value;
      }

      if (field.name.toLowerCase() == "description") {
        videoPayload["description"] = field.value;
      }
    }

    return videoPayload;
  }

  decodeEntities(encodedString) {
    if (encodedString && typeof encodedString == "string") {
      var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
      var translate = {
        "nbsp": " ",
        "amp": "&",
        "quot": "\"",
        "lt": "<",
        "gt": ">"
      };
      return encodedString.replace(translate_re, function (match, entity) {
        return translate[entity];
      }).replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
    }
    return encodedString;
  }



  // Signature Pad
  @ViewChild(SignaturePad,{static:false}) signaturePad: SignaturePad;
  @ViewChild("dialogRef",{static:false}) dialogRef: TemplateRef<any>;
  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 2,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  openSignatuerPad(): void{
    this.matDialog.open(this.dialogRef, { data: "some data" });
    if(this.signaturePad){
      this.signaturePad.set('minWidth', 2); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
  }
  drawComplete(field) {
    console.log()
    // will be notified of szimek/signature_pad's onEnd event
    const file = new File([this.dataURLtoBlob(this.signaturePad.toDataURL())], 'signature_'+this.dataModel._id,{ type: 'image/png' })
    const fileInputForm = new FormData();
    fileInputForm.append('file', file, file.name+".png");
    fileInputForm.append("fileName", file.name+".png");
    fileInputForm.append("functionInstanceName", "Activity");
    fileInputForm.append("entityType", "ACTIVITY_" + this.dataModel.name);
    let entityRef = this.viewEntity && this.viewEntity._documentId ? this.viewEntity._documentId : this.dataModel._id;
    entityRef = entityRef + "_" + field.name;
    fileInputForm.append("entityRef", entityRef);
    // fileInputForm.append("publicRead", "true");

    this.uploadFile(fileInputForm, field).subscribe(
      onFileUpload => {
        this.parentFormGroup.controls[field.name].patchValue(field.value);
      }
    );
  }
  clearPad(){
    this.signaturePad.clear();
  }
  dataURLtoBlob(dataURL) {
    var byteString = atob(dataURL.split(',')[1]);
    var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}
}