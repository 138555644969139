<div class="container-fluid no-horizontal-padding vertical-padding-15 root-container" *ngIf="selectedDataModel">
  
    <aw-wizard *ngIf="arePagesPresent()" #wizard [navBarLayout]="'large-filled-symbols'"
        [defaultStepIndex]="getDefaultStepIndex()" class="wizard-root" [navBarLocation]="selectedDataModel.uiProperties.navPosition" [ngClass]="{'hideNavigation': selectedDataModel.uiProperties.showNavigationPanel == false}">
        <aw-wizard-step 
            [stepTitle]="page.label" 
            [stepId]="page.name" 
            [navigationSymbol]="{ symbol: page.wizardStepName ? page.wizardStepName : (pageIndex + 1)}"
            [awCompletedStep]="isCompleted(page)" 
            [class]="getPageClass(page)"
            (stepEnter)="onEnterStep(page, $event)" (stepExit)="onExitStep(page, $event)"
            *ngFor="let page of selectedDataModel.pages | orderBy:'sortOrder'; let pageIndex = index; let firstPage = first; let lastPage = last">
            <div *ngIf="page && page.uiProperties && page.uiProperties.pageStartHtml" class="wizard-mode container-fluid startHtml"
                [innerHTML]="getSanitisedHtml(page.uiProperties.pageStartHtml)"></div>
            <div class="progressBar" *ngIf="!innerForm"><small>Page Progress:</small> <progress id="file" [value]="pageIndex + 1" [max]="selectedDataModel.pages.length"></progress> <small>{{pageIndex + 1}}/{{selectedDataModel.pages.length}}</small></div>
            <form [formGroup]="form" class="root-form">
                <div class="row no-horizontal-margin root-form-inner-div">
                    <ng-container *ngIf="selectedDataModel.groups && selectedDataModel.groups.length>0; else noGroupsPresentForNoPagesTemplate">
                        <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                            <ng-container *ngIf="!field.groupName">
                                <ng-container class="field-container" *ngIf="isFieldInPage(page, field) && conditionForField(field)">
        
                                    <!-- Custom Text Field -->
                                    <app-text *ngIf="field.type == 'TEXT'" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                        (onRendered)="onFieldRendered()"></app-text>
                                    <!-- Custom Text Field -->
        
                                    <!-- Custom Text Field -->
                                    <app-text-area *ngIf="field.type == 'TEXTAREA'" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                        (onRendered)="onFieldRendered()"></app-text-area>
                                    <!-- Custom Text Field -->
        
                                    <!-- Custom Password Field -->
                                    <app-password *ngIf="field.type == 'PASSWORD' || field.type == 'SECURED'"
                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                        [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-password>
                                    <!-- Custom Password Field -->
        
                                    <!-- Custom Number Field -->
                                    <app-number *ngIf="field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT'"
                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                        [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-number>
                                    <!-- Custom Number Field -->
        
                                    <!-- Custom Checkbox Field -->
                                    <app-checkbox *ngIf="field.type == 'CHECKBOX'" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                        (onRendered)="onFieldRendered()"></app-checkbox>
                                    <!-- Custom Checkbox Field -->
        
                                    <!-- Dyanamic Table Field -->
                                    <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                                    </app-dynamic-table>
                                    <!-- Dyanamic Table Field -->
        
                                    <!-- Custom Select Field -->
                                    <app-select
                                        *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE'].includes(field.type)"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" [fields]="selectedDataModel.fields"
                                        [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                        (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                        (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                        (onRendered)="onFieldRendered()">
                                    </app-select>
                                    <!-- Custom Select Field -->
        
                                    <!-- Custom Date Field -->
                                    <app-date *ngIf="field.type == 'DATE' || field.type == 'DATETIME'"
                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                        [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-date>
                                    <!-- Custom Date Field -->
        
                                    <!-- Custom File Field -->
                                    <app-file
                                        *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                                        (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" [viewEntity]="viewEntity" (onRendered)="onFieldRendered()">
                                    </app-file>
                                    <!-- Custom File Field -->
        
                                    <!-- Custom Video Upload Field -->
                                    <app-video-upload *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel" [viewEntity]="viewEntity"
                                        (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                                    </app-video-upload>
                                    <!-- Custom Video Upload Field -->
                                    
                                    <!-- Custom Model Field -->
                                    <app-model *ngIf="field.type == 'MODEL'" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                                        (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                        (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                        (onRendered)="onFieldRendered()">
                                    </app-model>
                                    <!-- Custom Model Field -->
                                    
                                    <!-- Custom Html Viewer Field -->
                                    <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel" [viewEntity]="viewEntity" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()" (onSignatureUpload)="onSignatureUpload($event, field)"></app-html-viewer>
                                    <!-- Custom Html Viewer Field -->
        
                                    <!-- Custom Rating Field -->
                                    <autopi-rating-comp *ngIf="field.type == 'RATING'"
                                        [field]="field"
                                        [parentFormGroup]="form"
                                        [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors"
                                        (clicked)="field.value=$event"
                                        [selectedValue]="field.value" 
                                        [(ngModel)]="field.value" 
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" 
                                        [id]="field.name"
                                        [name]="field.name"
                                        [ngModelOptions]="{standalone: true}" ngDefaultControl
                                        (onRendered)="onFieldRendered()"></autopi-rating-comp>
                                    <!-- Custom Rating Field -->
        
                                    <!-- Custom Text Field -->
                                    <app-button *ngIf="field.type == 'BUTTON'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()" (onClick)="onButtonClick(field)"></app-button>
                                    <!-- Custom Text Field -->
        
                                    <!-- Custom System Field -->
                                    <app-system-variable-field
                                        *ngIf="['GROUP', 'PRIORITY', 'STATUS','USER'].includes(field.type)"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [datamodelName]="selectedDataModel.name"
                                        [highlightErrors]="highlightErrors" [fields]="selectedDataModel.fields"
                                        [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                        (onRendered)="onFieldRendered()">
                                    </app-system-variable-field>
                                    <!-- Custom System Field -->
        
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        
                        <ng-container *ngFor="let group of selectedDataModel.groups | orderBy:'sortOrder'; let grpIndex = index; let grouIndex=index">
                            <ng-container *ngIf="areFieldsPresentinGroup(group,selectedDataModel.fields) > 0">
                                <ng-container *ngIf="areGroupFieldsPresentInPage(group,page)">
                                
                                    <ng-container *ngIf="group.uiProperties.accordionFlag == true; else nonCollapsedTemplate">
                                        <legend class="col-md-12" (click)="toggleCollapse(group)">
                                            <div class="fieldsetLabel">{{group.label}}
                                                <button class="btn collapse-pull-right" *ngIf="group.uiProperties.iscollapsed == true"><i class="fa fa-chevron-up"></i></button>
                                                <button class="btn collapse-pull-right" *ngIf="group.uiProperties.iscollapsed == false"><i class="fa fa-chevron-down"></i></button>
                                            </div>
                                        </legend> 
                                    </ng-container>
                                    <ng-template #nonCollapsedTemplate>
                                        <legend class="col-md-12">
                                            <div class="fieldsetLabel">{{group.label}}</div>
                                        </legend> 
                                    </ng-template>
                                    <fieldset  [ngClass]="{'collapse': group.uiProperties.iscollapsed == true}" [class]="'col-md-12 groupedFields'+ grpIndex">
                                        <div class="row">

                                            <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                                                <ng-container *ngIf="field.groupName == group.name">
                                                    <ng-container class="field-container" *ngIf="isFieldInPage(page, field) && conditionForField(field)">
                            
                                                        <!-- Custom Text Field -->
                                                        <app-text *ngIf="field.type == 'TEXT'" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                                            (onRendered)="onFieldRendered()"></app-text>
                                                        <!-- Custom Text Field -->
                            
                                                        <!-- Custom Text Field -->
                                                        <app-text-area *ngIf="field.type == 'TEXTAREA'" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                                            (onRendered)="onFieldRendered()"></app-text-area>
                                                        <!-- Custom Text Field -->
                            
                                                        <!-- Custom Password Field -->
                                                        <app-password *ngIf="field.type == 'PASSWORD' || field.type == 'SECURED'"
                                                            [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                                            [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-password>
                                                        <!-- Custom Password Field -->
                            
                                                        <!-- Custom Number Field -->
                                                        <app-number *ngIf="field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT'"
                                                            [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                                            [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-number>
                                                        <!-- Custom Number Field -->
                            
                                                        <!-- Custom Checkbox Field -->
                                                        <app-checkbox *ngIf="field.type == 'CHECKBOX'" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                                            (onRendered)="onFieldRendered()"></app-checkbox>
                                                        <!-- Custom Checkbox Field -->
                            
                                                        <!-- Dyanamic Table Field -->
                                                        <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                                                        </app-dynamic-table>
                                                        <!-- Dyanamic Table Field -->
                            
                                                        <!-- Custom Select Field -->
                                                        <app-select
                                                            *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE'].includes(field.type)"
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors" [fields]="selectedDataModel.fields"
                                                            [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                                            (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                                            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                                            (onRendered)="onFieldRendered()">
                                                        </app-select>
                                                        <!-- Custom Select Field -->
                            
                                                        <!-- Custom Date Field -->
                                                        <app-date *ngIf="field.type == 'DATE' || field.type == 'DATETIME'"
                                                            [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                                            [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-date>
                                                        <!-- Custom Date Field -->
                            
                                                        <!-- Custom File Field -->
                                                        <app-file
                                                            *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                                                            (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" [viewEntity]="viewEntity" (onRendered)="onFieldRendered()">
                                                        </app-file>
                                                        <!-- Custom File Field -->
                            
                                                        <!-- Custom Video Upload Field -->
                                                        <app-video-upload *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel" [viewEntity]="viewEntity"
                                                            (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                                                        </app-video-upload>
                                                        <!-- Custom Video Upload Field -->
                                                        
                                                        <!-- Custom Model Field -->
                                                        <app-model *ngIf="field.type == 'MODEL'" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors" [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                                                            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                                            (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                                            (onRendered)="onFieldRendered()">
                                                        </app-model>
                                                        <!-- Custom Model Field -->
                                                        
                                                        <!-- Custom Html Viewer Field -->
                                                        <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-html-viewer>
                                                        <!-- Custom Html Viewer Field -->
                            
                                                        <!-- Custom Rating Field -->
                                                        <autopi-rating-comp *ngIf="field.type == 'RATING'"
                                                            [field]="field"
                                                            [parentFormGroup]="form"
                                                            [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [highlightErrors]="highlightErrors"
                                                            (clicked)="field.value=$event"
                                                            [selectedValue]="field.value" 
                                                            [(ngModel)]="field.value" 
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" 
                                                            [id]="field.name"
                                                            [name]="field.name"
                                                            [ngModelOptions]="{standalone: true}" ngDefaultControl
                                                            (onRendered)="onFieldRendered()"></autopi-rating-comp>
                                                        <!-- Custom Rating Field -->
                            
                                                        <!-- Custom Text Field -->
                                                        <app-button *ngIf="field.type == 'BUTTON'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()" (onClick)="onButtonClick(field)"></app-button>
                                                        <!-- Custom Text Field -->
                            
                                                        <!-- Custom System Field -->
                                                        <app-system-variable-field
                                                            *ngIf="['GROUP', 'PRIORITY', 'STATUS','USER'].includes(field.type)"
                                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                            [datamodelName]="selectedDataModel.name"
                                                            [highlightErrors]="highlightErrors" [fields]="selectedDataModel.fields"
                                                            [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                                            (onRendered)="onFieldRendered()">
                                                        </app-system-variable-field>
                                                        <!-- Custom System Field -->
                            
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </fieldset>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #noGroupsPresentForNoPagesTemplate>
                        <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                            <ng-container class="field-container" *ngIf="isFieldInPage(page, field) && conditionForField(field)">
    
                                <!-- Custom Text Field -->
                                <app-text *ngIf="field.type == 'TEXT'" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                    (onRendered)="onFieldRendered()"></app-text>
                                <!-- Custom Text Field -->
    
                                <!-- Custom Text Field -->
                                <app-text-area *ngIf="field.type == 'TEXTAREA'" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                    (onRendered)="onFieldRendered()"></app-text-area>
                                <!-- Custom Text Field -->
    
                                <!-- Custom Password Field -->
                                <app-password *ngIf="field.type == 'PASSWORD' || field.type == 'SECURED'"
                                    [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                    [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-password>
                                <!-- Custom Password Field -->
    
                                <!-- Custom Number Field -->
                                <app-number *ngIf="field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT'"
                                    [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                    [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-number>
                                <!-- Custom Number Field -->
    
                                <!-- Custom Checkbox Field -->
                                <app-checkbox *ngIf="field.type == 'CHECKBOX'" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                    (onRendered)="onFieldRendered()"></app-checkbox>
                                <!-- Custom Checkbox Field -->
    
                                <!-- Dyanamic Table Field -->
                                <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                                </app-dynamic-table>
                                <!-- Dyanamic Table Field -->
    
                                <!-- Custom Select Field -->
                                <app-select
                                    *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE'].includes(field.type)"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" [fields]="selectedDataModel.fields"
                                    [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                    (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                    (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                    (onRendered)="onFieldRendered()">
                                </app-select>
                                <!-- Custom Select Field -->
    
                                <!-- Custom Date Field -->
                                <app-date *ngIf="field.type == 'DATE' || field.type == 'DATETIME'"
                                    [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                    [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-date>
                                <!-- Custom Date Field -->
    
                                <!-- Custom File Field -->
                                <app-file
                                    *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                                    (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" [viewEntity]="viewEntity" (onRendered)="onFieldRendered()">
                                </app-file>
                                <!-- Custom File Field -->
    
                                <!-- Custom Video Upload Field -->
                                <app-video-upload *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel" [viewEntity]="viewEntity"
                                    (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                                </app-video-upload>
                                <!-- Custom Video Upload Field -->
                                
                                <!-- Custom Model Field -->
                                <app-model *ngIf="field.type == 'MODEL'" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                                    (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                    (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                    (onRendered)="onFieldRendered()">
                                </app-model>
                                <!-- Custom Model Field -->
                                
                                <!-- Custom Html Viewer Field -->
                                <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel" [viewEntity]="viewEntity" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()" (onSignatureUpload)="onSignatureUpload($event, field)"></app-html-viewer>
                                <!-- Custom Html Viewer Field -->
    
                                <!-- Custom Rating Field -->
                                <autopi-rating-comp *ngIf="field.type == 'RATING'"
                                    [field]="field"
                                    [parentFormGroup]="form"
                                    [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors"
                                    (clicked)="field.value=$event"
                                    [selectedValue]="field.value" 
                                    [(ngModel)]="field.value" 
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" 
                                    [id]="field.name"
                                    [name]="field.name"
                                    [ngModelOptions]="{standalone: true}" ngDefaultControl
                                    (onRendered)="onFieldRendered()"></autopi-rating-comp>
                                <!-- Custom Rating Field -->
    
                                <!-- Custom Text Field -->
                                <app-button *ngIf="field.type == 'BUTTON'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()" (onClick)="onButtonClick(field)"></app-button>
                                <!-- Custom Text Field -->
    
                                <!-- Custom System Field -->
                                <app-system-variable-field
                                    *ngIf="['GROUP', 'PRIORITY', 'STATUS','USER'].includes(field.type)"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [datamodelName]="selectedDataModel.name"
                                    [highlightErrors]="highlightErrors" [fields]="selectedDataModel.fields"
                                    [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                    (onRendered)="onFieldRendered()">
                                </app-system-variable-field>
                                <!-- Custom System Field -->
    
                            </ng-container>
                        </ng-container>
                    </ng-template>
                              

                    <div *ngIf="page && page.uiProperties && page.uiProperties.pageEndHtml" class="wizard-mode container-fluid endHtml"
                        [innerHTML]="getSanitisedHtml(page.uiProperties.pageEndHtml)"></div>

                    <div class="col-md-12 no-horizontal-padding custom-footer-buttons">
                        <button *ngIf="!firstPage" style="float: left"
                            class="btn btn-secondary btn-with-icon margin-top-15 wizard-back-button" type="button" 
                            (click)="navigateWizard(-1)">
                            <img src="assets/faIcons/fa-arrow-left.svg" height="12" />&nbsp;Back</button>

                        <button *ngIf="!lastPage" style="float: right"
                            class="btn btn-secondary btn-with-icon margin-left-15 margin-top-15 wizard-next-button" type="button"
                            (click)="navigateWizard(1)">
                            Next&nbsp;<img src="assets/faIcons/fa-arrow-right.svg" height="12" /></button>

                        <button *ngIf="lastPage && checkForSubmit()" style="float: right"
                            class="btn btn-success btn-with-icon margin-left-15 margin-top-15 wizard-submit-button" type="button"
                            (click)="saveEntity('submit')">
                            <img src="assets/faIcons/fa-check.svg" height="12" />&nbsp;{{ getButtonLabel('Submit') }}</button>

                        <button *ngIf="lastPage && checkForCreate()" style="float: right"
                            class="btn btn-primary btn-with-icon margin-top-15 wizard-save-button" type="button"
                            (click)="saveEntity('save')">
                            <img src="assets/faIcons/fa-save.svg" height="12" />&nbsp;{{ getButtonLabel('Save') }}</button>
                        <button *ngIf="lastPage && checkForUpdate()" style="float: right"
                            class="btn btn-primary btn-with-icon margin-top-15 wizard-save-button" type="button"
                            (click)="saveEntity('save')">
                            <img src="assets/faIcons/fa-save.svg" height="12" />&nbsp;{{ getButtonLabel('Save') }}</button>
                    </div>
                </div>
            </form>
        </aw-wizard-step>
    </aw-wizard>

    <form *ngIf="!arePagesPresent()" [formGroup]="form" class="root-form">
        <div class="row no-horizontal-margin root-form-inner-div">
            <ng-container *ngIf="selectedDataModel.groups && selectedDataModel.groups.length>0; else noGroupsPresentForNoPagesTemplate">
                <!-- Display Field that do not have groups first  -->
                <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                    <ng-container *ngIf="!field.groupName">
                        <ng-container class="field-container" *ngIf="conditionForField(field)">
        
                            <!-- Custom Text Field -->
                            <app-text *ngIf="field.type == 'TEXT'" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-text>
                            <!-- Custom Text Field -->
        
                            <!-- Custom Text Field -->
                            <app-text-area *ngIf="field.type == 'TEXTAREA'" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text-area>
                            <!-- Custom Text Field -->
        
                            <!-- Custom Password Field -->
                            <app-password *ngIf="field.type == 'PASSWORD' || field.type == 'SECURED'" [parentFormGroup]="form"
                                [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()">
                            </app-password>
                            <!-- Custom Password Field -->
        
                            <!-- Custom Number Field -->
                            <app-number *ngIf="field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT'"
                                [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                (onRendered)="onFieldRendered()"></app-number>
                            <!-- Custom Number Field -->
        
                            <!-- Custom Checkbox Field -->
                            <app-checkbox *ngIf="field.type == 'CHECKBOX'" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-checkbox>
                            <!-- Custom Checkbox Field -->
        
                            <!-- Dyanamic Table Field -->
                            <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                (onRendered)="onFieldRendered()">
                            </app-dynamic-table>
                            <!-- Dyanamic Table Field -->
        
                            <!-- Custom Select Field -->
                            <app-select
                                *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE','USER','GROUP','STATUS','PRIORITY'].includes(field.type)"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModelName]="selectedDataModel.name" [fields]="selectedDataModel.fields"
                                [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                (onRendered)="onFieldRendered()">
                            </app-select>
                            <!-- Custom Select Field -->
        
                            <!-- Custom Date Field -->
                            <app-date *ngIf="field.type == 'DATE' || field.type == 'DATETIME'" [parentFormGroup]="form"
                                [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-date>
                            <!-- Custom Date Field -->
        
                            <!-- Custom File Field -->
                            <app-file
                                *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                                (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" [viewEntity]="viewEntity" (onRendered)="onFieldRendered()">
                            </app-file>
                            <!-- Custom File Field -->
        
                            <!-- Custom Video Upload Field -->
                            <app-video-upload *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                                (onFileUpload)="onFileUpload()" [viewEntity]="viewEntity" (onFileRemoved)="onFileRemoved()"
                                (onRendered)="onFieldRendered()">
                            </app-video-upload>
                            <!-- Custom Video Upload Field -->
        
                            <!-- Custom Model Field -->
                            <ng-container *ngIf="viewEntity; else elseNoViewEntityTemplate">
                                <app-model *ngIf="field.type == 'MODEL'" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                                (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                (onRendered)="onFieldRendered()">
                            </app-model>
                            </ng-container>
                            <ng-template #elseNoViewEntityTemplate>
                                <app-model *ngIf="field.type == 'MODEL'" [parentFormGroup]="form" [field]="field"
                                    [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [parentViewEntity]="viewEntity"
                                    (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                    (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                    (onRendered)="onFieldRendered()">
                                </app-model>
                            </ng-template>
                            
                            
                            <!-- Custom Model Field -->
        
                            <!-- Custom Html Viewer Field -->
                            <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel" [viewEntity]="viewEntity" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()" (onSignatureUpload)="onSignatureUpload($event, field)"></app-html-viewer>
                            <!-- Custom Html Viewer Field -->
        
                            <!-- Custom Rating Field -->
                            <autopi-rating-comp *ngIf="field.type == 'RATING'"
                                [field]="field"
                                (clicked)="field.value=$event"
                                [selectedValue]="field.value" 
                                [(ngModel)]="field.value" 
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" 
                                [id]="field.name"
                                [name]="field.name"
                                [ngModelOptions]="{standalone: true}" ngDefaultControl
                                [currentWizardStep]="getCurrentWizardStep()" 
                                [parentFormGroup]="form"
                                [errorMap]="errorMap" 
                                [warningMap]="warningMap" 
                                [highlightErrors]="highlightErrors"
                                (onRendered)="onFieldRendered()"></autopi-rating-comp>
                            <!-- Custom Rating Field -->
        
                            <!-- Custom Text Field -->
                            <app-button *ngIf="field.type == 'BUTTON'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()" (onClick)="onButtonClick(field)"></app-button>
                            <!-- Custom Text Field -->
                            
                        </ng-container>
                    </ng-container>
                </ng-container>
                <!-- Display Fields that have group names as per groups order -->
                <ng-container *ngFor="let group of selectedDataModel.groups | orderBy:'sortOrder'; let grpIndex = index; let grouIndex=index">
                    <ng-container *ngIf="areFieldsPresentinGroup(group,selectedDataModel.fields) > 0">
                        <ng-container *ngIf="group.uiProperties.accordionFlag == true; else nonCollapsedTemplate">
                            <legend class="col-md-12" (click)="toggleCollapse(group)">
                                <div class="fieldsetLabel">{{group.label}}
                                    <button class="btn collapse-pull-right" *ngIf="group.uiProperties.iscollapsed == true"><i class="fa fa-chevron-up"></i></button>
                                    <button class="btn collapse-pull-right" *ngIf="group.uiProperties.iscollapsed == false"><i class="fa fa-chevron-down"></i></button>
                                </div>
                            </legend> 
                        </ng-container>
                        <ng-template #nonCollapsedTemplate>
                            <legend class="col-md-12">
                                <div class="fieldsetLabel">{{group.label}}</div>
                            </legend> 
                        </ng-template>
                        <fieldset  [ngClass]="{'collapse': group.uiProperties.iscollapsed == true}" [class]="'col-md-12 groupedFields'+ grpIndex">
                            <div class="row">
                                <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                                    <ng-container *ngIf="group.name == field.groupName">
                                        <ng-container class="field-container" *ngIf="conditionForField(field)">
                        
                                            <!-- Custom Text Field -->
                                            <app-text *ngIf="field.type == 'TEXT'" [parentFormGroup]="form" [field]="field"
                                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-text>
                                            <!-- Custom Text Field -->
                        
                                            <!-- Custom Text Field -->
                                            <app-text-area *ngIf="field.type == 'TEXTAREA'" [parentFormGroup]="form" [field]="field"
                                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text-area>
                                            <!-- Custom Text Field -->
                        
                                            <!-- Custom Password Field -->
                                            <app-password *ngIf="field.type == 'PASSWORD' || field.type == 'SECURED'" [parentFormGroup]="form"
                                                [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()">
                                            </app-password>
                                            <!-- Custom Password Field -->
                        
                                            <!-- Custom Number Field -->
                                            <app-number *ngIf="field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT'"
                                                [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                                (onRendered)="onFieldRendered()"></app-number>
                                            <!-- Custom Number Field -->
                        
                                            <!-- Custom Checkbox Field -->
                                            <app-checkbox *ngIf="field.type == 'CHECKBOX'" [parentFormGroup]="form" [field]="field"
                                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-checkbox>
                                            <!-- Custom Checkbox Field -->
                        
                                            <!-- Dyanamic Table Field -->
                                            <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field"
                                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                (onRendered)="onFieldRendered()">
                                            </app-dynamic-table>
                                            <!-- Dyanamic Table Field -->
                        
                                            <!-- Custom Select Field -->
                                            <app-select
                                                *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE','USER','GROUP','STATUS','PRIORITY'].includes(field.type)"
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                                [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModelName]="selectedDataModel.name" [fields]="selectedDataModel.fields"
                                                [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                                (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                                (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                                (onRendered)="onFieldRendered()">
                                            </app-select>
                                            <!-- Custom Select Field -->
                        
                                            <!-- Custom Date Field -->
                                            <app-date *ngIf="field.type == 'DATE' || field.type == 'DATETIME'" [parentFormGroup]="form"
                                                [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-date>
                                            <!-- Custom Date Field -->
                        
                                            <!-- Custom File Field -->
                                            <app-file
                                                *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                                [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                                                (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" [viewEntity]="viewEntity" (onRendered)="onFieldRendered()">
                                            </app-file>
                                            <!-- Custom File Field -->
                        
                                            <!-- Custom Video Upload Field -->
                                            <app-video-upload *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                                [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                                                (onFileUpload)="onFileUpload()" [viewEntity]="viewEntity" (onFileRemoved)="onFileRemoved()"
                                                (onRendered)="onFieldRendered()">
                                            </app-video-upload>
                                            <!-- Custom Video Upload Field -->
                        
                                            <!-- Custom Model Field -->
                                            <ng-container *ngIf="viewEntity; else elseNoViewEntityTemplate">
                                                <app-model *ngIf="field.type == 'MODEL'" [parentFormGroup]="form" [field]="field"
                                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                                                [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                                                (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                                (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                                (onRendered)="onFieldRendered()">
                                            </app-model>
                                            </ng-container>
                                            <ng-template #elseNoViewEntityTemplate>
                                                <app-model *ngIf="field.type == 'MODEL'" [parentFormGroup]="form" [field]="field"
                                                    [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [parentViewEntity]="viewEntity"
                                                    (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                                    (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                                    (onRendered)="onFieldRendered()">
                                                </app-model>
                                            </ng-template>
                                            
                                            
                                            <!-- Custom Model Field -->
                        
                                            <!-- Custom Html Viewer Field -->
                                            
                                            
                                            <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel" [viewEntity]="viewEntity" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()" (onSignatureUpload)="onSignatureUpload($event, field)"></app-html-viewer>
                                            <!-- Custom Html Viewer Field -->
                        
                                            <!-- Custom Rating Field -->
                                            <autopi-rating-comp *ngIf="field.type == 'RATING'"
                                                [field]="field"
                                                (clicked)="field.value=$event"
                                                [selectedValue]="field.value" 
                                                [(ngModel)]="field.value" 
                                                [class]="getFieldClass(field, 'col-md-4 mt-2')" 
                                                [id]="field.name"
                                                [name]="field.name"
                                                [ngModelOptions]="{standalone: true}" ngDefaultControl
                                                [currentWizardStep]="getCurrentWizardStep()" 
                                                [parentFormGroup]="form"
                                                [errorMap]="errorMap" 
                                                [warningMap]="warningMap" 
                                                [highlightErrors]="highlightErrors"
                                                (onRendered)="onFieldRendered()"></autopi-rating-comp>
                                            <!-- Custom Rating Field -->
                        
                                            <!-- Custom Text Field -->
                                            <app-button *ngIf="field.type == 'BUTTON'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()" (onClick)="onButtonClick(field)"></app-button>
                                            <!-- Custom Text Field -->
                                            
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </fieldset>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #noGroupsPresentForNoPagesTemplate>
                <!-- No Groups Present -->
                <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                    <ng-container class="field-container" *ngIf="conditionForField(field)">
    
                        <!-- Custom Text Field -->
                        <app-text *ngIf="field.type == 'TEXT'" [parentFormGroup]="form" [field]="field"
                            [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-text>
                        <!-- Custom Text Field -->
    
                        <!-- Custom Text Field -->
                        <app-text-area *ngIf="field.type == 'TEXTAREA'" [parentFormGroup]="form" [field]="field"
                            [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                            [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text-area>
                        <!-- Custom Text Field -->
    
                        <!-- Custom Password Field -->
                        <app-password *ngIf="field.type == 'PASSWORD' || field.type == 'SECURED'" [parentFormGroup]="form"
                            [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()">
                        </app-password>
                        <!-- Custom Password Field -->
    
                        <!-- Custom Number Field -->
                        <app-number *ngIf="field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT'"
                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                            [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                            (onRendered)="onFieldRendered()"></app-number>
                        <!-- Custom Number Field -->
    
                        <!-- Custom Checkbox Field -->
                        <app-checkbox *ngIf="field.type == 'CHECKBOX'" [parentFormGroup]="form" [field]="field"
                            [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-checkbox>
                        <!-- Custom Checkbox Field -->
    
                        <!-- Dyanamic Table Field -->
                        <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field"
                            [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                            (onRendered)="onFieldRendered()">
                        </app-dynamic-table>
                        <!-- Dyanamic Table Field -->
    
                        <!-- Custom Select Field -->
                        <app-select
                            *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE','USER','GROUP','STATUS','PRIORITY'].includes(field.type)"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                            [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModelName]="selectedDataModel.name" [fields]="selectedDataModel.fields"
                            [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                            (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                            (onRendered)="onFieldRendered()">
                        </app-select>
                        <!-- Custom Select Field -->
    
                        <!-- Custom Date Field -->
                        <app-date *ngIf="field.type == 'DATE' || field.type == 'DATETIME'" [parentFormGroup]="form"
                            [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-date>
                        <!-- Custom Date Field -->
    
                        <!-- Custom File Field -->
                        <app-file
                            *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                            [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                            (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" [viewEntity]="viewEntity" (onRendered)="onFieldRendered()">
                        </app-file>
                        <!-- Custom File Field -->
    
                        <!-- Custom Video Upload Field -->
                        <app-video-upload *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                            [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel"
                            (onFileUpload)="onFileUpload()" [viewEntity]="viewEntity" (onFileRemoved)="onFileRemoved()"
                            (onRendered)="onFieldRendered()">
                        </app-video-upload>
                        <!-- Custom Video Upload Field -->
    
                        <!-- Custom Model Field -->
                        <ng-container *ngIf="viewEntity; else elseNoViewEntityTemplate">
                            <app-model *ngIf="field.type == 'MODEL'" [parentFormGroup]="form" [field]="field"
                            [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
                            [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                            (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                            (onRendered)="onFieldRendered()">
                        </app-model>
                        </ng-container>
                        <ng-template #elseNoViewEntityTemplate>
                            <app-model *ngIf="field.type == 'MODEL'" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [parentViewEntity]="viewEntity"
                                (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                (onRendered)="onFieldRendered()">
                            </app-model>
                        </ng-template>
                        
                        
                        <!-- Custom Model Field -->
    
                        <!-- Custom Html Viewer Field -->
                        <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [dataModel]="selectedDataModel" [viewEntity]="viewEntity" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()" (onSignatureUpload)="onSignatureUpload($event, field)"></app-html-viewer>
                        <!-- Custom Html Viewer Field -->
    
                        <!-- Custom Rating Field -->
                        <autopi-rating-comp *ngIf="field.type == 'RATING'"
                            [field]="field"
                            (clicked)="field.value=$event"
                            [selectedValue]="field.value" 
                            [(ngModel)]="field.value" 
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" 
                            [id]="field.name"
                            [name]="field.name"
                            [ngModelOptions]="{standalone: true}" ngDefaultControl
                            [currentWizardStep]="getCurrentWizardStep()" 
                            [parentFormGroup]="form"
                            [errorMap]="errorMap" 
                            [warningMap]="warningMap" 
                            [highlightErrors]="highlightErrors"
                            (onRendered)="onFieldRendered()"></autopi-rating-comp>
                        <!-- Custom Rating Field -->
    
                        <!-- Custom Text Field -->
                        <app-button *ngIf="field.type == 'BUTTON'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()" (onClick)="onButtonClick(field)"></app-button>
                        <!-- Custom Text Field -->
                        
                    </ng-container>
                </ng-container>
            </ng-template>
            
            

            <div *ngIf="!innerForm" class="col-md-12 no-horizontal-padding text-right custom-footer-buttons">
                <button type="button" *ngIf="checkForCreate()" (click)="saveEntity('save')"
                    class="btn btn-primary btn-with-icon margin-top-15 form-save-button">
                    <img src="assets/faIcons/fa-save.svg" height="12" />&nbsp;{{ getButtonLabel('Create') }}</button>
                <button type="button" *ngIf="checkForUpdate()" (click)="saveEntity('save')"
                    class="btn btn-primary btn-with-icon margin-top-15 form-save-button">
                    <img src="assets/faIcons/fa-save.svg" height="12" />&nbsp;{{ getButtonLabel('Save') }}</button>
                <button type="button" *ngIf="checkForSubmit()" (click)="saveEntity('submit')"
                    class="btn btn-success btn-with-icon margin-left-15 margin-top-15 form-submit-button">
                    <img src="assets/faIcons/fa-check.svg" height="12" />&nbsp;{{ getButtonLabel('Submit') }}</button>
            </div>

            <div *ngIf="innerForm && !viewingNestedEntity && uiRendered" class="col-md-12 text-right custom-modal-footer-buttons">
                <button class="btn btn-primary margin-top-15 form-done-button" (click)="closeDialogSave()">
                    {{ getButtonLabel('Done') }}
                </button>
                <button class="btn btn-warning margin-left-15 margin-top-15 form-close-button" *ngIf="!addNew" (click)="closeDialog()">
                    {{ getButtonLabel('Close') }}
                </button>
            </div>
        </div>
    </form>
</div>