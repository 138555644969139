<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-view-label', 'fieldTitle')">
        Parent:
    </label>

    <br>

    <ng-container *ngIf="field.value && getEntityDetails(field.value); else viewModeParentNotSelected">
        <!-- <ng-container *ngIf="getEntityDetails(field.value)['0']['value']">
            <b>{{ getEntityDetails(field.value)['0']['value'] }}</b>
        </ng-container>
        <ng-container *ngIf="getEntityDetails(field.value)['1']['value']">
            <br>{{ getEntityDetails(field.value)['1']['value'] }}
        </ng-container>
        <ng-container *ngIf="getEntityDetails(field.value)['2']['value']">
            <br>{{ getEntityDetails(field.value)['2']['value'] }}
        </ng-container> -->
        {{ getEntityDetails(field.value) }}
    </ng-container>
    <ng-template #viewModeParentNotSelected>
        <span>Not Selected</span>
    </ng-template>
</div>

<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        {{field.label}}:
    </label>

    <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
        <img src="assets/faIcons/fa-info-circle.svg" height="12" />
    </span>

    <span *ngIf="showRemoveButton(field)" class="pull-right clsBtnSelect" (click)="removeSelectValue(field)">
        <img src="assets/faIcons/fa-times.svg" height="12" />
    </span>

    <mat-select
        [class]="getFieldClass(field, '-value', matSelectClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        (selectionChange)="onParentSelect($event, field)"
        [compareWith]="compareObjects"
        [formControlName]="field.name">
        <mat-select-trigger>
            <ng-container *ngIf="field.value; else parentNotSelected">
                <ng-container *ngIf="getEntityDetails(field.value); else parentDetailsAbsent">
                    <!-- <ng-container *ngIf="getEntityDetails(field.value)['0']['value']">
                        <b>{{ getEntityDetails(field.value)['0']['value'] }}</b>
                    </ng-container>
                    <ng-container *ngIf="getEntityDetails(field.value)['1']['value']">
                        <br>{{ getEntityDetails(field.value)['1']['value'] }}
                    </ng-container>
                    <ng-container *ngIf="getEntityDetails(field.value)['2']['value']">
                        <br>{{ getEntityDetails(field.value)['2']['value'] }}
                    </ng-container> -->
                    {{ getEntityDetails(field.value) }}
                </ng-container>
                <ng-template #parentDetailsAbsent>
                    {{ field.value['_id'] }}
                </ng-template>
            </ng-container>
            <ng-template #parentNotSelected>
                Please Select
            </ng-template>
        </mat-select-trigger>
        <mat-option [value]="undefined" disabled>
            Please Select
        </mat-option>
        <mat-option *ngFor="let parentEntity of parentEntityList" [value]="parentEntity['_id']">
            <ng-container *ngIf="parentEntity && getEntityDetails(parentEntity['_id']); else dropdownParentDetailsAbsent">
                <!-- <ng-container *ngIf="getEntityDetails(parentEntity['_id'])['0']['value']">
                    <b>{{ getEntityDetails(parentEntity['_id'])['0']['value'] }}</b>
                </ng-container>
                <ng-container *ngIf="getEntityDetails(parentEntity['_id'])['1']['value']">
                    <br>{{ getEntityDetails(parentEntity['_id'])['1']['value'] }}
                </ng-container>
                <ng-container *ngIf="getEntityDetails(parentEntity['_id'])['2']['value']">
                    <br>{{ getEntityDetails(parentEntity['_id'])['2']['value'] }}
                </ng-container> -->
                {{ getEntityDetails(parentEntity['_id']) }}
            </ng-container>
            <ng-template #dropdownParentDetailsAbsent>
                {{ parentEntity['_id'] }}
            </ng-template>
        </mat-option>
    </mat-select>

    <!-- <i *ngIf="field.value && field.viewEntity"
        class="fa fa-eye pull-right" (click)="openEntityUpdateModal(field.value,undefined,true)">
    </i> -->
</div>