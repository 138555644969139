import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { EnvironmentData } from 'src/app/services/environments.service';
import { FieldActions } from '../models/constants';
import { AuthService, FieldOnChangeService } from './shared.service';
import { DataModelService } from './data-model.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./shared.service";
import * as i3 from "ngx-toastr";
import * as i4 from "./environments.service";
import * as i5 from "./data-model.service";
export class APIService {
    constructor(httpClient, authService, toastrService, fieldOnChangeService, environmentData, dataModelService) {
        this.httpClient = httpClient;
        this.authService = authService;
        this.toastrService = toastrService;
        this.fieldOnChangeService = fieldOnChangeService;
        this.environmentData = environmentData;
        this.dataModelService = dataModelService;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
    clearAll() {
        this.dataModelId = null;
        this.entityId = null;
    }
    setDataModel(dataModel) {
        this.dataModel = dataModel;
        this.dataModelName = dataModel.name;
    }
    getDataModel() {
        return this.dataModel;
    }
    setDataModelId(dataModelId) {
        this.dataModelId = dataModelId;
    }
    setEntityId(entityId) {
        this.entityId = entityId;
    }
    setEntity(entity) {
        this.entity = entity;
    }
    getEntity() {
        return this.entity;
    }
    triggerApiOrRouteOrMvel(apiOrRouteOrMvelDataModel, payload, isMvelExecution, metadata) {
        if (apiOrRouteOrMvelDataModel && payload) {
            payload['dataModelId'] = this.dataModelId;
            payload['entityId'] = this.entityId;
            payload['entityName'] = this.dataModelName;
            let tmpFieldArr = [];
            if (metadata && metadata.dataModelFieldMap.size != 0) {
                tmpFieldArr = this.flattenDataModelField(metadata.dataModelFieldMap);
                // for (let [key, value] of metadata.dataModelFieldMap) {
                //     if (value.value) {
                //         let tmpField = {};
                //         tmpField[key+""] = value.value;
                //         tmpFieldArr.push(tmpField)
                //     }
                // }
                payload['pageFields'] = tmpFieldArr;
            }
            let obseravable = null;
            if (isMvelExecution) {
                obseravable = this.evaluateMvel(apiOrRouteOrMvelDataModel, payload);
            }
            else {
                if (apiOrRouteOrMvelDataModel.startsWith('route:')) {
                    obseravable = this.executeRoute(apiOrRouteOrMvelDataModel.replace('route:', ''), payload);
                }
                else {
                    obseravable = this.executeApi(apiOrRouteOrMvelDataModel, payload);
                }
            }
            if (obseravable && metadata && metadata.isValid()) {
                return this.processResponseAsync(obseravable, metadata);
            }
            else {
                return obseravable;
            }
        }
        return null;
    }
    flattenDataModelField(dataModelFieldMap) {
        let tmpFieldArr = [];
        dataModelFieldMap.forEach((field, key) => {
            if (field) {
                let tmpField = {};
                let flag = false;
                if (field["type"] && field["type"] === "MODEL" && field["listvalue"] && field["listvalue"].length > 0) {
                    let listVals = [];
                    for (let listValInd = 0; listValInd < field["listvalue"].length; listValInd++) {
                        const listvalueitem = field["listvalue"][listValInd];
                        if (listvalueitem && listvalueitem["fields"] && listvalueitem["fields"].length > 0) {
                            let tmpFieldArrForModel = this.flattenDataModelField(listvalueitem["fields"]);
                            listVals.push(tmpFieldArrForModel);
                            flag = true;
                        }
                    }
                    tmpField[key + ""] = listVals;
                }
                else if (field["type"] && field["type"] === "MODEL" && !field["listvalue"]) {
                    if (field["fields"] && field["fields"].length > 0) {
                        let tmpFieldArrForModel = this.flattenDataModelField(field["fields"]);
                        tmpField[key + ""] = tmpFieldArrForModel;
                        flag = true;
                    }
                    else {
                        this.dataModelService.getDataModelByName(field.modelName).subscribe(response => {
                            field['fields'] = response.fields;
                            let tmpFieldArrForModel = this.flattenDataModelField(field["fields"]);
                            tmpField[key + ""] = tmpFieldArrForModel;
                            flag = true;
                        }, error => { });
                    }
                }
                else {
                    if (field && field["value"]) {
                        tmpField[key + ""] = field["value"];
                        flag = true;
                    }
                }
                if (flag) {
                    tmpFieldArr.push(tmpField);
                }
            }
        });
        return tmpFieldArr;
    }
    executeApi(apiName, requestBody) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.apiConfigBaseUrl + "execute/" + envData.apiName;
        ;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, requestBody, {
                observe: 'response',
                reportProgress: true,
                headers: this.httpHeaders
            }).subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    executeRoute(routeCode, requestBody) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.routeBaseUrl + envData.routeUrl + routeCode;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, requestBody, {
                observe: 'response',
                reportProgress: true,
                headers: this.httpHeaders
            }).subscribe((response) => {
                if (response.body) {
                    const responseBody = response.body;
                    if (responseBody['payload'] && responseBody['payload']['successMessage']) {
                        this.toastrService.success(responseBody['payload']['successMessage']);
                    }
                    else if (responseBody['payload'] && responseBody['payload']['errorMessage']) {
                        this.toastrService.error(responseBody['payload']['errorMessage']);
                    }
                    subject.next(responseBody);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    evaluateMvel(entityName, payload) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.evaluateMvel + entityName;
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, payload, {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            })
                .subscribe((response) => {
                if (response.body) {
                    const payload = {
                        'payload': {
                            'fieldControl': response.body
                        }
                    };
                    subject.next(payload);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        });
        return subject.asObservable();
    }
    processResponseAsync(apiResponseObservable, apiMetadata) {
        const intermediateSubject = new Subject();
        if (apiResponseObservable && apiMetadata && apiMetadata.isValid()) {
            apiResponseObservable.subscribe(result => {
                result = this.processResponse(result, apiMetadata);
                intermediateSubject.next(result);
            }, (err) => {
                intermediateSubject.error(err);
            });
        }
        else {
            intermediateSubject.error('Invalid response');
        }
        return intermediateSubject.asObservable();
    }
    processResponse(apiResponse, apiMetadata) {
        if (apiResponse && apiMetadata && apiMetadata.isValid()) {
            const updatedFields = apiResponse['payload'] && apiResponse['payload']['updatedFields'] ? apiResponse['payload']['updatedFields'] : null;
            const fieldControl = apiResponse['payload'] && apiResponse['payload']['fieldControl'] ? apiResponse['payload']['fieldControl'] : null;
            if (updatedFields && Object.keys(updatedFields).length > 0) {
                for (const key of Object.keys(updatedFields)) {
                    if (key && updatedFields[key]) {
                        this.fieldOnChangeService.onChange(key, updatedFields[key], apiMetadata.formGroup);
                    }
                }
                apiResponse[APIService._IS_FORM_VALIDATION_NEEDED] = true;
            }
            else if (fieldControl && Object.keys(fieldControl).length > 0) {
                for (const key of Object.keys(fieldControl)) {
                    if (key && fieldControl[key] && Object.keys(fieldControl[key]).length > 0) {
                        const field = apiMetadata.dataModelFieldMap.get(key);
                        if (field) {
                            for (const action of Object.keys(fieldControl[key])) {
                                if (action && fieldControl[key][action]) {
                                    const actionResult = fieldControl[key][action]['result'];
                                    const actionMessage = fieldControl[key][action]['message'];
                                    if (action == FieldActions.ENABLE.toString()) {
                                        field.disable = !actionResult;
                                    }
                                    else if (action == FieldActions.SHOW.toString()) {
                                        field.hide = !actionResult;
                                    }
                                    else if (action == FieldActions.MANDATORY.toString()) {
                                        field.mandatory = actionResult;
                                        if (actionResult) {
                                            apiMetadata.formGroup.controls[field.name].setValidators(Validators.required);
                                        }
                                        else {
                                            apiMetadata.formGroup.controls[field.name].clearValidators();
                                        }
                                    }
                                    else if (action == FieldActions.ERROR.toString()) {
                                        if (actionResult && actionMessage) {
                                            apiMetadata.errorMap[key] = actionMessage;
                                        }
                                        else {
                                            delete apiMetadata.errorMap[key];
                                        }
                                    }
                                    else if (action == FieldActions.BACKENDERROR.toString()) {
                                        if (actionResult && actionMessage) {
                                            apiMetadata.backendErrorMap[key] = actionMessage;
                                            apiMetadata.errorMap[key] = actionMessage;
                                        }
                                        else {
                                            delete apiMetadata.backendErrorMap[key];
                                        }
                                    }
                                    else if (action == FieldActions.WARNING.toString()) {
                                        if (actionResult && actionMessage) {
                                            apiMetadata.warningMap[key] = actionMessage;
                                        }
                                        else {
                                            delete apiMetadata.warningMap[key];
                                        }
                                    }
                                    else if (action == FieldActions.VALUE.toString()) {
                                        this.fieldOnChangeService.onChange(key, actionResult, apiMetadata.formGroup);
                                    }
                                }
                            }
                        }
                    }
                }
                apiResponse[APIService._IS_FORM_VALIDATION_NEEDED] = true;
            }
        }
        else {
            console.error("Invalid API Metadata to process response");
        }
        return apiResponse;
    }
}
APIService._IS_FORM_VALIDATION_NEEDED = '_isFormValidationNeeded';
APIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function APIService_Factory() { return new APIService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i2.FieldOnChangeService), i0.ɵɵinject(i4.EnvironmentData), i0.ɵɵinject(i5.DataModelService)); }, token: APIService, providedIn: "root" });
export class APIMetadata {
    constructor(formGroup, dataModelFieldMap, errorMap, warningMap, backendErrorMap) {
        this.formGroup = formGroup;
        this.dataModelFieldMap = dataModelFieldMap;
        this.errorMap = errorMap;
        this.backendErrorMap = backendErrorMap;
        this.warningMap = warningMap;
    }
    isValid() {
        return this.formGroup && this.dataModelFieldMap
            && this.errorMap != null && this.errorMap != undefined
            && this.warningMap != null && this.warningMap != undefined;
    }
}
