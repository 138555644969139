import { Component, OnInit, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileService } from 'src/app/services/shared.service';
import { Document } from '../../../models/tasks.model';

@Component({
  selector: 'app-image-pdf-viewer',
  templateUrl: './image-pdf-viewer.component.html',
  styleUrls: ['./image-pdf-viewer.component.scss']
})
export class ImagePdfViewerComponent implements OnInit {
  title: string;
  closeBtnName: string;
  doc: Document | any;
  dataFormat:any;
  downloadFileObjectUrl: any;
  DOCUMENT_STATUS = ["PENDING", "APPROVED", "REJECTED"];
  public event: EventEmitter<any> = new EventEmitter();
  @ViewChild("viewerwrapper",{static:false}) viewerwrapper:ElementRef

  constructor(
    public bsModalRef: BsModalRef,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private _renderer: Renderer2
  ) { }
  loaded:boolean = false
  ngOnInit() {
    if (this.doc && this.doc.downloadFileUrl) {
      if (this.doc.downloadFileUrl.startsWith('http')) {
        this.downloadFileObjectUrl = this.doc.downloadFileUrl
      } else {
        this.fileService.download(this.doc.downloadFileUrl,false, true).subscribe(
          objectUrl => {
            if (objectUrl) {
              var type = (objectUrl.split(';')[0]).replace("data:", "");
              if(type=='video/mp4'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }else if(type=='audio/mpeg'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }
              else if(type=='audio/x-wav'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }
              else if(type=='text/html'){
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  this.downloadFileObjectUrl = blob['text']()
                })
              }
              else if (type == 'application/octet-stream' || type == 'application/pdf') {
                type = "application/pdf";
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  var file = new Blob([blob], {type: type});
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
                })
              }else{
                this.dataFormat = type;
                fetch(objectUrl)
                .then(res => res.blob())
                .then(blob => {
                  let FileNameParts = [];
                  let file;
                  if (this.doc && this.doc.userFileName) {
                    FileNameParts = this.doc.userFileName.split(".");
                    if (FileNameParts.length>1) {
                      file = new File([blob], this.doc.userFileName.replace(/[^a-zA-Z0-9.]/g, ""),{ type: type })
                    } else{
                      file = new File([blob], "temp.jpeg",{ type: type })
                    }
                  } else {
                    FileNameParts = this.doc.fileName.split(".");
                    if (FileNameParts.length>1) {
                      file = new File([blob], this.doc.fileName.replace(/[^a-zA-Z0-9.]/g, ""),{ type: type })
                    } else{
                      file = new File([blob], "temp.jpeg",{ type: type })
                    }
                  }
                  var objectURL = URL.createObjectURL(blob);
                  
                  this.downloadFileObjectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
                })
              }
            }
          }
        );
      }
    }
  }
  updateDocument() {
    this.event.emit({ data: this.doc, res: 200 });
  }
  elementChecker(){
    if(this.viewerwrapper && this.viewerwrapper.nativeElement){
      this._renderer.setStyle(this.viewerwrapper.nativeElement, 'height', '79vh');
      this.loaded = true;
    }
    return this.downloadFileObjectUrl
  }
}
