/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entity-ref-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/expansion";
import * as i3 from "@angular/common";
import * as i4 from "ngx-pagination";
import * as i5 from "../../../../../node_modules/ngx-pagination/dist/ngx-pagination.ngfactory";
import * as i6 from "./entity-ref-modal.component";
import * as i7 from "@angular/material/dialog";
var styles_EntityRefModalComponent = [i0.styles];
var RenderType_EntityRefModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EntityRefModalComponent, data: {} });
export { RenderType_EntityRefModalComponent as RenderType_EntityRefModalComponent };
function View_EntityRefModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "mat-accordion", [["class", "mat-accordion"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEntityUpdateModal(_co.entityRefField, (((_co.p - 1) * 10) + _v.context.index)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.MAT_ACCORDION, null, [i2.MatAccordion]), i1.ɵdid(3, 1720320, null, 1, i2.MatAccordion, [], null, null), i1.ɵqud(603979776, 1, { _headers: 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "mat-panel-title", [["class", "mat-expansion-panel-header-title"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatExpansionPanelTitle, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "strong", [["style", "line-height: 40px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["height", "12"], ["src", "assets/faIcons/fa-chevron-right.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit._lookup_reference_label; _ck(_v, 8, 0, currVal_0); }); }
export function View_EntityRefModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_EntityRefModalComponent_1)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpod(10, { itemsPerPage: 0, currentPage: 1 }), i1.ɵpid(0, i4.PaginatePipe, [i4.PaginationService]), (_l()(), i1.ɵeld(12, 0, null, null, 1, "pagination-controls", [], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.p = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_PaginationControlsComponent_0, i5.RenderType_PaginationControlsComponent)), i1.ɵdid(13, 49152, null, 0, i4.PaginationControlsComponent, [], null, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 11).transform(_co.entityRefField.listvalue, _ck(_v, 10, 0, 10, _co.p))); _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityRefField.label; _ck(_v, 2, 0, currVal_0); }); }
export function View_EntityRefModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-entity-ref-modal", [], null, null, null, View_EntityRefModalComponent_0, RenderType_EntityRefModalComponent)), i1.ɵdid(1, 49152, null, 0, i6.EntityRefModalComponent, [i7.MatDialogRef, i7.MatDialog, i7.MAT_DIALOG_DATA], null, null)], null, null); }
var EntityRefModalComponentNgFactory = i1.ɵccf("app-entity-ref-modal", i6.EntityRefModalComponent, View_EntityRefModalComponent_Host_0, {}, {}, []);
export { EntityRefModalComponentNgFactory as EntityRefModalComponentNgFactory };
