import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';
import { Subscription } from 'rxjs';
import { EntityService } from 'src/app/services/entity.service';

@Component({
  selector: 'app-system-variable-field',
  templateUrl: './system-variable-field.component.html',
  styleUrls: ['./system-variable-field.component.scss']
})
export class SystemVariableComponent extends BaseFieldDirective {
  @Input()
  private datamodelName: string;
  // Removed form-control so that checkbox can be seen properly
  matSelectClass: string = 'mat-select col-md-12 col-sm-12 col-xs-12 form-control';
  userList: any[];
  groupList: any[];
  subscription: Subscription;

  constructor(
    private entityService: EntityService,
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.field && changes.field.isFirstChange() && this.field.type) {
      if (this.field.type == 'USER') {
        this.entityService.getDataModelLookupByType(this.datamodelName,this.field.type,this.field.name).subscribe(
          response=>{
            this.userList = response;
          }
        );
      }
      if (this.field.type == 'GROUP') {
        this.entityService.getDataModelLookupByType(this.datamodelName,this.field.type,this.field.name).subscribe(
          response=>{
            this.groupList = response;
          }
        );
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
