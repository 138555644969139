<div class="width100">
    <div *ngIf="viewMode" class="col-md-12 no-horizontal-padding">
        <ng-container *ngIf="!field.list">
            <label [attr.for]="field.name" [class]="getFieldClass(field, '-view-label', 'fieldTitle')" style="line-height: 40px;">
                {{field.label}}:
            </label>

            <br>

            <ng-container *ngIf="field.value">
                <a [class]="getFieldClass(field, '-view-value')" href="javascript:void(0);" *ngIf="field.value.viewEntity" (click)="openEntityUpdateModal(field.value)">
                    Show Details
                </a>
                <a [class]="getFieldClass(field, '-view-value')" href="javascript:void(0);" *ngIf="!field.value.viewEntity" (click)="openEntityUpdateModal(field)">
                    Show Details
                </a>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="field.list">
            <ng-container *ngIf="!field.displayAsCarousel">
                <label [attr.for]="field.name" [class]="getFieldClass(field, '-view-label', 'fieldTitle')" style="line-height: 40px;">
                    {{field.label}}:
                </label>

                <br>

                <div class="table-responsive responsive" *ngIf="field.value">
                    <table class="table" *ngIf="checkTableColumns()">
                        <thead>
                            <tr>
                                <ng-container *ngFor="let columnData of tableColumns">
                                    <th>{{ columnData.label }}</th>
                                </ng-container>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tableRow of tableData | paginate: { itemsPerPage: pageSize, currentPage: currentPage, id: 'field_' + fieldUniqueIdentifier }; let recordIndex = index">
                                <td *ngFor="let columnData of tableColumns; let columnIndex = index">
                                    {{ tableRow[columnIndex] }}
                                </td>
                                <td>
                                    <button class="btn btn-sm std-icons" (click)="openEntityUpdateModal(field, (currentPage - 1) * pageSize + recordIndex)">
                                        <img src="assets/faIcons/fa-eye.svg" height="12" /> View
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="currentPage = $event" [id]="'field_' + fieldUniqueIdentifier"></pagination-controls>
                </div>
            </ng-container>

            <div *ngIf="field.value && field.value.length > 0 && field.displayAsCarousel"
                [class]="getFieldClass(field, '-view-carousel', 'row col-md-12 no-horizontal-margin')" style="align-items: center;">
                <div [class]="getFieldClass(field, '-view-carousel-back-button', 'carousel-left-button')" (click)="previousStep()" *ngIf="!isFirstStep()">
                    <img src="assets/faIcons/fa-arrow-left.svg" height="12" />
                </div>
                <ng-container *ngFor="let item of field.value; let i = index; let firstIndex = first; let lastIndex = last">
                    <app-view-mode *ngIf="getDefaultStepIndex() == i" [class]="getFieldClass(field, '-view-carousel-item', 'carousel-body mt-2 border-bottom bgColour')"
                        [model]="field.value[getDefaultStepIndex()]" [viewEntity]="viewEntity[getDefaultStepIndex()]"
                        [innerForm]="true" (onNestedEntitySave)="saveNestedEntity()">
                    </app-view-mode>
                </ng-container>
                <div [class]="getFieldClass(field, '-view-carousel-back-button', 'carousel-right-button')" (click)="nextStep()" *ngIf="!isLastStep()">
                    <img src="assets/faIcons/fa-arrow-right.svg" height="12" />
                </div>
            </div>
        </ng-container>
    </div>

    <div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
        <strong [class]="getFieldClass(field, '-label')" style="line-height: 40px; padding-left: 15px;">
            <ng-container *ngIf="!isValid(field)">
                <strong style="color: red;">*</strong>
            </ng-container>
            {{field.label}}
        </strong>

        <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
            <img src="assets/faIcons/fa-info-circle.svg" height="12" />
        </span>

        <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
            {{ field.label }} is mandatory
        </span>

        <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
            {{ validateRegex(field) }}
        </span>

        <span *ngIf="errorMap[field.name]" class="text-danger">
            {{errorMap[field.name]}}
        </span>

        <span *ngIf="warningMap[field.name]" class="text-warning">
            <strong>Warning: {{warningMap[field.name]}}</strong>
        </span>

        <ng-container *ngIf="field.list; else notListTemplate">
            <button class="btn btn-sm std-icons pull-right-table-button" (click)="addModel(field)">
                <img src="assets/faIcons/fa-plus.svg" height="12" /> Add
            </button>
            <div class="table-responsive responsive">
                <table class="table" *ngIf="checkTableColumns()">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let columnData of tableColumns">
                                <th>{{ columnData.label }}</th>
                            </ng-container>
                            <th *ngIf="!fromRoute">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tableRow of tableData | paginate: { itemsPerPage: pageSize, currentPage: currentPage, id: 'field_' + fieldUniqueIdentifier }; let recordIndex = index">
                            <td *ngFor="let columnData of tableColumns; let columnIndex = index">
                                <ng-container *ngIf="columnData.type == 'FILE'; else elseCheckForFIleTemplate">
                                    <div [innerHTML]="tableRow[columnIndex]"></div>
                                </ng-container>
                                <ng-template #elseCheckForFIleTemplate>
                                    {{ tableRow[columnIndex] }}
                                </ng-template>
                                
                                
                            </td>
                            <td *ngIf="!fromRoute">
                                <button class="btn btn-sm std-icons" (click)="openEntityUpdateModal(field, (currentPage - 1) * pageSize + recordIndex)">
                                    <img src="assets/faIcons/fa-eye.svg" height="12" /> View
                                </button>
                                <span class="btn btn-sm std-icons" (click)="removeModel(field, (currentPage - 1) * pageSize + recordIndex)">
                                    <img src="assets/faIcons/fa-minus.svg" height="12" /> Remove
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls (pageChange)="currentPage = $event" [id]="'field_' + fieldUniqueIdentifier"></pagination-controls>
            </div>
        </ng-container>

        <ng-template #notListTemplate>
            <ng-container *ngIf="field.value && field.value.viewEntity == true; else notViewTemplate">
                <mat-accordion class="completeAccordionClickableArea" (click)="openEntityUpdateModal(field.value)">
                    <mat-panel-title>
                        <strong style="line-height: 40px;"> <img src="assets/faIcons/fa-chevron-right.svg" height="12" /></strong>
                    </mat-panel-title>
                </mat-accordion>
            </ng-container>
            <ng-template #notViewTemplate>
                <mat-accordion class="completeAccordionClickableArea" (click)="openEntityUpdateModal(field)">
                    <mat-panel-title>
                        <strong style="line-height: 40px;"> <img src="assets/faIcons/fa-chevron-right.svg" height="12" /></strong>
                    </mat-panel-title>
                </mat-accordion>
            </ng-template>
        </ng-template>
    </div>
</div>