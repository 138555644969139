<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" class="fieldTitle view-label">
        {{field.label}}:
    </label>
    <br>
    <label *ngIf="field.value" class="view-value">
        <ng-container *ngFor="let line of getViewModeValue(); let lastLine = last" [attr.class]="getFieldClass(field, '-view-value')">
            {{line}}<br *ngIf="!last">
        </ng-container>
    </label>
</div>

<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    

    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
        <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
            <img src="assets/faIcons/fa-info-circle.svg" height="12" />
        </span>
    </label>

    

    <textarea *ngIf="!isMaskPresent()"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        rows="3"
        [placeholder]="field.placeholder"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name">
    </textarea>

    <textarea *ngIf="isMaskPresent()"
        [class]="getFieldClass(field, '-value', defaultClass)"
        [id]="field.name"
        [(ngModel)]="field.value"
        rows="3"
        [placeholder]="field.placeholder"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [mask]="field.mask"
        [formControlName]="field.name">
    </textarea>
    
    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>
    
    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>