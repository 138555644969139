import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AccessControlResponse } from '../models/accessControleResponse.model';
import { EnvironmentData } from "./environments.service";
import { SecuredStorageService } from "./shared.service";

@Injectable({
    providedIn: 'root'
})
export class AccessControlService {


    constructor(
        private httpClient: HttpClient,
        private environmentData: EnvironmentData,
        private storageService: SecuredStorageService
    ) { }

    getAccessControlForEntity(entityName: string): Observable<AccessControlResponse> {
        const subject = new Subject<AccessControlResponse>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + "accesscontrol/access/ENTITY/" + entityName;
        const body = {};
        this.httpClient.post<AccessControlResponse>(
            url,
            body,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        )
            .subscribe(
                (response: HttpResponse<AccessControlResponse>) => {
                    subject.next(response.body);
                    
                },
                (err: HttpErrorResponse) => {
                    // All errors are handled in ErrorInterceptor, no further handling required
                    // Unless any specific action is to be taken on some error

                    subject.error(err);
                }
            );

        return subject.asObservable();
    }
}  