<div class="container-fluid no-horizontal-padding vertical-padding-15 root-container">
  <form [formGroup]="form" class="root-form">
    <div class="row no-horizontal-margin root-form-inner-div">
      <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
        <ng-container class="field-container" *ngIf="conditionForField(field)">
          <!-- Parent Selection Field -->
          <app-select-parent-model *ngIf="selectedDataModel.parentDataModelName && field.type == 'PARENT_ID_SELECTOR'"
            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
            [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')"
            [parentDataModelName]="selectedDataModel.parentDataModelName" [linkageId]="getLinkageId()" (onRendered)="onFieldRendered()"></app-select-parent-model>
          <!-- Parent Selection Field -->

          <!-- Custom Text Field -->
          <app-text *ngIf="field.type == 'TEXT'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-text>
          <!-- Custom Text Field -->

          <!-- Custom Text Field -->
          <app-text-area *ngIf="field.type == 'TEXTAREA'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text-area>
          <!-- Custom Text Field -->

          <!-- Custom Password Field -->
          <app-password *ngIf="field.type == 'PASSWORD' || field.type == 'SECURED'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-password>
          <!-- Custom Password Field -->

          <!-- Custom Number Field -->
          <app-number *ngIf="field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-number>
          <!-- Custom Number Field -->

          <!-- Custom Checkbox Field -->
          <app-checkbox *ngIf="field.type == 'CHECKBOX'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-checkbox>
          <!-- Custom Checkbox Field -->

          <!-- Custom Select Field -->
          <app-select *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE','USER','GROUP','STATUS','PRIORITY'].includes(field.type)" [class]="getFieldClass(field, 'col-md-4 mt-2')"
            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [fields]="selectedDataModel.fields" [dataModelName]="selectedDataModel.name"
            [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView" (onDialogEntityRefOpen)="openDialogEntityRef($event)"
            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)" (onRendered)="onFieldRendered()">
          </app-select>
          <!-- Custom Select Field -->

          <!-- Dyanamic Field -->
          <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'" [class]="getFieldClass(field, 'col-md-4 mt-2')"
            [parentFormGroup]="form" [errorMap]="errorMap" [warningMap]="warningMap"
            [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
          </app-dynamic-table>
          <!-- Dyanamic Field -->

          <!-- Custom Date Field -->
          <app-date *ngIf="field.type == 'DATE' || field.type == 'DATETIME'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()"></app-date>
          <!-- Custom Date Field -->

          <!-- Custom File Field -->
          <app-file *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)" [class]="getFieldClass(field, 'col-md-4 mt-2')" 
            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
            [dataModel]="selectedDataModel" [viewEntity]="viewEntity" (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
          </app-file>
          <!-- Custom File Field -->

          <!-- Custom Video Upload Field -->
          <app-video-upload *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl" [class]="getFieldClass(field, 'col-md-4 mt-2')" 
            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors"
            [dataModel]="selectedDataModel" [viewEntity]="viewEntity" (onFileUpload)="onFileUpload()"
            (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
          </app-video-upload>
          <!-- Custom Video Upload Field -->

          <!-- Custom Model Field -->
          <app-model *ngIf="field.type == 'MODEL'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
            [highlightErrors]="highlightErrors" [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
            (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')" (onRendered)="onFieldRendered()"></app-model>
          <!-- Custom Model Field -->

          <!-- Custom Html Viewer Field -->
          <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [dataModel]="selectedDataModel" [viewEntity]="viewEntity" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-html-viewer>
          <!-- Custom Html Viewer Field -->

          <!-- Custom Text Field -->
          <app-button *ngIf="field.type == 'BUTTON'" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()" (onClick)="onButtonClick(field)"></app-button>
          <!-- Custom Text Field -->

        </ng-container>
      </ng-container>

      <div *ngIf="!innerForm" class="col-md-12 no-horizontal-padding text-right custom-footer-buttons">
        <button type="button" *ngIf="checkForCreate()" (click)="saveEntity('save')"
          class="btn btn-primary btn-with-icon margin-top-15 form-save-button">
          <img src="assets/faIcons/fa-save.svg" height="12" />&nbsp;{{ getButtonLabel('Create') }}</button>
        <button type="button" *ngIf="checkForUpdate()" (click)="saveEntity('save')"
          class="btn btn-primary btn-with-icon margin-top-15 form-save-button">
          <img src="assets/faIcons/fa-save.svg" height="12" />&nbsp;{{ getButtonLabel('Save') }}</button>
        <button type="button" *ngIf="checkForSubmit()" (click)="saveEntity('submit')"
          class="btn btn-success btn-with-icon margin-left-15 margin-top-15 form-submit-button">
          <img src="assets/faIcons/fa-check.svg" height="12" />&nbsp;{{ getButtonLabel('Submit') }}</button>
      </div>

      <div *ngIf="innerForm && !viewingNestedEntity && uiRendered" class="col-md-12 text-right custom-modal-footer-buttons">
        <button class="btn btn-primary margin-top-15 form-done-button" (click)="closeDialogSave()">{{ getButtonLabel('Done') }}</button>
        <button class="btn btn-warning margin-left-15 margin-top-15 form-close-button" (click)="closeDialog()">{{ getButtonLabel('Close') }}</button>
      </div>
    </div>
    <div class="bottomDocker" *ngIf="validationErrorTypes && validationErrorTypes.length>0">
      <div class="buttonPanel">
          <div class="btn-group">
              <ng-container *ngFor="let errorTypes of validationErrorTypes">
                  <button [class]="'btn btn-light add-btn btn-sm type' + errorTypes" (click)="showErrorPanel(errorTypes)"> 
                      <ng-container *ngIf="errorTypes == 'ERROR'">
                          <i class="fa fa-times-circle"></i> Errors
                      </ng-container>
                      <ng-container *ngIf="errorTypes == 'WARNING'">
                          <i class="fa fa-exclamation-circle"></i>  Warnings
                      </ng-container>
                      ({{getErrorCountByType(errorTypes)}}) 
                      <ng-container *ngIf="errorTypes == errPanelName">
                       <i class="fa fa-chevron-down"></i>
                      </ng-container>
                      <ng-container *ngIf="errorTypes != errPanelName">
                          <i class="fa fa-chevron-up"></i>
                         </ng-container>
                  </button>
              </ng-container>
          </div>
          
      </div>
      <div class="errorPanel" [ngClass]="{'hidePanel': errPanelName == ''}">
          <table class="table table-striped errorTable">
              <thead>
                  <tr>
                      <th class="text-center"></th>
                      <th>Message</th>
                  </tr>
              </thead>
              <tbody>
                  <ng-container *ngFor="let err of errorsByType; let errorI=index">
                      <tr>
                          <td align="center">
                              <ng-container *ngIf="err.type == 'ERROR'">
                                  <i class="fa fa-times-circle" style="color: #c71b1b"></i> 
                              </ng-container>
                              <ng-container *ngIf="err.type == 'WARNING'">
                                  <i class="fa fa-exclamation-circle" style="color: #dcd106"></i> 
                              </ng-container>
                          </td>
                          <td>{{err.message}}</td>
                      </tr>
                  </ng-container>
                  
              </tbody>
          </table>
      </div>
  </div>
  </form>

  <div class="col-md-12" *ngIf="linkageModelList && linkageModelList.length > 0">
    <ng-container *ngFor="let linkageModel of linkageModelList">
      <br><br>
      <app-linked-entity-table [id]="linkageModel['_id']" [linkageModel]="linkageModel"></app-linked-entity-table>
    </ng-container>
  </div>
</div>