import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent extends BaseFieldDirective {

  tagInputClass: string = 'col-md-12 col-sm-12 col-xs-12 custom-input-tag-border';

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }
}