import { HttpClient } from '@angular/common/http';
import { Subject } from "rxjs";
import { EnvironmentData } from "./environments.service";
import { SecuredStorageService } from "./shared.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./environments.service";
import * as i3 from "./shared.service";
export class AccessControlService {
    constructor(httpClient, environmentData, storageService) {
        this.httpClient = httpClient;
        this.environmentData = environmentData;
        this.storageService = storageService;
    }
    getAccessControlForEntity(entityName) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + "accesscontrol/access/ENTITY/" + entityName;
        const body = {};
        this.httpClient.post(url, body, {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
        })
            .subscribe((response) => {
            subject.next(response.body);
        }, (err) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
            subject.error(err);
        });
        return subject.asObservable();
    }
}
AccessControlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccessControlService_Factory() { return new AccessControlService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvironmentData), i0.ɵɵinject(i3.SecuredStorageService)); }, token: AccessControlService, providedIn: "root" });
