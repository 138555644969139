import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { DataModel, Field } from 'src/app/models/data-model.model';
import { DataModelService } from 'src/app/services/data-model.service';
import { EntityService } from 'src/app/services/entity.service';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';

@Component({
  selector: 'app-select-parent-model',
  templateUrl: './select-parent-model.component.html',
  styleUrls: ['./select-parent-model.component.scss']
})
export class SelectParentModelComponent extends BaseFieldDirective {

  private readonly _MAX_DATA_POINT_COUNT: number = 3;

  @Input()
  private parentDataModelName: string;

  @Input()
  private linkageId: string;

  // @Output()
  // private onEntityUpdateModalOpen: EventEmitter<any> = new EventEmitter();

  // @Output()
  // private onDialogEntityRefOpen: EventEmitter<any> = new EventEmitter();

  parentDataModel: DataModel;
  parentEntityList: any[];
  parentEntityMap: any;
  parentFieldsToShow: Field[];

  booleanList: boolean[];
  subscription: Subscription;

  matSelectClass: string = 'mat-select col-md-12 col-sm-12 col-xs-12 form-control';

  constructor(
    private entityService: EntityService,
    private dataModelService: DataModelService,
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);

    this.parentEntityList = [];
    this.parentEntityMap = {};
    this.parentFieldsToShow = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.field && changes.field.currentValue && this.field.type) {
      this.fetchParentDataModel(this.parentDataModelName);
    }

    if (changes.linkageId && changes.linkageId.currentValue) {
      this.fetchLinkageModel(changes.linkageId.currentValue);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  showRemoveButton(field: Field): boolean {
    return field.value != null && field.value != undefined;
  }

  fetchLinkageModel(linkageId: string) {
    if (linkageId && linkageId.trim().length > 0) {
      this.dataModelService.getLinkageModelById(linkageId).subscribe(
        linkageModel => {
          if (linkageModel && linkageModel.parentId) {
            this.field.value = linkageModel.parentId;
          }
        }
      );
    }
  }

  fetchParentDataModel(dataModelName: string) {
    this.dataModelService.getDataModelByName(dataModelName).subscribe(
      parentDataModel => {
        if (parentDataModel) {
          this.parentDataModel = parentDataModel;
          this.fetchParentEntities(this.parentDataModelName);

          const allowedFieldTypes = [
            'TEXT',
            'INT',
            'LONG',
            'FLOAT',
            'CHECKBOX',
            'BOOLEAN',
            'SINGLE_SELECT',
            'LOOKUP',
            'DATE',
            'DATETIME'
          ];

          if (this.parentDataModel.fields && this.parentDataModel.fields.length > 0) {
            for (const field of this.parentDataModel.fields) {
              if (!field.list && allowedFieldTypes.includes(field.type)) {
                if (this.parentFieldsToShow.length == 0) {
                  this.parentFieldsToShow.push(field);
                } else {
                  if (field.businessKey) {
                    this.parentFieldsToShow.unshift(field);
                  } else {
                    let insertionIndex = -1;

                    for (let index = 0; index < this.parentFieldsToShow.length; index++) {
                      const selectedField = this.parentFieldsToShow[index];

                      if (!selectedField.businessKey && selectedField.sortOrder > field.sortOrder) {
                        insertionIndex = index;
                        break;
                      }

                      // We only want to show _MAX_DATA_POINT_COUNT fields
                      if (index == this._MAX_DATA_POINT_COUNT) {
                        break;
                      }
                    }

                    if (insertionIndex > -1) {
                      this.parentFieldsToShow.splice(insertionIndex, 0, field);
                    } else {
                      this.parentFieldsToShow.push(field);
                    }
                  }
                }
              }
            }

            if (this.parentFieldsToShow && this.parentFieldsToShow.length > this._MAX_DATA_POINT_COUNT) {
              this.parentFieldsToShow.splice(this._MAX_DATA_POINT_COUNT, this.parentFieldsToShow.length - this._MAX_DATA_POINT_COUNT);
            }
          }
        }
      }
    );
  }

  fetchParentEntities(dataModelName: string, pageNumber?: number, pageSize?: number) {
    if (!pageNumber) {
      pageNumber = 0;
    }

    if (!pageSize) {
      pageSize = 20;
    }

    this.subscription = this.entityService.getEntityList(dataModelName, pageNumber, pageSize).subscribe(
      parentEntityList => {
        if (parentEntityList && parentEntityList.length > 0) {
          if (!this.parentEntityList) {
            this.parentEntityList = [];
          }

          for (const parentEntity of parentEntityList) {
            this.parentEntityList.push(parentEntity);
            this.parentEntityMap[parentEntity['_id']] = parentEntity;
          }

          if (parentEntityList.length == pageSize) {
            this.fetchParentEntities(dataModelName, pageNumber + 1, pageSize);
          }
        }
      }
    );
  }

  // openEntityUpdateModal(item: any, index?: number, viewingNestedEntity?: boolean) {
  //   const event = {
  //     'item': item,
  //     'index': index,
  //     'viewingNestedEntity': viewingNestedEntity
  //   };
  //   this.onEntityUpdateModalOpen.emit(event);
  // }

  // -------------------

  removeSelectValue(field: Field) {
    field.value = undefined;
  }

  onParentSelect(item: any, field: Field) {
    field.value = item.value;
  }

  compareObjects(o1: any, o2: any) {
    return o1 && o2 && o1 == o2;
  }

  getEntityDetails(entityId: any) {
    let parentPreviewData = null;
    let parentPreviewString = '';
    const parentEntity = this.parentEntityMap[entityId];

    if (parentEntity && this.parentFieldsToShow && this.parentFieldsToShow.length > 0) {
      if (!parentPreviewData) {
        parentPreviewData = {};
      }

      for (let index = 0; index < this.parentFieldsToShow.length; index++) {
        const parentField = this.parentFieldsToShow[index];
        if (this.isFieldValueValid(parentField, parentEntity)) {
          parentPreviewData[index] = {
            'name': parentField.name,
            'label': parentField.label,
            'value': this.getDisplayValue(parentField, parentEntity)
          };

          if (!parentPreviewString || parentPreviewString.trim().length == 0) {
            parentPreviewString = parentPreviewData[index]['value'];
          } else {
            parentPreviewString += " | " + parentPreviewData[index]['value'];
          }
        } else {
          parentPreviewData[index] = {};
        }
      }
    }

    // return parentPreviewData;
    return parentPreviewString;
  }

  isFieldValueValid(field: Field, entity: any): boolean {
    return field && entity && field.name && field.label && ![null, undefined].includes(entity[field.name]);
  }

  getDisplayValue(parentField: Field, entity: any) {
    if (parentField && parentField.type && ![null, undefined].includes(parentField.value)) {
      if (['TEXT', 'SINGLE_SELECT', 'LOOKUP'].includes(parentField.type)) {
        return entity[parentField.name];
      } else if (['INT', 'LONG', 'FLOAT'].includes(parentField.type)) {
        return +parseFloat('' + entity[parentField.name]).toFixed(3);
      } else if (['CHECKBOX', 'BOOLEAN'].includes(parentField.type)) {
        return entity[parentField.name] ? 'Yes' : 'No';
      } else if (parentField.type == 'DATE') {
        return new Date(entity[parentField.name]).toLocaleDateString();
      } else if (parentField.type == 'DATETIME') {
        return new Date(entity[parentField.name]).toLocaleString();
      }
    }

    return entity[parentField.name];
  }
}
