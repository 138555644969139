<div class="modal-header" *ngIf="doc">
  <h4 class="modal-title pull-left">{{doc.documentType}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div [ngSwitch]="dataFormat">
        <p *ngSwitchCase="'application/pdf'">
            <ng-container *ngIf="!loaded;">
                Document Loading
            </ng-container>
            <embed #viewerwrapper id="pdfEmbed" frameborder="0" height="100%" style="min-height:80vh" [src]="downloadFileObjectUrl" *ngIf="elementChecker()" 
                width="100%">
        </p>
        <p *ngSwitchCase="'application/octet-stream'">
            <ng-container *ngIf="!loaded;">
                Document Loading
            </ng-container>
            <embed #viewerwrapper frameborder="0" height="100%" style="min-height:80vh" [src]="downloadFileObjectUrl" *ngIf="elementChecker()"
                width="100%">
        </p>
        <p *ngSwitchCase="'audio/x-wav'">
            <audio controls>
                <source [src]="downloadFileObjectUrl" type="audio/x-wav" *ngIf="downloadFileObjectUrl">
                Your browser does not support the audio tag.
            </audio>
        </p>
        <p *ngSwitchCase="'audio/mpeg'">
            <audio controls>
                <source [src]="downloadFileObjectUrl" type="audio/mpeg" *ngIf="downloadFileObjectUrl">
                Your browser does not support the audio tag.
            </audio>
        </p>
        <p *ngSwitchCase="'video/mp4'">
            <video width="100%" height="240" controls>
                <source [src]="downloadFileObjectUrl" type="video/mp4" *ngIf="downloadFileObjectUrl">
                Your browser does not support the video tag.
            </video>
        </p>
        <div *ngSwitchCase="'text/html'">
            <div [innerHtml]="downloadFileObjectUrl | async"></div>
        </div>
        <p *ngSwitchDefault>
            <!-- <ngx-imageviewer #viewerwrapper></ngx-imageviewer> -->
            <img class="img-container" [src]="downloadFileObjectUrl" *ngIf="downloadFileObjectUrl!=null">
        </p>
    </div>
    </div>
  </div>
</div>