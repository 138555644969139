import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Field } from 'src/app/models/data-model.model';
import { APIService } from 'src/app/services/api.service';
import { EntitySharingService, FieldOnChangeService } from 'src/app/services/shared.service';
import { State } from '../../../models/tasks.model';
import { BaseFieldDirective } from '../base-field/base-field.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss'],
  providers: [ DatePipe ]
})
export class ModelComponent extends BaseFieldDirective {

  @Input()
  public viewEntity: any;

  @Input() state: State;

  @Input()
  private parentViewEntity: any;

  @Output()
  private onAddModel: EventEmitter<any> = new EventEmitter();

  @Output()
  private onEntityUpdateModalOpen: EventEmitter<any> = new EventEmitter();

  @Output()
  private saveViewModeProperties: EventEmitter<any> = new EventEmitter();

  tableColumns: { name: string, label: string, type?:string, islist?:boolean }[] = [];
  tableData: any[] = [];

  currentPage: number = 1;
  pageSize: number = 5;

  carouselPosition: number = null;

  private readonly TABLE_EXCEPTION_FIELDS = [
     'MODEL', 'LOOKUP_REFERENCE', 'HTML_VIEWER', 'BUTTON', 'RATING'
  ];

  constructor(
    private datePipe: DatePipe,
    private apiService: APIService,
    private entitySharingService: EntitySharingService,
    private domSanitizer:DomSanitizer,
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  setDefaultValues() {
    if (this.carouselPosition == null || this.carouselPosition == undefined) {
      if (this.field && this.field.value && this.field.list && this.field.displayAsCarousel) {
        if (this.field.retainPosition && this.parentViewEntity[this.field.name + '_carouselLastKnownIndex'] != null && this.parentViewEntity[this.field.name + '_carouselLastKnownIndex'] != undefined) {
          this.carouselPosition = this.parentViewEntity[this.field.name + '_carouselLastKnownIndex'];
        } else if (!this.field.retainPosition || (this.parentViewEntity[this.field.name + '_carouselLastKnownIndex'] == null || this.parentViewEntity[this.field.name + '_carouselLastKnownIndex'] == undefined)) {
          this.carouselPosition = 0;
        }
      } else {
        this.carouselPosition = 0;
      }
    }
  }

  previousStep() {
    if (this.field && this.field.value && this.field.list && this.field.displayAsCarousel) {
      this.setDefaultValues();

      const fromStep = this.carouselPosition;

      if (this.carouselPosition > 0) {
        this.carouselPosition--;

        if (this.field.retainPosition || this.field.trackProgress) {
          this.parentViewEntity[this.field.name + '_carouselLastKnownIndex'] = this.carouselPosition;
          this.onEnterStep(this.carouselPosition);
        }
      }

      const toStep = this.carouselPosition;

      if (fromStep != toStep && this.field.onCarouselChangeApi) {
        this.onCarouselChange(fromStep, toStep);
      }

      return this.carouselPosition;
    }
  }

  nextStep() {
    if (this.field && this.field.value && this.field.list && this.field.displayAsCarousel) {
      this.setDefaultValues();

      const fromStep = this.carouselPosition;

      if (this.carouselPosition < this.field.value.length - 1) {
        this.carouselPosition++;

        if (this.field.retainPosition || this.field.trackProgress) {
          this.parentViewEntity[this.field.name + '_carouselLastKnownIndex'] = this.carouselPosition;
          this.onEnterStep(this.carouselPosition);
        }
      }

      const toStep = this.carouselPosition;

      if (fromStep != toStep && this.field.onCarouselChangeApi) {
        this.onCarouselChange(fromStep, toStep);
      }

      return this.carouselPosition;
    }
  }

  onCarouselChange(fromStep: number, toStep: number) {
    if (this.field && this.field.value && this.field.list && this.field.displayAsCarousel) {
      if (this.field.onCarouselChangeApi) {
        let payloadRes = this.entitySharingService.getPayloadToEvaluateMVEL(this.field.value[toStep], this.viewEntity, this.state);
        const toStepFieldValues = payloadRes != null && payloadRes['payload'] != null ? payloadRes['payload'] : null;
        
        payloadRes = this.entitySharingService.getPayloadToEvaluateMVEL(this.field.value[fromStep], this.viewEntity, this.state);
        const fromStepFieldValues = payloadRes != null && payloadRes['payload'] != null ? payloadRes['payload'] : null;
        
        const payload = {
          'fromStep': fromStep,
          'toStep': toStep,
          'event': 'ON_CAROUSEL_CHANGE',
          'toStepFieldValues': toStepFieldValues,
          'fromStepFieldValues': fromStepFieldValues
        };

        this.apiService.triggerApiOrRouteOrMvel(this.field.onCarouselChangeApi, payload, false);
      }
    }
  }

  isFirstStep() {
    this.setDefaultValues();
    return this.carouselPosition == 0;
  }

  isLastStep() {
    this.setDefaultValues();
    return this.carouselPosition == (this.field.value.length - 1);
  }

  addModel(field) {
    let index;
    if (field.value && field.value.length > 0) {
      index = field.value.length;
    }
    else {
      field.value = [];
      index = 0;
    }
    field["currentIndex"] = index;
    this.onAddModel.emit(field);
  }

  openEntityUpdateModal(item: any, index?: number, viewingNestedEntity?: boolean) {
    const event = {
      'item': item,
      'index': index,
      'viewingNestedEntity': viewingNestedEntity
    };

    this.onEntityUpdateModalOpen.emit(event);
  }

  removeModel(field: Field, index: number) {
    if (field.value.length > 0) {
      field.value.splice(index, 1);
    }
  }

  getDefaultStepIndex() {
    this.setDefaultValues();
    return this.carouselPosition;
  }

  onEnterStep(itemIndex) {
    if (this.field.list && this.field.displayAsCarousel && (this.field.retainPosition || this.field.trackProgress)) {
      if (itemIndex != null && itemIndex != undefined) {
        const data = {
          'currentIndex': itemIndex
        };

        if (this.isLastStep()) {
          data['carouselFinished'] = true;
        }

        this.updateEntityViewModeProperties(data);
        this.saveNestedEntity(data);
      }
    }
  }

  updateEntityViewModeProperties(data: any) {
    if (data) {
      if (data['currentIndex'] != null && data['currentIndex'] != undefined) {
        const currentIndex = data['currentIndex'];

        if (this.field.retainPosition) {
          this.parentViewEntity[this.field.name + '_carouselLastKnownIndex'] = currentIndex;
        }

        if (this.field.trackProgress) {
          if (this.parentViewEntity[this.field.name + '_carouselProgress'] != null && this.parentViewEntity[this.field.name + '_carouselProgress'] != undefined) {
            const carouselProgress = this.parentViewEntity[this.field.name + '_carouselProgress'];

            if (currentIndex > carouselProgress) {
              this.parentViewEntity[this.field.name + '_carouselProgress'] = currentIndex;
            }
          } else {
            this.parentViewEntity[this.field.name + '_carouselProgress'] = currentIndex;
          }

          if (data['carouselFinished']) {
            this.parentViewEntity[this.field.name + '_carouselFinished'] = true;
          }
        }
      }
    }
  }

  saveNestedEntity(data) {
    const payload = {
      'parentViewEntity': this.parentViewEntity
    };
    this.saveViewModeProperties.emit(payload);
  }

  checkTableColumns() {
    this.tableColumns = [];
    this.tableData = [];
    if (this.field && this.field.value && this.field.value.length > 0) {
      if (this.field && this.field.value[0] && this.field.value[0].fields) {
        const childFields = this.field.value[0].fields.sort((a, b) => { return a.sortOrder > b.sortOrder ? 1 : a.sortOrder ? 0 : -1 });
        let showinListFields  =childFields.filter(item=>item.uiProperties.showInList);
        if (showinListFields && showinListFields.length>0) {
          for (let index = 0; index < showinListFields.length; index++) {
            const childFieldSelected: Field = showinListFields[index];
            this.tableColumns.push({
              'name': childFieldSelected.name,
              'label': childFieldSelected.label
            });
          }
        } else {
          for (let index = 0; index < childFields.length; index++) {
            const childField: Field = childFields[index];
            if(!childField.hide && childField.type && !this.TABLE_EXCEPTION_FIELDS.includes(childField.type)) {
              console.log(childField)
              this.tableColumns.push({
                'name': childField.name,
                'label': childField.label,
                'type': childField.type,
                'islist': childField.list
              });
            }
          }
        }

        for (const childEntity of this.field.value) {
          const rowData = [];

          for (const columnData of this.tableColumns) {
            if (childEntity && childEntity.fields) {
              const fieldLocation = childEntity.fields.map((field: Field) => field.name).indexOf(columnData.name);
              const childField: Field = childEntity.fields[fieldLocation];

              if (childField && childField.value != null && childField.value != undefined) {
                if (childField.type) {
                  if (childField.type == 'DATE') {
                    if (childField.uiProperties.enableTimePicker) {
                      rowData.push(this.datePipe.transform(childField.value, 'dd/MM/yyyy hh:mm a'));
                    } else {
                      rowData.push(this.datePipe.transform(childField.value, 'dd/MM/yyyy'));
                    }
                  } else if (childField.type == 'DATETIME') {
                    rowData.push(this.datePipe.transform(childField.value, 'dd/MM/yyyy hh:mm a'));
                  } else if (childField.type == 'TIME') {
                    rowData.push(this.datePipe.transform(childField.value, 'hh:mm a'));
                  } else if (childField.type == 'PASSWORD' || childField.type == 'SECURED') {
                    rowData.push(childField.value ? '*****' : null);
                  } else if (childField.type == 'MULTI_SELECT' || (childField.type == 'LOOKUP' && childField.list)) {
                    let tempValue = null;

                    for (const value of childField.value) {
                      if (tempValue) {
                        tempValue += ', ' + value;
                      } else {
                        tempValue = value;
                      }
                    }

                    rowData.push(tempValue);
                  } else if (childField.type == 'FILE' || childField.type == 'FILE_SECURED') {
                    if (typeof childField.value == 'object' && childField.value.length>0) {
                      for (let index = 0; index < childField.value.length; index++) {
                        const innerFile = childField.value[index];
                        if (innerFile && innerFile.fileName) {
                          let tmp = innerFile && innerFile.fileName ?  innerFile.fileName + ", " : "-, "
                          rowData.push(tmp)
                        } else if (innerFile && innerFile.userFileName) {
                          let tmp = innerFile && innerFile.userFileName ? innerFile.userFileName + ", "  : "-, "
                          rowData.push(tmp)
                        }else{
                          rowData.push("-, ")
                        }
                      }
                    }else{
                      if (childField.value && childField.value.fileName) {
                        let tmp = childField.value && childField.value.fileName ?   childField.value.fileName : "-"
                        rowData.push((tmp))
                      } else if (childField.value && childField.value.userFileName) {
                        let tmp = childField.value && childField.value.userFileName ?  childField.value.userFileName  : "-"
                        rowData.push((tmp))
                      }else{
                        rowData.push("-")
                      }
                    }
                  } else{
                    rowData.push(childField.value);
                  }
                } else {
                  if (childField.value instanceof Date) {
                    rowData.push(this.datePipe.transform(childField.value, 'dd/MM/yyyy hh:mm a'));
                  } else {
                    rowData.push(childField.value);
                  }
                }
              } else {
                rowData.push(null);
              }
            }
          }

          this.tableData.push(rowData);
        }

        return true;
      }else{
        // This is if values is generated by route and is a list of Hashmaps in key value only
        let fieldList = this.field['fields'];
        let valueList = JSON.parse(JSON.stringify(this.field['value']));
        for (let index = 0; index < fieldList.length; index++) {
          const fieldEl = fieldList[index];
          this.tableColumns.push({
            'name': fieldEl.name,
            'label': fieldEl.label
          });
        }

        this.field['value'] = [];
        for (let valueIndex = 0; valueIndex < valueList.length; valueIndex++) {
          
          this.field['value'][valueIndex] ={};
          this.field['value'][valueIndex] = JSON.parse(JSON.stringify(this.field)); //This puts the entire Model for each field
          // delete this.field['value'][valueIndex]['value'];
          this.field['value'][valueIndex]['fields'] = [];



          let singleValue = valueList[valueIndex];
          let rowData =[];
          for (const key in singleValue) {
            let pos = fieldList.map(item=>item.name).indexOf(key);
            if(pos>-1){
              fieldList[pos].value = singleValue[key];
              this.field['value'][valueIndex]['fields'].push(JSON.parse(JSON.stringify(fieldList[pos])))
              let fieldType = (fieldList[pos].type)
              if (fieldType == 'DATE') {
                if (fieldList[pos].uiProperties.enableTimePicker) {
                  rowData.push(this.datePipe.transform(fieldList[pos].value, 'dd/MM/yyyy hh:mm a'));
                } else {
                  rowData.push(this.datePipe.transform(fieldList[pos].value, 'dd/MM/yyyy'));
                }
              } else if (fieldType == 'DATETIME') {
                rowData.push(this.datePipe.transform(fieldList[pos].value, 'dd/MM/yyyy hh:mm a'));
              } else if (fieldType == 'TIME') {
                rowData.push(this.datePipe.transform(fieldList[pos].value, 'hh:mm a'));
              } else if (fieldType == 'PASSWORD' || fieldType == 'SECURED') {
                rowData.push(fieldList[pos].value ? '*****' : null);
              } else if (fieldType == 'MULTI_SELECT' || (fieldType == 'LOOKUP' && fieldList[pos].list)) {
                let tempValue = null;

                for (const value of fieldList[pos].value) {
                  if (tempValue) {
                    tempValue += ', ' + value;
                  } else {
                    tempValue = value;
                  }
                }

                rowData.push(tempValue);
              } else if (fieldType == 'FILE' || fieldType == 'FILE_SECURED') {
                if (fieldList[pos].value && fieldList[pos].value.fileName) {
                  rowData.push(fieldList[pos].value && fieldList[pos].value.fileName ? fieldList[pos].value.fileName : "-")
                } else if (fieldList[pos].value && fieldList[pos].value.userFileName) {
                  rowData.push(fieldList[pos].value && fieldList[pos].value.fileName ? fieldList[pos].value.fileName : "-")
                }else{
                  rowData.push("-")
                }
              } else{
                rowData.push(fieldList[pos].value);
              }
            }else{
              // console.log(fieldList)
            }
          }
          this.tableData.push(rowData)
        }
        return true
      }
    }
    return false;
  }
}
