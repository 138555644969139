import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { ArchwizardModule } from 'angular-archwizard';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AngularSplitModule } from 'angular-split';
import { MyDatePickerModule } from 'mydatepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagInputModule } from 'ngx-chips';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseFieldDirective } from './components/fields/base-field/base-field.component';
import { CheckboxComponent } from './components/fields/checkbox/checkbox.component';
import { DateComponent } from './components/fields/date/date.component';
import { FileComponent } from './components/fields/file/file.component';
import { HtmlViewerComponent } from './components/fields/html-viewer/html-viewer.component';
import { ModelComponent } from './components/fields/model/model.component';
import { NumberComponent } from './components/fields/number/number.component';
import { PasswordComponent } from './components/fields/password/password.component';
import { SelectComponent } from './components/fields/select/select.component';
import { SelectParentModelComponent } from './components/fields/select-parent-model/select-parent-model.component';
import { TextAreaComponent } from './components/fields/text-area/text-area.component';
import { TextComponent } from './components/fields/text/text.component';
import { VideoUploadComponent } from './components/fields/video-upload/video-upload.component';
import { DynamicFormComponent } from './components/forms/dynamic-form/dynamic-form.component';
import { ViewModeComponent } from './components/forms/view-mode/view-mode.component';
import { WizardFormComponent } from './components/forms/wizard-form/wizard-form.component';
import { DynamicFormModalComponent } from './components/utility/dynamic-form-modal/dynamic-form-modal.component';
import { EntityRefModalComponent } from './components/utility/entity-ref-modal/entity-ref-modal.component';
import { ImagePdfViewerComponent } from './components/utility/image-pdf-viewer/image-pdf-viewer.component';
import { LinkedEntityTableComponent } from './components/utility/linked-entity-table/linked-entity-table.component';
import { AdditionalDetailsModalComponent } from './components/utility/additional-details-modal/additional-details-modal.component';
import { AngularMaterialModule } from './modules/material.module';
import { AuthInterceptor } from './services/auth.interceptor';
import { DynamicTableComponent } from './components/fields/dynamic-table/dynamic-table.component';
import { ButtonComponent } from './components/fields/button/button.component';
import { createCustomElement } from '@angular/elements';
import { TextOcrComponent } from './components/fields/text-ocr/text-ocr.component';
import { AuthComponent } from './components/auth/auth.component';
import { APP_BASE_HREF } from '@angular/common';
import { RatingCompComponent } from './components/fields/rating-comp/rating-comp.component';
import { OrderByPipe } from './components/utility/pipes/orderBy.pipe';
import { SystemVariableComponent } from './components/fields/system-variable-field/system-variable-field.component';
import { SignaturePadModule } from 'angular2-signaturepad';

@NgModule({
  declarations: [
    AppComponent,
    DynamicFormComponent,
    DynamicFormModalComponent,
    ImagePdfViewerComponent,
    LinkedEntityTableComponent,
    EntityRefModalComponent,
    AdditionalDetailsModalComponent,
    BaseFieldDirective,
    TextComponent,
    TextAreaComponent,
    PasswordComponent,
    NumberComponent,
    CheckboxComponent,
    SystemVariableComponent,
    SelectComponent,
    SelectParentModelComponent,
    DateComponent,
    FileComponent,
    ModelComponent,
    VideoUploadComponent,
    WizardFormComponent,
    ViewModeComponent,
    HtmlViewerComponent,
    DynamicTableComponent,
    ButtonComponent,
    TextOcrComponent,
    AuthComponent,
    RatingCompComponent,
    OrderByPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ArchwizardModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TagInputModule,
    MyDatePickerModule,
    MatSnackBarModule,
    FeatherModule.pick(allIcons),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    AngularMaterialModule,
    BsDropdownModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AccordionModule.forRoot(),
    AutocompleteLibModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    NgxPaginationModule,
    ImageViewerModule,
    AngularSplitModule.forRoot(),
    TimepickerModule.forRoot(),
    MatTabsModule,
    ToastrModule.forRoot(),
    SignaturePadModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  entryComponents: [
    AppComponent,
    ImagePdfViewerComponent,
    DynamicFormComponent,
    WizardFormComponent,
    ViewModeComponent,
    DynamicFormModalComponent,
    EntityRefModalComponent,
    AdditionalDetailsModalComponent,
    AuthComponent
  ],
  exports: [
    FeatherModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) { }

  ngDoBootstrap() {
    const el = createCustomElement(AppComponent,
      { injector: this.injector });
    customElements.define('pi-blocks', el);
  }
}
