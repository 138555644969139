import { Component, Inject, Input, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-additional-details-modal',
    templateUrl: './additional-details-modal.component.html',
    styleUrls: ['./additional-details-modal.component.scss']
})
export class AdditionalDetailsModalComponent {

    _modalTitle: any;

    get modalTitle(): any {
        return this._modalTitle;
    }

    @Input() set modalTitle(modalTitle: any) {
        if (modalTitle != null && modalTitle != undefined) {
            if (modalTitle instanceof String || typeof modalTitle == 'string') {
                this._modalTitle = this.domSanitizer.bypassSecurityTrustHtml(<string> modalTitle);
            } else {
                this._modalTitle = modalTitle;
            }
        } else {
            this._modalTitle = this.domSanitizer.bypassSecurityTrustHtml('Info');
        }
    }

    _modalBody: any;

    get modalBody(): any {
        return this._modalBody;
    }

    @Input() set modalBody(modalBody: any) {
        if (modalBody != null && modalBody != undefined) {
            if (modalBody instanceof String || typeof modalBody == 'string') {
                this._modalBody = this.domSanitizer.bypassSecurityTrustHtml(<string> modalBody);
            } else {
                this._modalBody = modalBody;
            }
        } else {
            this._modalBody = this.domSanitizer.bypassSecurityTrustHtml('');
        }
    }

    constructor(
        private domSanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<AdditionalDetailsModalComponent>,

        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public data: {modalTitle: any, modalBody: any}
    ) {
        this.modalTitle = data.modalTitle;
        this.modalBody = data.modalBody;
    }

    closeModal() {
        this.dialogRef.close({ event: 'close' });
    }
}