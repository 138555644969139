<div class="container-fluid no-horizontal-padding vertical-padding-15 root-container">
    <aw-wizard *ngIf="arePagesPresent()" #wizard [navBarLayout]="'large-filled-symbols'"
        [defaultStepIndex]="getDefaultStepIndex()" class="wizard-root">
        <aw-wizard-step [stepTitle]="page.label" [stepId]="page.name" (stepEnter)="onEnterStep(page, $event)" (stepExit)="onExitStep(page, $event)"
            [navigationSymbol]="{ symbol: page.wizardStepName ? page.wizardStepName : (pageIndex + 1)}"
            [awCompletedStep]="isCompleted(page)" [class]="getPageClass(page)"
            *ngFor="let page of selectedDataModel.pages | orderBy:'sortOrder'; let pageIndex = index; let firstPage = first; let lastPage = last">

            <div *ngIf="page && page.uiProperties && page.uiProperties.pageStartHtml" class="view-mode container-fluid startHtml"
                [innerHTML]="getSanitisedHtml(page.uiProperties.pageStartHtml)"></div>

            <form [formGroup]="form" class="root-form">
                <div class="row no-horizontal-margin root-form-inner-div">
                    <ng-container *ngIf="selectedDataModel.groups && selectedDataModel.groups.length>0; else noGroupsPresentForNoPagesTemplate">
                        <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                            <ng-container *ngIf="!field.groupName">
                                <ng-container class="field-container" *ngIf="isFieldInPage(page, field) && conditionForField(field)">
                                    <!-- Custom Text Field -->
                                    <app-text *ngIf="field.type == 'TEXT'" [viewMode]="true"
                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                        [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                        (onRendered)="onFieldRendered()"></app-text>
                                    <!-- Custom Text Field -->
        
                                    <!-- Custom Text Field -->
                                    <app-text-area *ngIf="field.type == 'TEXTAREA'"
                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                        (onRendered)="onFieldRendered()">
                                    </app-text-area>
                                    <!-- Custom Text Field -->
        
                                    <!-- Custom Password Field -->
                                    <app-password
                                        *ngIf="(field.type == 'PASSWORD' || field.type == 'SECURED')"
                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                        (onRendered)="onFieldRendered()">
                                    </app-password>
                                    <!-- Custom Password Field -->
        
                                    <!-- Custom Number Field -->
                                    <app-number
                                        *ngIf="(field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT')"
                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                        (onRendered)="onFieldRendered()">
                                    </app-number>
                                    <!-- Custom Number Field -->
        
                                    <!-- Custom Checkbox Field -->
                                    <app-checkbox *ngIf="field.type == 'CHECKBOX'"
                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                        (onRendered)="onFieldRendered()">
                                    </app-checkbox>
                                    <!-- Custom Checkbox Field -->
        
                                    <!-- Custom System Field -->
                                    <app-system-variable-field
                                        *ngIf="['GROUP', 'PRIORITY', 'STATUS','USER'].includes(field.type)"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [fields]="selectedDataModel.fields"
                                        [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                        (onRendered)="onFieldRendered()">
                                    </app-system-variable-field>
                                    <!-- Custom System Field -->
        
                                    <!-- Custom Select Field -->
                                    <app-select
                                        *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE'].includes(field.type)" 
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [fields]="selectedDataModel.fields" [fieldsMap]="selectedDataModelFieldMap"
                                        [insertDataForView]="insertDataForView"
                                        (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                        (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                        (onRendered)="onFieldRendered()">
                                    </app-select>
                                    <!-- Custom Select Field -->
        
                                    <!-- Custom Date Field -->
                                    <app-date
                                        *ngIf="(field.type == 'DATE' || field.type == 'DATETIME')"
                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()">
                                    </app-date>
                                    <!-- Custom Date Field -->
        
                                    <!-- Custom File Field -->
                                    <app-file
                                        *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [dataModel]="selectedDataModel" (onFileUpload)="onFileUpload()"
                                        (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                                    </app-file>
                                    <!-- Custom File Field -->
        
                                    <!-- Custom Video Upload Field -->
                                    <app-video-upload
                                        *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [dataModel]="selectedDataModel" [viewEntity]="viewEntity" (onFileUpload)="onFileUpload()"
                                        (onFileRemoved)="onFileRemoved()" (saveViewModeEntity)="saveViewModeProperties($event)"
                                        (onRendered)="onFieldRendered()">
                                    </app-video-upload>
                                    <!-- Custom Video Upload Field -->
        
                                    <!-- Custom Model Field -->
                                    <app-model *ngIf="field.type == 'MODEL'" [viewMode]="true"
                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                        [errorMap]="errorMap" [warningMap]="warningMap" [viewEntity]="viewEntity[field.name]"
                                        [parentViewEntity]="viewEntity" (saveViewModeProperties)="saveViewModeProperties($event)"
                                        (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                        (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                        (onRendered)="onFieldRendered()">
                                    </app-model>
                                    <!-- Custom Model Field -->
        
                                    <!-- Dyanamic Table Field -->
                                    <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                        [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                        [viewMode]="true" [errorMap]="errorMap" [warningMap]="warningMap"
                                        [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                                    </app-dynamic-table>
                                    <!-- Dyanamic Table Field -->
        
                                    <!-- Custom Html Viewer Field -->
                                    <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-html-viewer>
                                     <!-- Custom Html Viewer Field -->
                                     
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let group of selectedDataModel.groups | orderBy:'sortOrder'; let grpIndex = index; let grouIndex=index">
                            <ng-container *ngIf="areGroupFieldsPresentInPage(group,page)">
                                <ng-container *ngIf="group.uiProperties.accordionFlag == true; else nonCollapsedTemplate">
                                    <legend class="col-md-12" (click)="toggleCollapse(group)">
                                        <div class="fieldsetLabel">{{group.label}}
                                            <button class="btn collapse-pull-right" *ngIf="group.uiProperties.iscollapsed == true"><i class="fa fa-chevron-up"></i></button>
                                            <button class="btn collapse-pull-right" *ngIf="group.uiProperties.iscollapsed == false"><i class="fa fa-chevron-down"></i></button>
                                        </div>
                                    </legend> 
                                </ng-container>
                                <ng-template #nonCollapsedTemplate>
                                    <legend class="col-md-12">
                                        <div class="fieldsetLabel">{{group.label}}</div>
                                    </legend> 
                                </ng-template>
                                <fieldset  [ngClass]="{'collapse': group.uiProperties.iscollapsed == true}" [class]="'col-md-12 groupedFields'+ grpIndex">
                                    <div class="row">
                                        <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                                            <ng-container *ngIf="field.groupName == group.name">
                                                <ng-container class="field-container" *ngIf="isFieldInPage(page, field) && conditionForField(field)">
                                                    <!-- Custom Text Field -->
                                                    <app-text *ngIf="field.type == 'TEXT'" [viewMode]="true"
                                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                                        [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                                        (onRendered)="onFieldRendered()"></app-text>
                                                    <!-- Custom Text Field -->
                        
                                                    <!-- Custom Text Field -->
                                                    <app-text-area *ngIf="field.type == 'TEXTAREA'"
                                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                                        (onRendered)="onFieldRendered()">
                                                    </app-text-area>
                                                    <!-- Custom Text Field -->
                        
                                                    <!-- Custom Password Field -->
                                                    <app-password
                                                        *ngIf="(field.type == 'PASSWORD' || field.type == 'SECURED')"
                                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                                        (onRendered)="onFieldRendered()">
                                                    </app-password>
                                                    <!-- Custom Password Field -->
                        
                                                    <!-- Custom Number Field -->
                                                    <app-number
                                                        *ngIf="(field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT')"
                                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                                        (onRendered)="onFieldRendered()">
                                                    </app-number>
                                                    <!-- Custom Number Field -->
                        
                                                    <!-- Custom Checkbox Field -->
                                                    <app-checkbox *ngIf="field.type == 'CHECKBOX'"
                                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                                        (onRendered)="onFieldRendered()">
                                                    </app-checkbox>
                                                    <!-- Custom Checkbox Field -->
                        
                                                    <!-- Custom System Field -->
                                                    <app-system-variable-field
                                                        *ngIf="['GROUP', 'PRIORITY', 'STATUS','USER'].includes(field.type)"
                                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                        [fields]="selectedDataModel.fields"
                                                        [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                                        (onRendered)="onFieldRendered()">
                                                    </app-system-variable-field>
                                                    <!-- Custom System Field -->
                        
                                                    <!-- Custom Select Field -->
                                                    <app-select
                                                        *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE'].includes(field.type)" 
                                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                        [fields]="selectedDataModel.fields" [fieldsMap]="selectedDataModelFieldMap"
                                                        [insertDataForView]="insertDataForView"
                                                        (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                                        (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                                        (onRendered)="onFieldRendered()">
                                                    </app-select>
                                                    <!-- Custom Select Field -->
                        
                                                    <!-- Custom Date Field -->
                                                    <app-date
                                                        *ngIf="(field.type == 'DATE' || field.type == 'DATETIME')"
                                                        [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                                        [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()">
                                                    </app-date>
                                                    <!-- Custom Date Field -->
                        
                                                    <!-- Custom File Field -->
                                                    <app-file
                                                        *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                        [dataModel]="selectedDataModel" (onFileUpload)="onFileUpload()"
                                                        (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                                                    </app-file>
                                                    <!-- Custom File Field -->
                        
                                                    <!-- Custom Video Upload Field -->
                                                    <app-video-upload
                                                        *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                                        [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                                        [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                                        [dataModel]="selectedDataModel" [viewEntity]="viewEntity" (onFileUpload)="onFileUpload()"
                                                        (onFileRemoved)="onFileRemoved()" (saveViewModeEntity)="saveViewModeProperties($event)"
                                                        (onRendered)="onFieldRendered()">
                                                    </app-video-upload>
                                                    <!-- Custom Video Upload Field -->
                        
                                                    <!-- Custom Model Field -->
                                                    <app-model *ngIf="field.type == 'MODEL'" [viewMode]="true"
                                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                                        [errorMap]="errorMap" [warningMap]="warningMap" [viewEntity]="viewEntity[field.name]"
                                                        [parentViewEntity]="viewEntity" (saveViewModeProperties)="saveViewModeProperties($event)"
                                                        (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                                        (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                                        (onRendered)="onFieldRendered()">
                                                    </app-model>
                                                    <!-- Custom Model Field -->
                        
                                                    <!-- Dyanamic Table Field -->
                                                    <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                                        [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                                        [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                                        [viewMode]="true" [errorMap]="errorMap" [warningMap]="warningMap"
                                                        [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                                                    </app-dynamic-table>
                                                    <!-- Dyanamic Table Field -->
                        
                                                    <!-- Custom Html Viewer Field -->
                                                    <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-html-viewer>
                                                    <!-- Custom Html Viewer Field -->
                                                    
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    
                                    </div>
                                </fieldset>    
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #noGroupsPresentForNoPagesTemplate>
                        <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                        
                            <ng-container class="field-container" *ngIf="isFieldInPage(page, field) && conditionForField(field)">
                                <!-- Custom Text Field -->
                                <app-text *ngIf="field.type == 'TEXT'" [viewMode]="true"
                                    [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                    [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                    (onRendered)="onFieldRendered()"></app-text>
                                <!-- Custom Text Field -->
    
                                <!-- Custom Text Field -->
                                <app-text-area *ngIf="field.type == 'TEXTAREA'"
                                    [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                    [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                    (onRendered)="onFieldRendered()">
                                </app-text-area>
                                <!-- Custom Text Field -->
    
                                <!-- Custom Password Field -->
                                <app-password
                                    *ngIf="(field.type == 'PASSWORD' || field.type == 'SECURED')"
                                    [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                    [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                    (onRendered)="onFieldRendered()">
                                </app-password>
                                <!-- Custom Password Field -->
    
                                <!-- Custom Number Field -->
                                <app-number
                                    *ngIf="(field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT')"
                                    [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                    [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                    (onRendered)="onFieldRendered()">
                                </app-number>
                                <!-- Custom Number Field -->
    
                                <!-- Custom Checkbox Field -->
                                <app-checkbox *ngIf="field.type == 'CHECKBOX'"
                                    [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                    [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                    (onRendered)="onFieldRendered()">
                                </app-checkbox>
                                <!-- Custom Checkbox Field -->
    
                                <!-- Custom System Field -->
                                <app-system-variable-field
                                    *ngIf="['GROUP', 'PRIORITY', 'STATUS','USER'].includes(field.type)"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [fields]="selectedDataModel.fields"
                                    [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                    (onRendered)="onFieldRendered()">
                                </app-system-variable-field>
                                <!-- Custom System Field -->
    
                                <!-- Custom Select Field -->
                                <app-select
                                    *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE'].includes(field.type)" 
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [fields]="selectedDataModel.fields" [fieldsMap]="selectedDataModelFieldMap"
                                    [insertDataForView]="insertDataForView"
                                    (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                    (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                    (onRendered)="onFieldRendered()">
                                </app-select>
                                <!-- Custom Select Field -->
    
                                <!-- Custom Date Field -->
                                <app-date
                                    *ngIf="(field.type == 'DATE' || field.type == 'DATETIME')"
                                    [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form"
                                    [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" (onRendered)="onFieldRendered()">
                                </app-date>
                                <!-- Custom Date Field -->
    
                                <!-- Custom File Field -->
                                <app-file
                                    *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [dataModel]="selectedDataModel" (onFileUpload)="onFileUpload()"
                                    (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                                </app-file>
                                <!-- Custom File Field -->
    
                                <!-- Custom Video Upload Field -->
                                <app-video-upload
                                    *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')" [viewMode]="true" [currentWizardStep]="getCurrentWizardStep()"
                                    [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [dataModel]="selectedDataModel" [viewEntity]="viewEntity" (onFileUpload)="onFileUpload()"
                                    (onFileRemoved)="onFileRemoved()" (saveViewModeEntity)="saveViewModeProperties($event)"
                                    (onRendered)="onFieldRendered()">
                                </app-video-upload>
                                <!-- Custom Video Upload Field -->
    
                                <!-- Custom Model Field -->
                                <app-model *ngIf="field.type == 'MODEL'" [viewMode]="true"
                                    [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                    [errorMap]="errorMap" [warningMap]="warningMap" [viewEntity]="viewEntity[field.name]"
                                    [parentViewEntity]="viewEntity" (saveViewModeProperties)="saveViewModeProperties($event)"
                                    (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                    (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                    (onRendered)="onFieldRendered()">
                                </app-model>
                                <!-- Custom Model Field -->
    
                                <!-- Dyanamic Table Field -->
                                <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                    [class]="getFieldClass(field, 'col-md-4 mt-2')"
                                    [currentWizardStep]="getCurrentWizardStep()" [parentFormGroup]="form" [field]="field"
                                    [viewMode]="true" [errorMap]="errorMap" [warningMap]="warningMap"
                                    [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                                </app-dynamic-table>
                                <!-- Dyanamic Table Field -->
    
                                <!-- Custom Html Viewer Field -->
                                <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-html-viewer>
                                 <!-- Custom Html Viewer Field -->
                                 
                            </ng-container>
                        </ng-container>
                    </ng-template>
                    
                    

                    <div *ngIf="page && page.uiProperties && page.uiProperties.pageEndHtml" class="view-mode container-fluid endHtml" [innerHTML]="getSanitisedHtml(page.uiProperties.pageEndHtml)"></div>

                    <div class="col-md-12 no-horizontal-padding custom-footer-buttons">
                        <button *ngIf="!firstPage" style="float: left"
                            class="btn btn-secondary btn-with-icon margin-top-15 wizard-back-button" type="button" 
                            (click)="navigateWizard(-1)">
                            <img src="assets/faIcons/fa-arrow-left.svg" height="12" />&nbsp;Back</button>

                        <button *ngIf="!lastPage" style="float: right"
                            class="btn btn-secondary btn-with-icon margin-left-15 margin-top-15 wizard-next-button" type="button"
                            (click)="navigateWizard(1)">
                            Next&nbsp;<img src="assets/faIcons/fa-arrow-right.svg" height="12" /></button>
                    </div>
                </div>
            </form>
        </aw-wizard-step>
    </aw-wizard>

    <form *ngIf="!arePagesPresent()" [formGroup]="form" class="root-form">
        <div class="row no-horizontal-margin root-form-inner-div">
            <ng-container *ngIf="selectedDataModel.groups && selectedDataModel.groups.length>0; else noGroupsPresentForNoPagesTemplate">
                <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                    <ng-container *ngIf="!field.groupName">
                        <ng-container class="field-container" *ngIf="conditionForField(field)">
    
                            <!-- Custom Text Field -->
                            <app-text *ngIf="field.type == 'TEXT'" [viewMode]="true"
                                [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text>
                            <!-- Custom Text Field -->
    
                            <!-- Custom Text Field -->
                            <app-text-area *ngIf="field.type == 'TEXTAREA'" [viewMode]="true"
                                [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text-area>
                            <!-- Custom Text Field -->
    
                            <!-- Custom Password Field -->
                            <app-password
                                *ngIf="(field.type == 'PASSWORD' || field.type == 'SECURED')"
                                [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                (onRendered)="onFieldRendered()"></app-password>
                            <!-- Custom Password Field -->
    
                            <!-- Custom Number Field -->
                            <app-number
                                *ngIf="(field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT')"
                                [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                (onRendered)="onFieldRendered()"></app-number>
                            <!-- Custom Number Field -->
    
                            <!-- Custom Checkbox Field -->
                            <app-checkbox *ngIf="field.type == 'CHECKBOX'" [viewMode]="true"
                                [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-checkbox>
                            <!-- Custom Checkbox Field -->
    
                            <!-- Custom Select Field -->
                            <app-select
                                *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE','USER','GROUP','STATUS','PRIORITY'].includes(field.type)"
                                [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [dataModelName]="selectedDataModel.name" [fields]="selectedDataModel.fields"
                                [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                (onRendered)="onFieldRendered()">
                            </app-select>
                            <!-- Custom Select Field -->
    
                            <!-- Custom Date Field -->
                            <app-date *ngIf="(field.type == 'DATE' || field.type == 'DATETIME')"
                                [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                (onRendered)="onFieldRendered()">
                            </app-date>
                            <!-- Custom Date Field -->
    
                            <!-- Custom File Field -->
                            <app-file
                                *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [dataModel]="selectedDataModel"
                                (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                            </app-file>
                            <!-- Custom File Field -->
    
                            <!-- Custom Video Upload Field -->
                            <app-video-upload
                                *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                                [errorMap]="errorMap" [warningMap]="warningMap" [dataModel]="selectedDataModel"
                                [viewEntity]="viewEntity" (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()"
                                (saveViewModeEntity)="saveViewModeProperties($event)" (onRendered)="onFieldRendered()">
                            </app-video-upload>
                            <!-- Custom Video Upload Field -->
    
                            <!-- Custom Model Field -->
                            <app-model *ngIf="field.type == 'MODEL'" [viewMode]="true"
                                [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                                (saveViewModeProperties)="saveViewModeProperties($event)"
                                (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                (onRendered)="onFieldRendered()">
                            </app-model>
                            <!-- Custom Model Field -->
        
                            <!-- Dyanamic Table Field -->
                            <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                [parentFormGroup]="form" [field]="field" [viewMode]="true" [errorMap]="errorMap"
                                [warningMap]="warningMap" [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                            </app-dynamic-table>
                            <!-- Dyanamic Table Field -->
    
                            <!-- Custom Html Viewer Field -->
                            <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-html-viewer>
                            <!-- Custom Html Viewer Field -->
    
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let group of selectedDataModel.groups | orderBy:'sortOrder'; let grpIndex = index; let grouIndex=index">
                    <ng-container *ngIf="group.uiProperties.accordionFlag == true; else nonCollapsedTemplate">
                        <legend class="col-md-12" (click)="toggleCollapse(group)">
                            <div class="fieldsetLabel">{{group.label}}
                                <button class="btn collapse-pull-right" *ngIf="group.uiProperties.iscollapsed == true"><i class="fa fa-chevron-up"></i></button>
                                <button class="btn collapse-pull-right" *ngIf="group.uiProperties.iscollapsed == false"><i class="fa fa-chevron-down"></i></button>
                            </div>
                        </legend> 
                    </ng-container>
                    <ng-template #nonCollapsedTemplate>
                        <legend class="col-md-12">
                            <div class="fieldsetLabel">{{group.label}}</div>
                        </legend> 
                    </ng-template>
                    <fieldset  [ngClass]="{'collapse': group.uiProperties.iscollapsed == true}" [class]="'col-md-12 groupedFields'+ grpIndex">
                        <div class="row">
                            <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                                <ng-container *ngIf="group.name == field.groupName">
                                    <ng-container class="field-container" *ngIf="conditionForField(field)">
                
                                        <!-- Custom Text Field -->
                                        <app-text *ngIf="field.type == 'TEXT'" [viewMode]="true"
                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                            [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text>
                                        <!-- Custom Text Field -->
                
                                        <!-- Custom Text Field -->
                                        <app-text-area *ngIf="field.type == 'TEXTAREA'" [viewMode]="true"
                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                            [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text-area>
                                        <!-- Custom Text Field -->
                
                                        <!-- Custom Password Field -->
                                        <app-password
                                            *ngIf="(field.type == 'PASSWORD' || field.type == 'SECURED')"
                                            [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                            [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                            (onRendered)="onFieldRendered()"></app-password>
                                        <!-- Custom Password Field -->
                
                                        <!-- Custom Number Field -->
                                        <app-number
                                            *ngIf="(field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT')"
                                            [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                            [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                            (onRendered)="onFieldRendered()"></app-number>
                                        <!-- Custom Number Field -->
                
                                        <!-- Custom Checkbox Field -->
                                        <app-checkbox *ngIf="field.type == 'CHECKBOX'" [viewMode]="true"
                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                            [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-checkbox>
                                        <!-- Custom Checkbox Field -->
                
                                        <!-- Custom Select Field -->
                                        <app-select
                                            *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE','USER','GROUP','STATUS','PRIORITY'].includes(field.type)"
                                            [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                                            [errorMap]="errorMap" [warningMap]="warningMap" [dataModelName]="selectedDataModel.name" [fields]="selectedDataModel.fields"
                                            [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                                            (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                                            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                            (onRendered)="onFieldRendered()">
                                        </app-select>
                                        <!-- Custom Select Field -->
                
                                        <!-- Custom Date Field -->
                                        <app-date *ngIf="(field.type == 'DATE' || field.type == 'DATETIME')"
                                            [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                                            [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                                            (onRendered)="onFieldRendered()">
                                        </app-date>
                                        <!-- Custom Date Field -->
                
                                        <!-- Custom File Field -->
                                        <app-file
                                            *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                                            [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                                            [errorMap]="errorMap" [warningMap]="warningMap" [dataModel]="selectedDataModel"
                                            (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                                        </app-file>
                                        <!-- Custom File Field -->
                
                                        <!-- Custom Video Upload Field -->
                                        <app-video-upload
                                            *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                                            [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                                            [errorMap]="errorMap" [warningMap]="warningMap" [dataModel]="selectedDataModel"
                                            [viewEntity]="viewEntity" (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()"
                                            (saveViewModeEntity)="saveViewModeProperties($event)" (onRendered)="onFieldRendered()">
                                        </app-video-upload>
                                        <!-- Custom Video Upload Field -->
                
                                        <!-- Custom Model Field -->
                                        <app-model *ngIf="field.type == 'MODEL'" [viewMode]="true"
                                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                                            [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                                            (saveViewModeProperties)="saveViewModeProperties($event)"
                                            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                                            (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                                            (onRendered)="onFieldRendered()">
                                        </app-model>
                                        <!-- Custom Model Field -->
                    
                                        <!-- Dyanamic Table Field -->
                                        <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                                            [parentFormGroup]="form" [field]="field" [viewMode]="true" [errorMap]="errorMap"
                                            [warningMap]="warningMap" [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                                        </app-dynamic-table>
                                        <!-- Dyanamic Table Field -->
                
                                        <!-- Custom Html Viewer Field -->
                                        <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-html-viewer>
                                        <!-- Custom Html Viewer Field -->
                
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </fieldset>
                </ng-container>
            </ng-container>
            <ng-template #noGroupsPresentForNoPagesTemplate>
                <ng-container *ngFor="let field of selectedDataModel.fields; let index=index;">
                    <ng-container class="field-container" *ngIf="conditionForField(field)">

                        <!-- Custom Text Field -->
                        <app-text *ngIf="field.type == 'TEXT'" [viewMode]="true"
                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                            [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text>
                        <!-- Custom Text Field -->

                        <!-- Custom Text Field -->
                        <app-text-area *ngIf="field.type == 'TEXTAREA'" [viewMode]="true"
                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                            [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-text-area>
                        <!-- Custom Text Field -->

                        <!-- Custom Password Field -->
                        <app-password
                            *ngIf="(field.type == 'PASSWORD' || field.type == 'SECURED')"
                            [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                            [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                            (onRendered)="onFieldRendered()"></app-password>
                        <!-- Custom Password Field -->

                        <!-- Custom Number Field -->
                        <app-number
                            *ngIf="(field.type == 'INT' || field.type == 'LONG' || field.type == 'FLOAT')"
                            [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                            [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                            (onRendered)="onFieldRendered()"></app-number>
                        <!-- Custom Number Field -->

                        <!-- Custom Checkbox Field -->
                        <app-checkbox *ngIf="field.type == 'CHECKBOX'" [viewMode]="true"
                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                            [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-checkbox>
                        <!-- Custom Checkbox Field -->

                        <!-- Custom Select Field -->
                        <app-select
                            *ngIf="['BOOLEAN', 'SINGLE_SELECT', 'MULTI_SELECT', 'LOOKUP', 'LOOKUP_REFERENCE','USER','GROUP','STATUS','PRIORITY'].includes(field.type)"
                            [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                            [errorMap]="errorMap" [warningMap]="warningMap" [dataModelName]="selectedDataModel.name" [fields]="selectedDataModel.fields"
                            [fieldsMap]="selectedDataModelFieldMap" [insertDataForView]="insertDataForView"
                            (onDialogEntityRefOpen)="openDialogEntityRef($event)"
                            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                            (onRendered)="onFieldRendered()">
                        </app-select>
                        <!-- Custom Select Field -->

                        <!-- Custom Date Field -->
                        <app-date *ngIf="(field.type == 'DATE' || field.type == 'DATETIME')"
                            [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap"
                            [warningMap]="warningMap" [class]="getFieldClass(field, 'col-md-12 mt-2')"
                            (onRendered)="onFieldRendered()">
                        </app-date>
                        <!-- Custom Date Field -->

                        <!-- Custom File Field -->
                        <app-file
                            *ngIf="(field.type == 'FILE' || field.type == 'FILE_SECURED') && !(field.value && field.value.vimeoVideoUrl)"
                            [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                            [errorMap]="errorMap" [warningMap]="warningMap" [dataModel]="selectedDataModel"
                            (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()" (onRendered)="onFieldRendered()">
                        </app-file>
                        <!-- Custom File Field -->

                        <!-- Custom Video Upload Field -->
                        <app-video-upload
                            *ngIf="field.type == 'FILE' && field.value && field.value.vimeoVideoUrl"
                            [class]="getFieldClass(field, 'col-md-12 mt-2')" [viewMode]="true" [parentFormGroup]="form" [field]="field"
                            [errorMap]="errorMap" [warningMap]="warningMap" [dataModel]="selectedDataModel"
                            [viewEntity]="viewEntity" (onFileUpload)="onFileUpload()" (onFileRemoved)="onFileRemoved()"
                            (saveViewModeEntity)="saveViewModeProperties($event)" (onRendered)="onFieldRendered()">
                        </app-video-upload>
                        <!-- Custom Video Upload Field -->

                        <!-- Custom Model Field -->
                        <app-model *ngIf="field.type == 'MODEL'" [viewMode]="true"
                            [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap"
                            [viewEntity]="viewEntity[field.name]" [parentViewEntity]="viewEntity"
                            (saveViewModeProperties)="saveViewModeProperties($event)"
                            (onEntityUpdateModalOpen)="openEntityUpdateModal($event.item, $event.index, $event.viewingNestedEntity)"
                            (onAddModel)="onAddModel($event)" [class]="getFieldClass(field, 'col-md-12 mt-2 border-bottom bgColour')"
                            (onRendered)="onFieldRendered()">
                        </app-model>
                        <!-- Custom Model Field -->
    
                        <!-- Dyanamic Table Field -->
                        <app-dynamic-table *ngIf="field.type == 'DYNAMIC_TABLE'"
                            [class]="getFieldClass(field, 'col-md-4 mt-2')" [currentWizardStep]="getCurrentWizardStep()"
                            [parentFormGroup]="form" [field]="field" [viewMode]="true" [errorMap]="errorMap"
                            [warningMap]="warningMap" [highlightErrors]="highlightErrors" (onRendered)="onFieldRendered()">
                        </app-dynamic-table>
                        <!-- Dyanamic Table Field -->

                        <!-- Custom Html Viewer Field -->
                        <app-html-viewer *ngIf="field.type == 'HTML_VIEWER'" [viewMode]="true" [parentFormGroup]="form" [field]="field" [errorMap]="errorMap" [warningMap]="warningMap" [highlightErrors]="highlightErrors" [class]="getFieldClass(field, 'col-md-12 mt-2')" (onRendered)="onFieldRendered()"></app-html-viewer>
                        <!-- Custom Html Viewer Field -->

                    </ng-container>
                </ng-container>
            </ng-template>
        </div>
    </form>
</div>