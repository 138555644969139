<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-view-label', 'fieldTitle')">
        {{field.label}}:
    </label>
    <br>
    <ng-container *ngIf="field.value">
        <label *ngFor="let item of field.value; let lastIndex = last" [class]="getFieldClass(field, '-view-value')">{{ item + (lastIndex ? '' : ',&nbsp;') }}</label>
    </ng-container>
</div>

<div *ngIf=" field !== undefined && !viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
    </label>

    <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
        <img src="assets/faIcons/fa-info-circle.svg" height="12" />
    </span>

    <br>

    <ng-container *ngIf="field.value">
        <label *ngFor="let item of field.value; let lastIndex = last" [class]="getFieldClass(field, '-view-value')">{{ item + (lastIndex ? '' : ',&nbsp;') }}</label>
    </ng-container>
    <ng-container *ngIf= "field !== undefined && field.inputSource !== undefined && field.inputSource !== null" >
        <table *ngIf= "field.inputSource && field.inputSource.length > 0" class="table table-striped" [attr.disabled]="checkForRefrenceField(field) ? '' : null">
            <tbody>
                <th *ngFor="let singleLine of field.inputSource[0] | keyvalue" [hidden]="singleLine.key == 'value'">{{singleLine.key }} </th>
                <th>&nbsp;</th>
                <ng-container *ngFor="let singleValue of field.inputSource;  let i=index">
                    <tr>
                        <td *ngFor="let singleLine of singleValue | keyvalue" [hidden]="singleLine.key == 'value'">{{singleLine.value}}</td>
                            <td>
                                <input type="checkbox" [id]="field.name + i" [value]="singleValue" (change)="onCheckboxSelect($event, field, singleValue)" [checked]="checkPreviousValue(field.value, singleValue.value)">
                            </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
</ng-container>

    <span *ngIf="field !== undefined && highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="field !== undefined && validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="field !== undefined && errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>
    
    <span *ngIf="field !== undefined && warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>