import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EnvironmentData } from 'src/app/services/environments.service';
import { DataModel, LinkageModel } from '../models/data-model.model';
import { AuthService } from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class DataModelService {
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        private authService: AuthService, 
        private httpClient: HttpClient,
        private environmentData: EnvironmentData
        ) { }

    saveDataModel(datamodel?: DataModel): Observable<DataModel> {
        const subject = new Subject<DataModel>();
        const environmentData = this.environmentData.getEnvData();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelsaveurl;

        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.post<DataModel>(
                    url,
                    datamodel,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                )
                    .subscribe(
                        (response: HttpResponse<DataModel>) => {
                            if (response.body) {
                                subject.next(response.body);
                            }
                        },
                        (err: HttpErrorResponse) => {
                            // All errors are handled in ErrorInterceptor, no further handling required
                            // Unless any specific action is to be taken on some error

                            subject.error(err);
                        }
                    );
            }
        );

        return subject.asObservable();
    }

    activate(_id: string): Observable<DataModel> {
        const subject = new Subject<DataModel>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + _id + "/activate";

        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.get<DataModel>(
                    url,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                ).subscribe(
                    (response: HttpResponse<DataModel>) => {
                        if (response.body) {
                            subject.next(response.body);
                        }
                    },
                    (err: HttpErrorResponse) => {
                        // All errors are handled in ErrorInterceptor, no further handling required
                        // Unless any specific action is to be taken on some error

                        subject.error(err);
                    }
                );
            }
        );

        return subject.asObservable();
    }


    updateDataModel(datamodel?: DataModel): Observable<DataModel> {
        const subject = new Subject<DataModel>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelsaveurl;

        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.put<DataModel>(
                    url,
                    datamodel,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                )
                    .subscribe(
                        (response: HttpResponse<DataModel>) => {
                            if (response.body) {
                                subject.next(response.body);
                            }
                        },
                        (err: HttpErrorResponse) => {
                            // All errors are handled in ErrorInterceptor, no further handling required
                            // Unless any specific action is to be taken on some error

                            subject.error(err);
                        }
                    );
            }
        );

        return subject.asObservable();
    }

    getDataModel(id?: string): Observable<DataModel> {
        const subject = new Subject<DataModel>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + id;
        
        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.get<DataModel>(
                    url,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                )
                    .subscribe(
                        (response: HttpResponse<DataModel>) => {
                            if (response.body) {
                                subject.next(response.body);
                            }
                        },
                        (err: HttpErrorResponse) => {
                            // All errors are handled in ErrorInterceptor, no further handling required
                            // Unless any specific action is to be taken on some error

                            subject.error(err);
                        }
                    );
            }
        );

        return subject.asObservable();
    }

    getDataModelByName(name?: string): Observable<DataModel> {
        const subject = new Subject<DataModel>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + "name/" + name;
        
        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.get<DataModel>(
                    url,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                )
                    .subscribe(
                        (response: HttpResponse<DataModel>) => {
                            if (response.body) {
                                subject.next(response.body);
                            }
                        },
                        (err: HttpErrorResponse) => {
                            // All errors are handled in ErrorInterceptor, no further handling required
                            // Unless any specific action is to be taken on some error

                            subject.error(err);
                        }
                    );
            }
        );

        return subject.asObservable();
    }

    getLinkageModelById(linkageId: string): Observable<LinkageModel> {
        const subject = new Subject<LinkageModel>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + "linkage/" + linkageId;
        
        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.get<LinkageModel>(
                    url,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                )
                    .subscribe(
                        (response: HttpResponse<LinkageModel>) => {
                            if (response.body) {
                                subject.next(response.body);
                            }
                        },
                        (err: HttpErrorResponse) => {
                            // All errors are handled in ErrorInterceptor, no further handling required
                            // Unless any specific action is to be taken on some error

                            subject.error(err);
                        }
                    );
            }
        );

        return subject.asObservable();
    }

    getLinkageModelByParentId(parentId: string): Observable<LinkageModel[]> {
        const subject = new Subject<LinkageModel[]>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.datamodelurl + 'linkage/parentid/' + parentId;
        
        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.get<LinkageModel[]>(
                    url,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                )
                    .subscribe(
                        (response: HttpResponse<LinkageModel[]>) => {
                            if (response.body) {
                                subject.next(response.body);
                            }
                        },
                        (err: HttpErrorResponse) => {
                            // All errors are handled in ErrorInterceptor, no further handling required
                            // Unless any specific action is to be taken on some error

                            subject.error(err);
                        }
                    );
            }
        );

        return subject.asObservable();
    }

    
    getDataModelLookupByType(datamodelName: string, type: string, attributeName?: string, parentGroupName?: string): Observable<any> {
        const envData = this.environmentData.getEnvData();
        const subject = new Subject<any>();
        let url = "";
        if (parentGroupName) {
            url = `${envData.baseURL + envData.flowAPI + envData.entityurl + 'lookup/' + datamodelName + '/' + attributeName + '/USER/' + parentGroupName}`;
        } else {
            let suffix = ""
            switch (type) {
                case 'GROUP':
                suffix = '/' + attributeName + '/GROUP';
                break;
                case 'USER':
                suffix = '/' + attributeName + '/USER';
                break;
                case 'PRIORITY':
                suffix = '/' + attributeName + '/PRIORITY';
                break;
                case 'STATUS':
                suffix = '/' + attributeName + '/STATUS';
                break;

                default:
                break;
            }
            url = `${envData.baseURL + envData.flowAPI + envData.entityurl + 'lookup/' + datamodelName + suffix}`;
        }
        this.httpClient.get<DataModel>(
        url,
        {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
        }

        )
        .subscribe(
            (response: HttpResponse<DataModel>) => {
            if (response.body) {
                subject.next(response.body);
            }
            },
            (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error

            subject.error(err);
            }
        );

        return subject.asObservable();
    }
}