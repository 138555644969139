import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EnvironmentData {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.subject = new Subject();
        this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.envData = null;
        this.baseURL = null;
    }
    fetchEnvData() {
        const url = './assets/wizard-env.json';
        this.httpClient.get(url, {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true,
        }).subscribe((response) => {
            this.envData = response.body;
            if (this.baseURL) {
                this.envData.baseURL = this.baseURL;
            }
            this.subject.next(response.body);
        }, (err) => {
            this.envData = environment;
            if (this.baseURL) {
                this.envData.baseURL = this.baseURL;
            }
            this.subject.next(environment);
        });
        return this.subject.asObservable();
    }
    getEnvData() {
        return this.envData;
    }
    setRootUrl(baseURL) {
        this.baseURL = baseURL;
        if (!this.envData) {
            this.envData = {};
        }
        this.envData.baseURL = this.baseURL;
    }
    setFlowApiByUser(user) {
        if (user.authorities && user.authorities.includes("ROLE_EXTERNAL_USER")) {
            this.envData.flowAPI = "/flow/externaluser/";
        }
        else {
            this.envData.flowAPI = "/flow/console/";
        }
    }
    getEnvDataObservable() {
        return this.subject.asObservable();
    }
}
EnvironmentData.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnvironmentData_Factory() { return new EnvironmentData(i0.ɵɵinject(i1.HttpClient)); }, token: EnvironmentData, providedIn: "root" });
