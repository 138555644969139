import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DynamicFormModalComponent } from '../dynamic-form-modal/dynamic-form-modal.component';

@Component({
  selector: 'app-entity-ref-modal',
  templateUrl: './entity-ref-modal.component.html',
  styleUrls: ['./entity-ref-modal.component.scss']
})
export class EntityRefModalComponent {
  entityRefField: any;
  p: number = 1;
  formMode: 'Default' | 'Wizard Mode' | 'View Mode';

  constructor(
    public dialogRef: MatDialogRef<EntityRefModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.entityRefField = data.field;
    this.formMode = data.formMode;
  }

  close(): void {
    this.dialogRef.close();
  }

  openEntityUpdateModal(item, index?): void {
    let innerViewEntity = {};

    for (let innerField of item.listvalue[index]['fields']) {
      if (innerField) {
        innerViewEntity[innerField['name']] = innerField['value'];
      }
    }

    const initialState = {
      entityItem: item,
      title: item.label,
      index: index,
      modalId: new Date().getTime(),
      formMode: this.formMode,
      viewEntity: innerViewEntity
    };

    const dialogRef = this.dialog.open(DynamicFormModalComponent, {
      data: initialState
    });
  }
}
