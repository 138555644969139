declare var Vimeo: any;

import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Field } from 'src/app/models/data-model.model';
import { APIService } from 'src/app/services/api.service';
import { EntityService } from 'src/app/services/entity.service';
import { FieldOnChangeService, FileService } from 'src/app/services/shared.service';
import { EnvironmentData } from 'src/app/services/environments.service';
import { v4 as uuid } from 'uuid';
import { FileComponent } from '../file/file.component';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss']
})
export class VideoUploadComponent extends FileComponent {

  @Input()
  protected viewEntity;

  @Output()
  private saveViewModeEntity: EventEmitter<any> = new EventEmitter();

  private videoPlayer: any;

  videoPlayerId: string;
  videoPlayerUrl: SafeResourceUrl;
  readonly videoWidth: number = 466;

  private saveVideoDuration: boolean = true;
  private triggerVideoCompleteApi: boolean = false;

  private videoCompleteApiSubscription: Subscription;

  constructor(
    private domSanitizer: DomSanitizer,
    private entityService: EntityService,
    private apiService: APIService,
    protected fileService: FileService,
    protected modalService: BsModalService,
    protected snackBar: MatSnackBar,
    protected changeDetector: ChangeDetectorRef,
    protected toastrService: ToastrService,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog,
    private environmentData: EnvironmentData
  ) {
    super(fileService, modalService, snackBar, changeDetector, toastrService, fieldOnChangeService, matDialog);

    this.videoPlayerId = uuid();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.viewMode) {
      setTimeout(() => {
        this.showVideoPlayer();
      }, 100);
    }

    if (this.field.onVideoCompleteApi)
      this.videoCompleteApiSubscription = this.entityService.getSaveEntityObservable().subscribe(
        onEntitySave => {
          if (this.triggerVideoCompleteApi) {
            this.triggerVideoCompleteApi = false;

            const payload = {
              'event': 'ON_VIDEO_COMPLETE'
            };

            this.apiService.triggerApiOrRouteOrMvel(this.field.onVideoCompleteApi, payload, false);
          }
        }
      );
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.videoCompleteApiSubscription && !this.videoCompleteApiSubscription) {
      this.videoCompleteApiSubscription.unsubscribe();
    }
  }

  showVideoPlayer() {
    if (this.field.value && this.field.value.vimeoVideoUrl) {
      this.videoPlayerUrl = this.generateVideoUrl(this.field);

      setTimeout(() => {
        this.videoPlayer = new Vimeo.Player(this.videoPlayerId);
        this.videoPlayer.setVolume(1);

        /**
         * Unconditionally saving the video progress since the
         * setting for the same is available against VIDEO_PLAYER
         * field type.
         * Currently videos are also being handled via FILE field
         * type.
         */
        // if (this.field.trackVideoProgress && this.viewMode) {
        this.videoPlayer.on('timeupdate', (videoData: any) => {
          if (this.saveVideoDuration || videoData.percent == 1) {
            this.saveVideoDuration = false;
            this.changeDetector.detectChanges();

            setTimeout(() => {
              this.saveVideoDuration = true;
              this.changeDetector.detectChanges();
            }, 5000);

            const data = {
              'videoFinished': videoData.percent == 1,
              'videoProgress': videoData.seconds
            }

            this.updateEntityViewModeProperties(data);
            this.triggerVideoCompleteApi = videoData.percent == 1;

            const payload = {
              'parentViewEntity': this.viewEntity
            };
            this.saveViewModeEntity.emit(payload);
          }
        });
        // }
      }, 100);
    }
  }

  updateEntityViewModeProperties(data: any) {
    if (data) {
      if (data['videoFinished'] && !this.viewEntity[this.field.name + '_videoFinished']) {
        this.viewEntity[this.field.name + '_videoFinished'] = true;
      }

      if (data['videoProgress'] != null && data['videoProgress'] != undefined) {
        this.viewEntity[this.field.name + '_lastKnownVideoDuration'] = data['videoProgress'];
      }
    }
  }

  onVideoPreview(templateRef: TemplateRef<any>, field: Field) {
    if (field.value && field.value.vimeoVideoUrl) {
      this.videoPlayerUrl = this.generateVideoUrl(field);
      this.bsModalRef = this.modalService.show(templateRef, { class: 'modal-dialog modal-md modal-dialog-centered' });

      setTimeout(() => {
        this.videoPlayer = new Vimeo.Player('videoPreviewPlayer');
        this.videoPlayer.setVolume(1);

        // this.videoPlayer.on('play', (data: any) => {
        //   console.log("Video play event");
        //   console.log("duration : " + data.duration);
        //   console.log("percent : " + data.percent);
        //   console.log("seconds : " + data.seconds);
        // });

        // this.videoPlayer.on('pause', (data: any) => {
        //   console.log("Video pause event");
        //   console.log("duration : " + data.duration);
        //   console.log("percent : " + data.percent);
        //   console.log("seconds : " + data.seconds);
        // });

        // this.videoPlayer.on('progress', (data: any) => {
        //   console.log("Video process event");
        //   console.log("duration : " + data.duration);
        //   console.log("percent : " + data.percent);
        //   console.log("seconds : " + data.seconds);
        // });

        // this.videoPlayer.on('timeupdate', (data: any) => {
        //   console.log("Video timeupdate event");
        //   console.log("duration : " + data.duration);
        //   console.log("percent : " + data.percent);
        //   console.log("seconds : " + data.seconds);
        // });
      }, 100);
    }
  }

  generateVideoUrl(field: Field): SafeResourceUrl {
    if (field.value && field.value.vimeoVideoUrl) {
      const videoId = field.value.vimeoVideoUrl.match(/\d+/);

      if (!isNaN(Number(videoId))) {
        let url = this.environmentData.getEnvData().vimeoVideoUrl + videoId[0];

        /**
         * Unconditionally saving the video progress since the
         * setting for the same is available against VIDEO_PLAYER
         * field type.
         * Currently videos are also being handled via FILE field
         * type.
         */
        // if (this.field.trackVideoProgress && this.viewMode && this.viewEntity[this.field.name + '_lastKnownVideoDuration']) {
        if (this.viewMode && this.viewEntity[this.field.name + '_lastKnownVideoDuration']) {
          const lastVideoDuration = this.viewEntity[this.field.name + '_lastKnownVideoDuration'];

          const hour = parseInt((lastVideoDuration / 3600) + '');
          const min = parseInt(((lastVideoDuration - (hour * 3600)) / 60) + '');
          const sec = parseInt((lastVideoDuration - (hour * 3600) - (min * 60)) + '');

          let seekerPosition = '';

          if (hour) {
            seekerPosition += hour + 'h';
          }
          if (min) {
            seekerPosition += min + 'm';
          }
          if (sec) {
            seekerPosition += sec + 's';
          }

          if (seekerPosition) {
            url += "#t=" + seekerPosition;
          }
        }

        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      }
    }

    return null;
  }
}