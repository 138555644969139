import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent extends BaseFieldDirective {

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  onCheckboxSelect(event, field: any, value: any) {
    if (field['value'] == null || field['value'] == undefined) {
      field['value'] = [];
    }

    if (event.target.checked) {
      if (value["value"] !== undefined) {
        field["value"].push(value["value"]);
      }
    } else {
      field["value"] = field["value"].filter((item) => {
        return item !== value["value"];
      });
    }
  }

  checkPreviousValue(oldValue, newValue) {
    return oldValue != null && oldValue != undefined && oldValue.includes(newValue);
  }
}