<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle view-label')">
        {{field.label}}:
    </label>

    <br>

    <label *ngIf="field.value" [class]="getFieldClass(field, '-view-value')">
        {{field.value}}
    </label>
</div>

<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
    </label>

    <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
        <img src="assets/faIcons/fa-info-circle.svg" height="12" />
    </span>

    <ng-container *ngIf="field.type == 'STATUS' || field.type == 'PRIORITY'">
        <select [class]="getFieldClass(field, '-value', defaultClass)" [id]="field.name" [(ngModel)]="field.value"
            title="Choose Status" [attr.disabled]="checkForRefrenceField(field) ? '' : null"
            [formControlName]="field.name">
            <option [ngValue]="undefined" disabled>
                Please Select
            </option>
            <option *ngFor="let value of field.inputSource" [ngValue]="value">
                {{ value }}
            </option>
        </select>
    </ng-container>
    <ng-container *ngIf="field.type == 'GROUP'">
        <select [class]="getFieldClass(field, '-value', defaultClass)" [id]="field.name" [(ngModel)]="field.value"
            title="Choose Status" [attr.disabled]="checkForRefrenceField(field) ? '' : null"
            [formControlName]="field.name">
            <option [ngValue]="undefined" disabled>
                Please Select
            </option>
            <option *ngFor="let group of groupList" [ngValue]="group.value">
                {{ group.label }}
            </option>
        </select>
    </ng-container>
    <ng-container *ngIf="field.type == 'USER'">
        <select [class]="getFieldClass(field, '-value', defaultClass)" [id]="field.name" [(ngModel)]="field.value"
            title="Choose Status" [attr.disabled]="checkForRefrenceField(field) ? '' : null"
            [formControlName]="field.name">
            <option [ngValue]="undefined" disabled>
                Please Select
            </option>
            <option *ngFor="let user of userList" [ngValue]="user.value">
                {{ user.label }} ({{user.value}})
            </option>
        </select>
    </ng-container>

    <!-- <input
        [class]="getFieldClass(field, '-view-value', defaultClass)"
        type="checkbox"
        [id]="field.name"
        [(ngModel)]="field.value"
        [attr.disabled]="checkForRefrenceField(field) ? '' : null"
        [formControlName]="field.name" /> -->

    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>

    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>