import { ChangeDetectorRef, Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { BaseFieldDirective } from '../base-field/base-field.component';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent extends BaseFieldDirective implements OnChanges {

  dateFieldMap: any = null;

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected matDialog: MatDialog
  ) {
    super(changeDetector, fieldOnChangeService, matDialog);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.field) {
      this.updateDateMap();
    }
  }

  updateFieldValue(changedField: { fieldName: string, inputSource: any, value: any, formGroup: FormGroup }): void {
    let date = new Date(changedField.value);
    this.field.value = date;
    this.parentFormGroup.controls[this.field.name].patchValue(date);
    this.updateDateMap();
  }

  getDatePickerClass() {
    return this.field.uiProperties && this.field.uiProperties.enableTimePicker ? 'col-7 pr-2' : 'col-12'
  }

  getDatePickerOptions(): IMyDpOptions {
    let mytime: Date = new Date();
    let currentYear: any = mytime.getUTCFullYear();
    let currentMonth: any = mytime.getUTCMonth() + 1; // Months from 1-12
    let currentPrevDate: any = mytime.getUTCDate() + 1;
    let currentFutDate: any = mytime.getUTCDate() - 1;

    if (this.field.dateTypeName == 'PastAndCurrentDate') {
      return {
        disableSince: { year: currentYear, month: currentMonth, day: currentPrevDate },
        dateFormat: 'dd-mm-yyyy'
      };
    }
    else if (this.field.dateTypeName == 'FutureAndCurrentDate') {
      return {
        disableUntil: { year: currentYear, month: currentMonth, day: currentFutDate },
        dateFormat: 'dd-mm-yyyy'
      };
    }
    else {
      return {
        dateFormat: 'dd-mm-yyyy'
      };
    }
  }

  onDateChanged(changedDate: IMyDateModel) {
    let fieldValue = this.field.value ? this.field.value : new Date();

    if (changedDate && changedDate.jsdate) {
      fieldValue.setFullYear(changedDate.jsdate.getFullYear());
      fieldValue.setMonth(changedDate.jsdate.getMonth());
      fieldValue.setDate(changedDate.jsdate.getDate());
    } else {
      fieldValue = null;
    }

    this.field.value = fieldValue;
    if (this.parentFormGroup && this.parentFormGroup.contains(this.field.name)) {
      this.parentFormGroup.controls[this.field.name].patchValue(this.field.value);
    }
  }

  onTimeChanged(changedTime: Date) {
    let fieldValue: Date = this.field.value;

    if (changedTime) {
      fieldValue = fieldValue ? fieldValue : new Date();

      fieldValue.setHours(changedTime.getHours());
      fieldValue.setMinutes(changedTime.getMinutes());
      fieldValue.setSeconds(changedTime.getSeconds());
      fieldValue.setMilliseconds(changedTime.getMilliseconds());
    } else if (fieldValue) {
      fieldValue.setHours(null);
      fieldValue.setMinutes(null);
      fieldValue.setSeconds(null);
      fieldValue.setMilliseconds(null);
    }

    this.field.value = fieldValue;
    if (this.parentFormGroup && this.parentFormGroup.contains(this.field.name)) {
      this.parentFormGroup.controls[this.field.name].patchValue(this.field.value);
    }
  }


  updateDateMap() {    
    if (this.field && this.field.value && this.field.value instanceof Date) {
      const date: Date = this.field.value;

      this.dateFieldMap = {
        'date': {
          'year': date.getFullYear(),
          'month': date.getMonth() + 1,
          'day': date.getDate()
        }
      };
    } else {
      this.dateFieldMap = null;
    }
  }
}
