<div *ngIf="viewMode" class="col-md-12 no-horizontal-padding viewMode">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle view-label')">
        {{field.label}}:
    </label>

    <div *ngIf="field.value != null && field.value != undefined" class="form-control disabled view-value">
        {{ getViewModeValue() }}
    </div>
    <div *ngIf="field.value == undefined || field.value == null" class="form-control disabled view-value">&nbsp;</div>
</div>

<div *ngIf="!viewMode" [formGroup]="parentFormGroup" class="col-md-12 no-horizontal-padding">
    <label [attr.for]="field.name" [class]="getFieldClass(field, '-label', 'fieldTitle')">
        <ng-container *ngIf="!isValid(field)">
            <strong style="color: red;">*</strong>
        </ng-container>
        {{field.label}}:
    </label>

    <span *ngIf="isPopupInfoApplicable()" class="margin-left-15" placement="right" (click)="openInfoModal()">
        <img src="assets/faIcons/fa-info-circle.svg" height="12" />
    </span>
    <input type="hidden" [formControlName]="field.name" [(ngModel)]="selectedValue  ">
   
    <ng-container *ngIf="field && field.uiProperties.ratingType && field.uiProperties.ratingType == 'numberScale'">
        <div class="services-select-option">
            <div class="sliderContainer">
                <div class="" id="custom-list-span">
                    <div class="starContainer" *ngFor="let star of colours; let i=index"
                        (mouseover)="divHoveredOn($event,i)" (mouseout)="hoverOut()" (click)="divSelected(i)">
                        <span [ngStyle]="{'border-bottom-color': star}">{{i +1}}</span>
                        <div class="starSymbol">
                            <ng-container *ngIf="i > hoveredDiv && i > selectedValue-1; else elseFilledTemplate">
                                <p class="emptySquare" [ngStyle]="{'border-color': star}"></p>
                            </ng-container>
                            <ng-template #elseFilledTemplate>
                                <p class="filledSquare" [ngStyle]="{'border-color': star,'background-color': star}"></p>
                            </ng-template>
                        </div>
                    </div>
                </div>
    
                <div class="ratingText">
                    <span><small>{{startText}}</small></span>
                    <span><small>{{endText}}</small></span>
                </div>
    
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="field.uiProperties.ratingType == 'pictureScale'">
        <div class="services-select-option">
            <div class="sliderContainer">
                <div class="" id="custom-list-span">
                    <div class="starContainer" *ngFor="let picture of pictureArray; let i=index"
                        (mouseover)="divHoveredOn($event,i)" (mouseout)="hoverOut()" (click)="divSelected(i)">
                        <img [src]="picture" width="100%">
                        <div class="starSymbol">
                            <ng-container *ngIf="i > hoveredDiv && i > selectedValue-1; else elseFilledTemplate">
                                <p class="emptySquare"></p>
                            </ng-container>
                            <ng-template #elseFilledTemplate>
                                <p class="filledSquare"></p>
                            </ng-template>
                        </div>
                    </div>
                </div>
    
                <div class="ratingText">
                    <span><small>{{startText}}</small></span>
                    <span><small>{{endText}}</small></span>
                </div>
    
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="field.uiProperties.ratingType == 'smileyScale'">
        <div class="services-select-option">
            <div class="sliderContainer">
                <div class="" id="custom-list-span">
                    <div class="starContainer text-in-center" *ngFor="let picture of smileyArray; let i=index"
                        (mouseover)="divHoveredOn($event,i)" (mouseout)="hoverOut()" (click)="divSelected(i)">
                        <img [src]="'assets/images/'+picture.img" width="80%" class="smileyClass">
                        <div class="starSymbol">
                            <ng-container *ngIf="i > hoveredDiv && i > selectedValue-1; else elseFilledTemplate">
                                <p class="emptySquare" [ngStyle]="{'border-color': picture.color}"></p>
                            </ng-container>
                            <ng-template #elseFilledTemplate>
                                <p class="filledSquare"
                                    [ngStyle]="{'border-color': picture.color,'background-color': picture.color}"></p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="field.uiProperties.ratingType == 'slider'">
        <div class="text-in-center">
            <div class="valueContainer">{{selectedValue}}
            </div>
        </div>
        <input type="range" [min]="startNumber" [formControlName]="field.name" [max]="endNumber" [(ngModel)]="selectedValue" class="slider" [id]="field.name" (change)="changeSLider($event)">
    
    </ng-container>
    <ng-container *ngIf="field.uiProperties.ratingType != 'numberScale' && field.uiProperties.ratingType != 'pictureScale' && field.uiProperties.ratingType != 'smileyScale' && field.uiProperties.ratingType != 'slider'">
         <div class="services-select-option">
            <div class="sliderContainer">
                <div class="" id="custom-list-span">
                    <div class="starContainer" *ngFor="let star of colours; let i=index"
                        (mouseover)="divHoveredOn($event,i)" (mouseout)="hoverOut()" (click)="divSelected(i)">
                        <span [ngStyle]="{'border-bottom-color': star}">{{i +1}}</span>
                        <div class="starSymbol">
                            <ng-container *ngIf="i > hoveredDiv && i > selectedValue-1; else elseFilledTemplate">
                                <p class="emptySquare" [ngStyle]="{'border-color': star}"></p>
                            </ng-container>
                            <ng-template #elseFilledTemplate>
                                <p class="filledSquare" [ngStyle]="{'border-color': star,'background-color': star}"></p>
                            </ng-template>
                        </div>
                    </div>
                </div>
    
                <div class="ratingText">
                    <span><small>{{startText}}</small></span>
                    <span><small>{{endText}}</small></span>
                </div>
    
            </div>
        </div>
    </ng-container>
    
    <span *ngIf="highlightErrors && !isValid(field) && !errorMap[field.name]" class="text-danger">
        {{ field.label }} is mandatory
    </span>

    <span *ngIf="validateRegex(field) && !errorMap[field.name]" class="text-danger">
        {{ validateRegex(field) }}
    </span>

    <span *ngIf="errorMap[field.name]" class="text-danger">
        {{ errorMap[field.name] }}
    </span>
    
    <span *ngIf="warningMap[field.name]" class="text-warning">
        <strong>Warning: {{ warningMap[field.name] }}</strong>
    </span>
</div>