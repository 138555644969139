import { AfterContentInit, ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControlOptions, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { WizardStep } from 'angular-archwizard';
import { commonKeys } from 'src/app/models/constants';
import { Field } from 'src/app/models/data-model.model';
import { FieldOnChangeService } from 'src/app/services/shared.service';
import { AdditionalDetailsModalComponent } from '../../utility/additional-details-modal/additional-details-modal.component';

@Directive({
  selector: 'app-base-field'
})
export class BaseFieldDirective implements OnInit, OnChanges, OnDestroy, AfterContentInit {

  @Input()
  parentFormGroup: FormGroup;

  @Input()
  field: Field;

  @Input()
  errorMap: any;

  @Input()
  warningMap: any;

  @Input()
  highlightErrors: boolean;

  @Input()
  viewMode: boolean;

  @Input()
  currentWizardStep: WizardStep;

  @Output()
  onRendered: EventEmitter<any> = new EventEmitter();

  fieldUniqueIdentifier: string;
  fieldPageVisible: boolean;

  defaultClass: string = 'col-md-12 col-sm-12 col-xs-12 form-control';

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected fieldOnChangeService: FieldOnChangeService,
    protected dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fieldUniqueIdentifier = this.generateObjectId();
    this.subscribeToOnFieldChange();
  }

  ngAfterContentInit() {
    this.onRendered.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.parentFormGroup && this.field) {
      if (!this.parentFormGroup.contains(this.field.name)) {
        this.addFormControl(this.field, this.parentFormGroup);
      }
    }

    if (this.currentWizardStep && this.currentWizardStep.stepId && this.field && this.field.pageName) {
      this.fieldPageVisible = this.currentWizardStep.stepId == this.field.pageName;
    } else if (this.field && !this.field.pageName) {
      this.fieldPageVisible = true;
    }
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {

  }

  getViewModeValue() {
    return this.field && this.field.value != null && this.field.value != undefined ? this.field.value : '';
  }

  isValid(field: Field) {
    let required = false;
    if (field != null && field.validators) {
      for (const validator of field.validators) {
        if (validator.name === 'Required') {
          required = true;
        }
      }
    }

    if (field != null && field.mandatory || required) {
      if (field.type && field.type == 'BUTTON' && field.value && Object.keys(field.value).length > 0 && field.value['_status']) {
        return true;
      }
      if (field.type && field.type != 'BUTTON' && field.value !== null && field.value !== "" && field.value !== undefined ) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  checkForRefrenceField(field: Field) {
    return field.disable || field.name == commonKeys.entityLookUpRefId;
  }

  addFormControl(field: Field, formGroup: FormGroup) {
    let required = false;
    required = field.mandatory;
    for (const validator of field.validators) {
      if (validator.name === 'Required') {
        required = true;
      }
    }

    let fieldValue = field.value != null && field.value != undefined ? field.value : '';

    let formControlOptions: AbstractControlOptions = {
      'updateOn': 'change'
    };

    if (required) {
      formControlOptions['validators'] = [Validators.required];
    }

    formGroup.addControl(field.name, new FormControl(fieldValue, formControlOptions));

    return formGroup;
  }

  getFieldClass(field: Field, dynamicSufix?: string, defaultClass?: string) {
    if (field && field.uiProperties && field.uiProperties['className']) {
      if (defaultClass) {
        return defaultClass + " " +field.uiProperties['className'] + (dynamicSufix ? dynamicSufix : '');
      } else {
        return field.uiProperties['className'] + (dynamicSufix ? dynamicSufix : '');
      }
    } else if (defaultClass) {
      return defaultClass;
    }

    return '';
  }

  isMaskPresent(): boolean {
    return this.field && this.field.mask && this.field.mask.trim().length > 0;
  }

  validateRegex(field: Field) {
    if (field != undefined && field.validationExpression != undefined && field.validationExpression && field.validationExpression.length > 0 && (field.value instanceof String || typeof field.value === 'string')) {
      if (field.value != null && field.value != undefined) {
        const matchValues = field.value.match(field.validationExpression);
        
        if (!matchValues || matchValues.length == 0) {
            return field.errorMessage ? field.errorMessage : "Invalid input";
        }
      }
    }

    return null;
  }

  subscribeToOnFieldChange() {
    this.fieldOnChangeService.getOnFieldChangeSubscription().subscribe(
      (changedField: { fieldName: string, inputSource:any, value: any, formGroup: FormGroup }) => {
        if (this.parentFormGroup && changedField.formGroup && this.parentFormGroup == changedField.formGroup && changedField.fieldName && changedField.fieldName == this.field.name) {
          if (changedField.inputSource != null && changedField.inputSource != undefined) {
            this.updateFieldSource(changedField);
          }
          
          if (changedField.value != null && changedField.value != undefined) {
            this.updateFieldValue(changedField);
          }
        }
      }
    );
  }

  // Don't remove method, below functionality can be overridden in child classes for specific handling
  updateFieldSource(changedField: { fieldName: string, inputSource: any, value: any, formGroup: FormGroup }): void {
    this.field.inputSource = changedField.inputSource;
  }

  // Don't remove method, below functionality is overridden in some child classes for specific handling
  updateFieldValue(changedField: { fieldName: string, inputSource: any, value: any, formGroup: FormGroup }): void {
    this.field.value = changedField.value;
    this.parentFormGroup.controls[this.field.name].patchValue(changedField.value);
  }

  isPopupInfoApplicable() {
    return this.field.uiProperties['popupDescription'] && JSON.stringify(this.field.uiProperties['popupDescription']).trim().length > 0;
  }

  openInfoModal() {
    if (!this.isPopupInfoApplicable()) {
      return;
    }

    const data = {
      'modalTitle': 'Info | ' + this.field.label,
      'modalBody': this.field.uiProperties['popupDescription']
    };

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.data = data;
    matDialogConfig.position = {
      top: '15vh'
    };

    this.dialog.open(AdditionalDetailsModalComponent, matDialogConfig);
  }

  generateObjectId() {
    let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
      return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
  }
  
  decodeEntities(encodedString) {
    if (encodedString && typeof encodedString == "string") {
      var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
      var translate = {
        "nbsp": " ",
        "amp": "&",
        "quot": "\"",
        "lt": "<",
        "gt": ">"
      };
      return encodedString.replace(translate_re, function (match, entity) {
        return translate[entity];
      }).replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
    }
    return encodedString;
  }
}
