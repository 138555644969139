<div class="modal-header">
  <h4 class="modal-title pull-left">{{entityItem.label}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div style="overflow-y:scroll;height:50vh;overflow-x:hidden">
    <ng-container *ngIf="index!=undefined; else notListTemplate">
      <app-dynamic-form *ngIf="formMode && formMode == 'Default'" [addNew]="addNew" [model]="entityItem.listvalue[index]"
        [viewEntity]="viewEntity" (emitted)="updateInnerModelList(entityItem,$event,index)" [innerForm]="true"
        (onNestedEntitySave)="saveNestedEntity($event)">
      </app-dynamic-form>
      <app-wizard-form *ngIf="formMode && formMode == 'Wizard Mode'" [addNew]="addNew" [model]="entityItem.listvalue[index]"
        [viewEntity]="viewEntity" (emitted)="updateInnerModelList(entityItem,$event,index)" [innerForm]="true"
        (onNestedEntitySave)="saveNestedEntity($event)">
      </app-wizard-form>
      <app-view-mode *ngIf="formMode && formMode == 'View Mode'" [addNew]="addNew" [model]="entityItem.listvalue[index]"
        [viewEntity]="viewEntity" (emitted)="updateInnerModelList(entityItem,$event,index)" [innerForm]="true"
        (onNestedEntitySave)="saveNestedEntity($event)">
      </app-view-mode>
    </ng-container>
    <ng-template #notListTemplate>
      <ng-container *ngIf="entityItem && entityItem.viewEntity == true; else notViewTemplate">
        <app-dynamic-form *ngIf="formMode && formMode == 'Default'" [addNew]="addNew" [model]="entityItem" [viewEntity]="viewEntity"
          (emitted)="updateInnerModel(entityItem,$event)" [innerForm]="true" (onNestedEntitySave)="saveNestedEntity($event)">
        </app-dynamic-form>
        <app-wizard-form *ngIf="formMode && formMode == 'Wizard Mode'" [addNew]="addNew" [model]="entityItem" [viewEntity]="viewEntity"
          (emitted)="updateInnerModel(entityItem,$event)" [innerForm]="true" (onNestedEntitySave)="saveNestedEntity($event)">
        </app-wizard-form>
        <app-view-mode *ngIf="formMode && formMode == 'View Mode'" [addNew]="addNew" [model]="entityItem" [viewEntity]="viewEntity"
          (emitted)="updateInnerModel(entityItem,$event)" [innerForm]="true" (onNestedEntitySave)="saveNestedEntity($event)">
        </app-view-mode>
      </ng-container>
      <ng-template #notViewTemplate>
        <app-dynamic-form *ngIf="formMode && formMode == 'Default'" [addNew]="addNew" [model]="entityItem" [viewEntity]="viewEntity"
          (emitted)="updateInnerModel(entityItem,$event)" [innerForm]="true" (onNestedEntitySave)="saveNestedEntity($event)">
        </app-dynamic-form>
        <app-wizard-form *ngIf="formMode && formMode == 'Wizard Mode'" [addNew]="addNew" [model]="entityItem" [viewEntity]="viewEntity"
          (emitted)="updateInnerModel(entityItem,$event)" [innerForm]="true" (onNestedEntitySave)="saveNestedEntity($event)">
        </app-wizard-form>
        <app-view-mode *ngIf="formMode && formMode == 'View Mode'" [addNew]="addNew" [model]="entityItem" [viewEntity]="viewEntity"
          (emitted)="updateInnerModel(entityItem,$event)" [innerForm]="true" (onNestedEntitySave)="saveNestedEntity($event)">
        </app-view-mode>
      </ng-template>
    </ng-template>
  </div>
</div>